import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewChildren, ElementRef, QueryList, Renderer2, SimpleChanges, forwardRef, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { TypeaheadValue, TypeaheadOptions, TypeaheadModel } from 'proceduralsystem-clientcomponents';
import { HttpParams, HttpClient } from '@angular/common/http';
import { NgbDropdown, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, fromEvent, of } from 'rxjs';
import { map, debounceTime, filter, scan, take, delay, tap } from 'rxjs/operators';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import OirCkEditor from 'proceduralsystem-ckeditor';
import { OirCkEditorConfig } from 'proceduralsystem-ckeditor';
import { EditorConfig } from '../../models/editor-config.model';
import { AppConfigService } from 'src/app/services/app-config.service';
import { OirCkEditorInstance } from '../../models/editor-config.model';

enum KeyPress {
  Tab = 9,
  Enter = 13,
  Backspace,
  Shift
}

const HTML_TAG_REGEX = /<.+?>/g;
const SPACE_REGEX = /^((?:\s+)|(?:\s+))$/g;

@Component({
  selector: 'oir-custom-typeahead',
  templateUrl: './custom-typeahead.component.html',
  styleUrls: ['./custom-typeahead.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomTypeaheadComponent),
      multi: true
    }
  ]
})
export class CustomTypeaheadComponent implements OnInit, ControlValueAccessor, OnDestroy,AfterViewInit {
  @Input() apiUrl: string;
  @Input() model: any;
  @Input() values: TypeaheadValue<string>[] = [];
  @Input() placeholder = 'Start typing';
  @Input() disabled = false;
  @Input() options: TypeaheadOptions;
  @Input() params: HttpParams;

  private _isRichTextEditor: boolean;

  @Input() set isRichTextEditor(val: boolean) {
    this._isRichTextEditor = val;

    of(true).pipe(
      delay(500),
      take(1),
      tap(() => this.onInputChange())
    ).subscribe();
  };

  get isRichTextEditor(): boolean {
    return this._isRichTextEditor;
  }
  
  @Output() modelChange: EventEmitter<any> = new EventEmitter();

  @ViewChild(NgbDropdown) dropdown: NgbDropdown;
  @ViewChild('input') input: ElementRef;
  @ViewChild('ckEditor') ckEditor: OirCkEditorInstance;
  @ViewChild(NgbDropdownToggle) ddt: NgbDropdownToggle;
  @ViewChild('inputPills') inputFieldTypeAhead: ElementRef;
  @ViewChildren('inputs') inputs: QueryList<ElementRef>;

  pillEnteredValue: TypeaheadValue<string>;
  inputDisabled = false;
  query = '';
  data: TypeaheadValue<string>[];
  typeaheadModel: TypeaheadModel;
  maxHeightPx = 508;
  maxHeight = this.maxHeightPx;
 
  Editor = OirCkEditor;
  ckEditorConfig: OirCkEditorConfig;

  editorLoaded = false
  private window: Window;

  private debounce: Observable<string>;
  private listener: Function;
  private readonly inputPadding = 11;
  private _debounce$: Subscription;

  propagateChange = (_: TypeaheadModel) => { };
  propagateTouch = (_: any) => { };

  constructor(private readonly el: ElementRef, private readonly renderer: Renderer2, private readonly http: HttpClient, private configurationService: AppConfigService,
    @Inject(DOCUMENT) private document: Document) { 
      this.window = this.document.defaultView;
      this.ckEditorConfig = {
        ...EditorConfig,
        licenseKey: this.configurationService.getValue('CKEditor5LicenseKey')
      }
    }

  ngOnInit(): void {
    this.typeaheadModel = {} as any;
    this.options = this.options || { pills: false };
    if (!this.options.maxPills) {
      this.options.maxPills = 0;
    }

    if (this.options.pills && !Array.isArray(this.model)) {
      this.model = [];
    }
  }

  ngOnDestroy(): void {
    if (this._debounce$) {
      this._debounce$.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.onInputChange();
  }
  
  onInputChange(isFormatRequired = true) {
    const inputElement = this.isRichTextEditor ? this.ckEditor.elementRef.nativeElement : this.input.nativeElement;
    this.debounce = fromEvent(inputElement, 'keydown')
      .pipe(
        filter((i: any) => i.which !== KeyPress.Tab && i.which !== KeyPress.Enter && i.which !== KeyPress.Shift),
        filter((i: any) => i.which !== KeyPress.Backspace || i.target.value.length > 0),
        map((i) => i.key),
        scan((text, key) => {
          if (key === 'Backspace') {
            return text.slice(0, -1);
          } else {
            return text + (/^(.|\n)$/.test(key) ? key : '');
          }
        }, ''),
        debounceTime(500)
      );

    if (this._debounce$) {
      this._debounce$.unsubscribe();
    }
  
    this._debounce$ = this.debounce.subscribe(val => {
      if (val !== null && typeof val === 'string') {
        let searchText = /<\/?[a-z][\s\S]*>/i.test(this.query) ? val : this.query;
        this.onSearch(searchText, isFormatRequired);
      }
    });
  
    this.dropdown.placement = 'bottom';
  }

  resizeInput(event): void {
    if (event) {
      const min = 250;
      const padRight = 5;
      const input = event.srcElement as HTMLInputElement;
      // Create temporary element with same styling as input.
      const tmp = document.createElement('div');
      tmp.style.padding = '0';
      tmp.style.font = '400 15px/21.4286px Montserrat-Light';
      tmp.style.paddingLeft = '8px';
      tmp.style.paddingRight = '8px';
      tmp.style.width = '';
      tmp.style.position = 'absolute';
      tmp.innerHTML = input.value.replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;')
        .replace(/ /g, '&nbsp;');
      input.parentNode.parentNode.appendChild(tmp);
      const width = tmp.clientWidth + padRight + 1;
      tmp.parentNode.removeChild(tmp);
      if (min <= width && (this.el.nativeElement.getBoundingClientRect().width - this.inputPadding) >= width) {
        input.style.width = `${width}px`;
      }
    }

  }

  stateChange(event): void {
    if (event) {
      const ddBounds = this.ddt.nativeElement
        .getBoundingClientRect();
      const body = document.querySelector('#body.body') as HTMLElement;
      // Get visible window port height and minus the element height and distance to bottom of viewport.
      if (body) {
        const maxHeight = body.offsetHeight - (ddBounds.top + ddBounds.height);
        this.maxHeight = maxHeight < this.maxHeightPx ? maxHeight : this.maxHeightPx;
      }
    }
    setTimeout(() => {
      this.setCkEditorStyle();
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.options = this.options ||  { pills: false };

    if (!changes.model) {
      return;
    }

    if (this.options.pills) {
      if (!changes.model.currentValue || (changes.model.currentValue && !changes.model.currentValue.length)) {
        this.query = '';
      } else {
        this.query = changes.model.currentValue[this.options.titlePropertyName] || '';
      }
    } else {
      if (!changes.model.currentValue || (changes.model.currentValue &&
        !changes.model.currentValue.hasOwnProperty(this.options.modelPropertyName))) {
        this.query = '';
      } else {
        this.query = changes.model.currentValue[this.options.titlePropertyName] || '';
      }
    }
  }

  registerOnChange(fn): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn): void {
    this.propagateTouch = fn;
  }

  writeValue(obj: any): void {
    if (obj == null) {
      obj = this.options.pills ? [] : '';
    }

    if (typeof (obj.value) === 'object') {
      this.model = obj;
      this.query = obj.value[this.options.titlePropertyName];
      if (this.query === undefined) {
        this.query = obj[this.options.titlePropertyName] || '';
      }
    } else {
      this.model = obj;
      this.query = obj[this.options.titlePropertyName] || '';
    }
    this.inputDisabled = (this.options.pills && Array.isArray(this.model) &&
      this.options.maxPills !== 0 && this.model.length >= this.options.maxPills);
  }

  setDisabledState(value: any): void {
    this.disabled = value;
  }

  removePill($event: any, index: number): void {
    // Button click has screen x and y of 0;
    const isButtonPress = $event.screenX === 0 && $event.screenY === 0;
    if (!this.disabled) {
      this.model[index].animate = true;

      setTimeout(() => {
        this.model[index].selected = false;
        this.model[index].animate = false;

        // Remove item from model.
        this.model.splice(index, 1);

        this.propagateChange(this.model);
        this.modelChange.emit(this.model);
        this.inputDisabled = (Array.isArray(this.model) && this.options.maxPills !== 0 && this.model.length >= this.options.maxPills);

        // Focus on pill before deleted pill or next if it is the first one and button press.
        if (isButtonPress && this.inputs.length > 1) {
          if (index === 0) {
            index = 2;
          }
          this.inputs.toArray()[index - 1].nativeElement.focus();
        }

      }, 200);

      $event.stopPropagation();
    }
  }

  showPills(): boolean {
    return Array.isArray(this.model) && this.options.pills === true;
  }

  blurInput(event) {
    if (event.which === KeyPress.Tab && this.data && (this.data.length === 0 || this.query === '')) {
      this.close();
    }
    if (event.which === KeyPress.Enter && this.query.trim() !== '' && this.options.pillsOnEnter && this.options.maxPills > 0) {
      if (this.model.find(x => x[this.options.titlePropertyName].toString().toLowerCase() === this.query.trim().toLowerCase()) === undefined) {
        let enteredValue: any = {};
        const textQuery = this.query.trim();
        enteredValue[this.options.titlePropertyName] = textQuery;
        enteredValue[this.options.modelPropertyName] = textQuery;
        this.pillEnteredValue = { value: enteredValue, title: this.query };
        let validateQuery;
        if (this.data !== undefined)
          validateQuery = this.data.filter(x => x[this.options.titlePropertyName].toString().toLowerCase() === textQuery.toLowerCase())[0];
        (validateQuery !== undefined) ? this.select(validateQuery, false) : this.select(this.pillEnteredValue, true);
      } else {
        this.query = '';
      }
    }
    this.resizeInput(event);
  }

  close(): void {
    if (this.typeaheadModel.active) {
      this.typeaheadModel.active = false;
      this.typeaheadModel.animating = true;
      this.dropdown.close();
      setTimeout(() => {
        this.data = undefined;
        this.typeaheadModel.animating = false;
      }, 280);
    }
  }

  optionKeyDown(event, index): void {
    if (event.which === KeyPress.Enter) {
      this.select(this.data[index]);
    }
  }

  select(option: TypeaheadValue<string>, enteredValue = false): void {
    if (this.inputFieldTypeAhead && !enteredValue) {
      // Removes the input width after item selected.
      this.renderer.setAttribute(this.inputFieldTypeAhead.nativeElement, 'style', undefined);
    }
    if (option.disabled || option.selected) {
      return;
    }

    const value = this.getCurrentValue(option, enteredValue);
    // Allow to add two same pills
    if (!this.options.pills || (this.options.pills && this.model.filter(x => x[this.options.titlePropertyName] === value[this.options.titlePropertyName]).length < 2)) {
      if (this.options.pills) {
        this.query = '';
        this.model.push(value);
        this.inputDisabled = (Array.isArray(this.model) && this.options.maxPills !== 0 &&
          this.model.length >= this.options.maxPills);
      } else {
        this.query = option.title || '';
        this.model = value;
      }
      this.propagateSelectedOptionChange();
      this.modelChange.emit(this.model);
      if (!this.isRichTextEditor) {
        this.input.nativeElement.focus();
      }
      return this.close();
    }
    this.query = '';
  }

  private onSearch(searchQuery: string | null, isFormatRequired = false): void {
    if (searchQuery !== '') {
      this.onValueSearch(searchQuery, isFormatRequired);
    } else {
      this.query = '';
      if (!this.options.pills) {
        this.model = [];

        this.propagateChange(this.model);
        this.modelChange.emit(this.model);
      }

      this.close();
    }
  }

  private getResponseObject(response: any): any {
    let result = response;

    if (!Array.isArray(response) && this.options.responsePropertyName) {
      const split = this.options.responsePropertyName.split('.');

      for (const s of split) {
        result = result[s];
      }
    }

    return result;
  }

  private clearActive(): void {
    this.typeaheadModel.processing = false;
    this.typeaheadModel.spinnerActive = false;
  }

  private getCurrentValue(option: TypeaheadValue<string>, enteredValue = false): any {
    let result: any;

    if (typeof (option.value) === 'object') {
      result = enteredValue
        ? option.value
        : this.data.find(x => x.value[this.options.modelPropertyName] ===
          option.value[this.options.modelPropertyName]).value;
    } else {
      result = option;
    }

    return result;
  }

  private buildRequestParameters(): HttpParams {
    let result = this.params || new HttpParams();
    result = result.append('query', this.query);
    result = result.append('hideSpinner', 'true');

    return result;
  }

  private registerListener(): void {
    this.listener = this.renderer.listen('document', 'click', (event: any) => {
      if (!this.el.nativeElement.contains(event.target)) {
        this.close();

        this.listener();
      }
    });
  }

  private getRawValue(searchQuery: string): string {
    return searchQuery
      ? searchQuery.replace(HTML_TAG_REGEX, '').replace(SPACE_REGEX, '')
      : searchQuery;
  }

  private propagateSelectedOptionChange(): void {
    // Return only if not using pills and return property asked.
    if (!this.options.pills && this.options.returnModelProperty !== null) {
      this.propagateChange(this.model[this.options.returnModelProperty]);
    } else {
      this.propagateChange(this.model);
    }
  }

  private onValueSearch(searchQuery: string | null, isFormatRequired = false): void {
    this.typeaheadModel.spinnerActive = true;

    // If custom data passed in seach through that otherwise call API.
    if (this.values.length === 0) {
      return this.fetchData();
    }

    if (isFormatRequired) {
      searchQuery = this.getRawValue(searchQuery);
    }

    this.data = this.values.filter(x => x[this.options.titlePropertyName].toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0);
    this.dropdown.open();
    this.typeaheadModel.active = true;
    this.clearActive();
    this.registerListener();
  }

  private fetchData(): void {
    this.http.get(this.apiUrl, { params: this.buildRequestParameters() }).toPromise().then((r: any) => {
      const response = this.getResponseObject(r);

      this.data = [];

      if (response && response.length) {
        this.data = response.slice(0, 10).map(x => ({ value: x, title: x[this.options.titlePropertyName] }));
      }
      this.dropdown.open();
      this.typeaheadModel.active = true;
      this.clearActive();
      this.registerListener();
    });
  }

  onReady() {
    this.setCkEditorStyle()
  }

  setCkEditorStyle() {
    if (this.window) {
      for (var element = 0; element < this.window.length; element++) {
        this.window[element].document.getElementsByTagName('body')[0].style.fontFamily = "'Montserrat-Light', sans-serif";
        this.window[element].document.getElementsByTagName('body')[0].style.lineHeight = "20px";
        this.window[element].document.getElementsByTagName('body')[0].style.color = "#444";
      }
    }
  }
}
