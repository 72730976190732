import { Component, OnInit, Input, ViewChild, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CollapsiblePanelComponent,
  SelectValue
} from 'proceduralsystem-clientcomponents';
import { ReportService } from 'src/app/services/report.service';
import {
  BusinessScheduleDay,
  BusinessDaySectionHeader
} from 'src/app/shared/models/report.model';
import { Subscription } from 'rxjs';
import { ReportStoreService } from 'src/app/services/report-store.service';
import { SharedService } from 'src/app/services/shared.service';
import { debounceTime, filter } from 'rxjs/operators';
import { GLOBALS } from 'src/app/shared/globals';
import { BusinessDaysTemplates } from 'src/app/shared/report.enum';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { sortBy } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';
import { SectionComponent } from './section/section.component';

@Component({
  selector: 'oir-day-instance',
  templateUrl: './day-instance.component.html',
  styleUrls: ['./day-instance.component.less']
})
export class DayInstanceComponent implements OnInit, OnDestroy {
  @ViewChildren('sectionComponent') sectionComponents: QueryList<SectionComponent>;
  @Input() businessScheduleDay: BusinessScheduleDay;
  @Input() showDayTemplateField: boolean;
  @Input() showTemplateFieldOther: boolean;

  private _readonly = false;

  get readonly(): boolean {
    return this._readonly;
  }

  @Input() set readonly(value: boolean) {
    this._readonly = value;
    this.adjustReadonlyControls();
  }

  @ViewChild('panel') panel: CollapsiblePanelComponent;

  public disableSectionDrag = {};
  public dayInstanceForm: FormGroup;
  public isSittingDay: boolean;
  public isDayDefaultSittinDayByDate = true;
  public isEditMode = false;
  public isDayBeforeToday = false;
  public dayTemplate = BusinessDaysTemplates.Blank;
  public dayTemplateData: SelectValue<any>[] = [
    {
      value: BusinessDaysTemplates.Blank,
      title: this.translateService.instant(
        'VIEW_REPORT_DAY_INSTANCE.DAY_TEMPLATE_INPUT.VALUE.BLANK'
      )
    },
    {
      value: BusinessDaysTemplates.Tuesday,
      title: this.translateService.instant(
        'VIEW_REPORT_DAY_INSTANCE.DAY_TEMPLATE_INPUT.VALUE.TUESDAY'
      )
    },
    {
      value: BusinessDaysTemplates.Wednesday,
      title: this.translateService.instant(
        'VIEW_REPORT_DAY_INSTANCE.DAY_TEMPLATE_INPUT.VALUE.WEDNESDAY'
      )
    },
    {
      value: BusinessDaysTemplates.Thursday,
      title: this.translateService.instant(
        'VIEW_REPORT_DAY_INSTANCE.DAY_TEMPLATE_INPUT.VALUE.THURSDAY'
      )
    }
  ];
  public mask = [/\d/, /\d/, ':', /\d/, /\d/];
  public reusableDayId: string;

  get startTime(): string {
    return this.sharedService.getTimeFromDate(
      this.businessScheduleDay.bsDayStartTime
    );
  }

  get endTime(): string {
    return this.sharedService.getTimeFromDate(
      this.businessScheduleDay.bsDayEndTime
    );
  }

  get dayTitle(): string {
    return this.sharedService.getDayNameFromDate(
      this.businessScheduleDay.bsDayDate
    );
  }

  private subscription: Subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private reportService: ReportService,
    private reportStoreService: ReportStoreService,
    private sharedService: SharedService,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.businessScheduleDay.businessDaySectionHeaders = sortBy(
      this.businessScheduleDay.businessDaySectionHeaders.map(item => {
        return {
          ...item,
          bsSectionStartTime: this.sharedService.convertDateStringToTime(
            item.bsSectionStartTime
          ),
          bsSectionEndTime: this.sharedService.convertDateStringToTime(
            item.bsSectionEndTime
          )
        };
      }),
      'businessDaySectionPositionNo'
    );

    this.dayInstanceForm = this.formBuilder.group({
      startTime: [
        '',
        [Validators.required, Validators.pattern('^([0-9]{2}):([0-9]{2})$')]
      ],
      sittingDay: null,
      reasonText: [
        '',
        [
          Validators.required,
          Validators.maxLength(250)
        ]
      ]
    });

    this.isSittingDay = this.businessScheduleDay.isBSDaySitting;

    this.populateForm();

    this.subscribeToFormChange();

    this.isDayDefaultSittinDayByDate =
      this.sharedService.isDayDefaultSittinDayByDate(
        this.businessScheduleDay.bsDayStartTime
      );

    this.subscription.add(
      this.reportService.dateChanged.subscribe(res => (this.isEditMode = res))
    );

    this.setDayId(this.businessScheduleDay.bsDayId);

    // Checking is day before today
    this.isDayBeforeToday = this.sharedService.isDayBeforeToday(
      this.businessScheduleDay.bsDayDate
    );

    this.adjustReadonlyControls();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Method to handle day template value change
   * @param value
   */
  public onDayTemplateChange(value: number): void {
    this.reportStoreService.getAndSetNonDefaultBusinessDayTemplate(
      this.businessScheduleDay.bsDayDate,
      value,
      this.businessScheduleDay
    );
  }

  /**
   * Method to handle when section is droped
   * @param event
   */
  public onDrop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.businessScheduleDay.businessDaySectionHeaders,
      event.previousIndex,
      event.currentIndex
    );
    this.reportStoreService.updateSectionPosition(
      this.businessScheduleDay.bsDayDate,
      this.businessScheduleDay.businessDaySectionHeaders
    );
  }

  /**
   * Method to update day section expanded value in store
   * @param collapsed
   */
  public onDaySectionToggle(collapsed: boolean): void {
    this.reportStoreService.setDaySectionExpanded(
      this.businessScheduleDay.bsDayDate,
      !collapsed
    );
  }

  /**
   * Method to get end time of businessDay
   */
  public getDayEndTime(): string {
    return this.reportStoreService.getBusinessDayEndTime(
      this.businessScheduleDay.bsDayDate
    );
  }

  /**
   * Method to resotre disabled section
   * @param header
   */
  public addRemovedSection(header: BusinessDaySectionHeader): void {
    this.reportStoreService.enableHeaderForDefaultSection(
      this.businessScheduleDay.bsDayDate,
      header.bdSectionId
    );
  }

  /**
   * Hide or show section elements and other sections when editing.
   */
  public hideOtherSections(evt: any) {
    setTimeout(() => {
      const daySections = Array.from(document.querySelector(
        '.sections-for-day-' + evt.dayId
      ).children);
      this.disableSectionDrag[evt.sectionId] = evt.hideOthers;

      for (const daySection of daySections) {
        daySection.setAttribute(
          'style',
          'display: ' +
            (daySection.id !== 'section-' + evt.sectionId && evt.hideOthers
              ? 'none'
              : 'block')
        );
      }
      const dayDependantElements = [
        '.adjournment-',
        '.disabled-section-',
        '.new-section-',
        '.day-arrangement-'
      ];
      const sectionDependantElements = [
        '.section-time-',
        '.accordion-button-',
        '.drag-element-'
      ];
      dayDependantElements.forEach(x => {
        this.toggleVisibilityByClassAndEditId(x, evt.dayId, evt.hideOthers);
      });
      sectionDependantElements.forEach(x => {
        this.toggleVisibilityByClassAndEditId(x, evt.sectionId, evt.hideOthers);
      });
    }, 100);
  }

  /**
   * Toggle element visibility by class and editId, based on hideOthers value
   * @param selector
   * @param id
   * @param hideOthers
   */
  public toggleVisibilityByClassAndEditId(
    selector: string,
    id: number,
    hideOthers: boolean
  ) {
    if (!!document.querySelector(selector + id)) {
      const element = document.querySelector(selector + id) as HTMLElement;
      if (element) {
        element.style.display = hideOthers ? 'none' : 'block';
      }
    }
  }

  /**
   * Set day ID or randomize
   */
  public setDayId(dayId: number): void {
    if (dayId === 0) {
      const dateOnly = this.businessScheduleDay.bsDayDate.split('T');
      this.reusableDayId = dateOnly[0];
    } else {
      this.reusableDayId = this.businessScheduleDay.bsDayId.toString();
    }
  }

  /**
   * Method to subscribe to form changes for various controls
   */
  private subscribeToFormChange(): void {
    this.subscription.add(
      this.dayInstanceForm
        .get('startTime')
        .valueChanges.pipe(filter(val => GLOBALS.timeFormatRegex.test(val)))
        .subscribe(val => {
          this.reportStoreService.setBusinessDayStartAndEndTime(
            this.businessScheduleDay.bsDayDate,
            val
          );
        })
    );

    this.subscription.add(
      this.dayInstanceForm.get('sittingDay').valueChanges.subscribe(val => {
        this.reportStoreService.setSittingDayStatus(
          this.businessScheduleDay.bsDayDate,
          val
        );
      })
    );

    this.subscription.add(
      this.dayInstanceForm.controls.reasonText.valueChanges
        .pipe(debounceTime(500))
        .subscribe(val =>
          this.reportStoreService.setDayReasonText(
            this.businessScheduleDay.bsDayDate,
            val
          )
        )
    );
  }

  /**
   * Method to populate form and disable controls if necessary
   */
  private populateForm(): void {
    this.dayInstanceForm.patchValue({
      startTime: this.startTime,
      sittingDay: this.businessScheduleDay.isBSDaySitting,
      reasonText: this.businessScheduleDay.bsDayNotSittingReason || ''
    });

    if (this.businessScheduleDay.bsDayTemplateId) {
      this.dayTemplate = this.businessScheduleDay.bsDayTemplateId;
    }

    // If is sitting disable reasonText
    if (this.businessScheduleDay.isBSDaySitting) {
      this.dayInstanceForm.controls.reasonText.disable();
    }

    // If is not sitting disable startTime
    if (!this.businessScheduleDay.isBSDaySitting) {
      this.dayInstanceForm.controls.startTime.disable();
    }
  }

  adjustReadonlyControls() {
    if (this.dayInstanceForm) {
      if (this.readonly) {
        this.dayInstanceForm.controls.startTime.disable({emitEvent: false});
        this.dayInstanceForm.controls.reasonText.disable({emitEvent: false});
      } else {
        // Covering similar logic from 'populateForm' function.
        if (!this.businessScheduleDay.isBSDaySitting) {
          this.dayInstanceForm.controls.reasonText.enable({emitEvent: false});
        } else {
          this.dayInstanceForm.controls.startTime.enable({emitEvent: false});
        }
      }
    }
  }
}
