import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  MonitoringService,
  NavigationService
} from 'proceduralsystem-clientcomponents';
import { forkJoin } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { AppConfigService } from './services/app-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.less']
})
export class PageComponent implements OnInit {
  safPanelExists = false;
  isFullView = true;
  
  constructor(
    private titleService: Title,
    private configuration: AppConfigService,
    private navigationService: NavigationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private monitoringService: MonitoringService
  ) {}

  ngOnInit(): void {
    forkJoin([
      this.configuration.get('UserName'),
      this.configuration.get('UserRoleMemberId'),
      this.configuration.get('ModuleName'),
      this.configuration.get('AppInsightsKey')
    ])
      .pipe(take(1))
      .subscribe(([userName, _roleId, moduleName, appInsightsKey]) => {
        this.monitoringService.init(appInsightsKey, userName);

        this.navigationService.init({
          module: { title: moduleName, path: '' },
          homeUrl: '/create-report',
          tree: [
            { title: 'Dashboard', path: '/dashboard' },
            { title: 'Search', path: '/search' },
            { title: 'Create report', path: '/create-report' }
          ]
        });

        this.listenRouterEvents();
      });
  }

  changeOfRoutes() {
    setTimeout(() => {
      this.safPanelExists =
        document.querySelector('.saf') !== null;
    }, 100);
    this.isFullView=!this.router.url.includes('dashboard')
  }

  private listenRouterEvents(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        this.titleService.setTitle(event.title);
      });
  }
}
