import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ActionInProgressService {

  public actionInProgress = false;

  /**
   * Method to set actionInProgress flag as active
   */
  public actionStarted(): void {
    this.actionInProgress = true;
  }

  /**
   * Method to set actionInProgress flag as inactive
   */
  public actionEnded(): void {
    this.actionInProgress = false;
  }

}
