export enum WorkItems {
    Motion = 1,
    Bill,
    Statement
}

export enum FileExtensionType {
  BcrPdf = 1,
  BcrDocx = 2
}

export enum RREmailType {
    ActionBarEmail = 1,
    SendToRapporteurEmail = 2
}

export enum TypeOfReport {
    BCR = 1,
    RR = 2,
    OB = 3,
}

export enum ReportTypes {
    'BCR' = 1,
    'ODR' = 2,
    'COSR' = 3,
}

export enum ReportSubTabs {
    Reports = 1,
    OutstandingItems = 2
}

export enum DashboardReportTypes {
    Current = 'Current',
    Draft = 'Draft',
    OutstandingCurrent = 'OutstandingCurrent',
    OutstandingDraft = 'OutstandingDraft',
    Previous = 'Previous'
}

export enum TabIndexes {
    Current = 1,
    Draft = 2,
    Previous = 3,
}

export enum ReportTypesTitles {
    BCR = 'Business Committee Report',
    ODR = 'One day Business Committee Report',
    COSR = 'Committee of Selection Report',
}

export const SectionTypes = {
    1: 'businessDayLeadersQuestionsSectionDetails',
    2: 'businessDayOrderOfBusinessSectionDetails',
    3: 'businessDayMotionsWithoutDebateSectionDetails',
    4: 'businessDayCommonSectionDetails',
    5: 'businessDaySectionsPQOralDetails',
    6: 'businessDayCommonSectionDetails',
    7: 'businessDayCommonSectionDetails',
    8: 'businessDayGovernmentBusinessSectionDetails',
    9: 'businessDayPrivateMembersBusinessSectionDetails',
    10: 'privateMemberBillSectionDetails',
    11: 'businessDayCommonSectionDetails',
    12: 'businessDayCommonSectionDetails',
    13: 'deferredDivisionsSectionDetails',
    14: 'businessDayArrangements',
    15: 'businessDayAdjournmentSectionDetails',
    17: 'businessDayCustomSectionDetails',
    18: 'businessDayCommonSectionDetails',
    19: 'businessDayCommonSectionDetails',
    20: 'businessDayCommonSectionDetails',
    21: 'businessDayBillsForIntroductionSectionDetails',
    22: 'businessDayReferralToCommitteeSectionDetails',
}

export enum ArrangementTypes {
    Day = 1,
    Section = 2,
    WorkItem = 3
}

export enum BusinessDaysTemplates {
    Blank = 1,
    Tuesday,
    Wednesday,
    Thursday
}

export enum FormTemplate {
    leadersQuestions = 1,
    orderOfBusiness = 2,
    motions = 3,
    taoiseach = 4,
    pqOral = 5,
    topicalIssues = 6,
    privateNoticeQuestion = 7,
    governmentBusiness = 8,
    privateMembersBusiness = 9,
    privateMembersBill = 10,
    questionsOnPromisedLegislation = 11,
    sos = 12,
    deferredDivisions = 13,
    dayArrangements = 14,
    adjournment = 15,
    custom = 17,
    expressionsOfSympathy = 18,
    messages = 19,
    announcements = 20,
    billsForIntroduction = 21,
    referralToCommittee = 22,
}

export enum FormFieldNames {
    parties = 1,
    department = 5
}

export const SectionsApi = {
    1: 'leadersquestions',
    3: 'motion',
    5: 'pqoral',
    8: 'governmentbusiness',
    9: 'privatememberbusiness',
    10: 'privatemembersbill',
    13: 'deferreddivisions',
    17: 'customsection',
    21: 'billsforintroduction',
    22: 'referraltocommittees',
};

export enum WorkItemTypes {
    bills = 'bills',
    motions = 'motions',
    statements = 'statements',
}

export enum WorkItemTypeValues {
    motions = 1,
    bills,
    statements
}

export enum ReportStatuses {
    Draft = 1,
    Circulated = 2,
    Update = 3,
    OutdatedByDraft = 4,
    OutdatedByCirculated = 5,
    OutdatedByUpdate = 6,
    Deleted = 9,
}

export enum Stages {
    FirstStage = 1,
    OrderForSecondStage,
    SecondStage,
    OrderForCommitteeStage,
    CommitteeStage,
    OrderForReport,
    ReportStage,
    OrderForFifthStage,
    FifthStage,
    AllStages,
    AmendmentsFromTheSeanad
}

export enum Amendments {
    Amendment = 1,
    AmendmentToAmendment
}

export enum TitleTranslationStatuses {
    InTranslation = 1,
    TranslationReceived,
    TranslationWithdrawn
}

export enum TitleSdlTranslationStatuses {
    Initial,
    SentForTranslation,
    InTranslation,
    InFinalReview,
    Translated
}

export enum TitleSdlTranslationTypes {
    Preparation = 'Preparation',
    Translation = 'Translation',
    Review = 'Review',
    Finalisation = 'Finalisation',
}
