import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PaginationOptions, SelectValue } from 'proceduralsystem-clientcomponents';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Reports } from 'src/app/shared/models/dashboard.model';
import { DeleteConfirmationModalComponent } from '../delete-confirmation-modal/delete-confirmation-modal.component';
import { DashboardService } from '../dashboard-services/dashboard-service.service';
import { takeUntil, debounceTime, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { OutstandingWorkItemsModalComponent } from '../outstanding-work-items-modal/outstanding-work-items-modal.component';
import { TypeOfReport, TabIndexes } from 'src/app/shared/report.enum';
import { isEqual } from 'lodash-es';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'oir-current-draft-report',
  templateUrl: './current-draft-report.component.html',
  styleUrls: ['./current-draft-report.component.less']
})
export class CurrentDraftReportComponent implements OnInit, OnDestroy {

  @Input() type: string;
  @Input() queryType: number;
  @Input() itemsPerPage: number;
  @Input() hasOutstandingItems: number;
  @Input() showDelete: false;
  @Input() showFilter: false;

  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() previousReportFormChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dashboardDeleteModal') dashboardDeleteModal: DeleteConfirmationModalComponent;
  @ViewChild('outstandingItemsModal') outstandingItemsModal: OutstandingWorkItemsModalComponent;

  public totalReports = {};
  public paginationList: Reports[];
  public previousReportForm: FormGroup;
  public currentPage = 1;
  public data: SelectValue<any>[];
  public typeOfReport = TypeOfReport;
  public tabIndex = TabIndexes;

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private previousReportFormOldVal: any;

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private dashboardService: DashboardService,
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.data = [
      { value: 1, title: this.translateService.instant('DASHBOARD.PREVIOUS_REPORT_TYPE_BCR_SELECT.VALUE')},
      { value: 3, title: this.translateService.instant('DASHBOARD.PREVIOUS_REPORT_TYPE_OB_SELECT.VALUE')},
      { value: 2, title: this.translateService.instant('DASHBOARD.PREVIOUS_REPORT_TYPE_RR_SELECT.VALUE')},
    ];

    this.dashboardService.reportDataArrived.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.updateList(res.reports[this.queryType]);
      this.totalReports[this.queryType] = !!res.totalPages[this.queryType] ? res.totalPages[this.queryType] : 0;
    });

    this.previousReportForm = this.formBuilder.group({
      reportType: '',
      dateRange: ''
    });

    if (this.showFilter) {
      this.onPreviousReportFormChange();
    }

  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Function to define pagination options
   * @param id Pagination id
   */
  public getPaginationOptions(id: string): PaginationOptions {
    return {
      id,
      itemsPerPage: this.itemsPerPage,
      currentPage: this.currentPage,
      totalItems: this.totalReports[this.queryType]
    };
  }

  /**
   * Page change event
   * @param pageNumber Page number
   */
  public onPageChange(pageNumber: number, queryType: number): void {
    const changeData = {
      pageNumber: pageNumber - 1,
      queryType,
      itemsPerPage: this.itemsPerPage,
    };
    this.pageChange.emit(changeData);
    this.queryType = queryType;
    this.currentPage = pageNumber;
  }

  /**
   * Function to open delete modal
   * @param id Report id
   * @param type Report type
   */
  public openDeleteReportModal(id: number, type: string, reportType: number): void {
    this.dashboardDeleteModal.openDeleteModal(id, type, this.queryType, reportType);
  }

  /**
   * Function to update pagination list
   * @param reportData Pagination data
   */
  private updateList(reportData: any): void {
    this.paginationList = [] as Reports[];
    if (reportData && reportData.length > 0) {
      for (const recievedReports of reportData) {
        this.paginationList.push(recievedReports);
      }
    }
  }

  /**
   * Function to open outstanding modal or navigate to RR/OB page
   * @param id report Id
   */
  public openOutstandingModal(id: number, reportType: number, isOutstandingItems: boolean) {
    if (isOutstandingItems) {
      this.outstandingItemsModal.openOutstandingItemsModal(id, reportType);
    } else {
      switch (reportType) {
        case this.typeOfReport.RR:
          this.router.navigate([`/create-rapporteurs-report/${id}`]);
          break;
        case this.typeOfReport.OB:
          this.router.navigate([`/create-order-of-business/${id}`]);
          break;
        default:
          break;
      }
    }
  }

  /**
   * Method to trigger previous report form value emit
   */
  public triggerPreviousReportFormValueEmit(): void {
    this.previousReportFormChange.emit(this.previousReportForm.value);
  }

  private onPreviousReportFormChange(): void {
    this.previousReportForm.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        // Need timeout because date range emits value change 2x times
        debounceTime(200),
        // Need to compare with previous value because date range emits when selecting start date in range
        filter(val => !isEqual((this.previousReportFormOldVal || {}), val) && val.dateRange !== undefined)
      )
      .subscribe(val => {
        this.onPageChange(1, this.queryType);
        this.previousReportFormOldVal = val;
      });
  }

}
