import { Component, ViewChild } from '@angular/core';
import { ModalComponent } from 'proceduralsystem-clientcomponents';
import { Router } from '@angular/router';
import { DashboardService } from '../dashboard-services/dashboard-service.service';
import { TypeOfReport } from 'src/app/shared/report.enum';

@Component({
  selector: 'oir-outstanding-work-items-modal',
  templateUrl: './outstanding-work-items-modal.component.html',
  styleUrls: ['./outstanding-work-items-modal.component.less']
})
export class OutstandingWorkItemsModalComponent {
  @ViewChild('outstandingItemsModal') outstandingItemsModal: ModalComponent;

  public itemId: number;
  public reportType: number;

  constructor(
    private router: Router,
    private dbService: DashboardService) { }

  /**
   * Function to open outstanding modal
   * @param id item id
   */
  public openOutstandingItemsModal(id: number, reportType: number): void {
    this.itemId = id;
    this.reportType = reportType;
    this.outstandingItemsModal.toggle();
  }

  /**
   * Function to view outstanding items
   */
  public viewOutstandingItems(): void {
    this.dbService.viewOutstandingItems(true);
    this.outstandingItemsModal.close();
  }

  /**
   * Function to navigate to report
   */
  public onContinue(): void {
    switch (this.reportType) {
      case TypeOfReport.RR:
        this.router.navigate([`/create-rapporteurs-report/${this.itemId}`]);
        this.outstandingItemsModal.close();
        break;
      case TypeOfReport.OB:
        this.router.navigate([`/create-order-of-business/${this.itemId}`]);
        this.outstandingItemsModal.close();
        break;
      default:
        break;
    }
  }

  /**
   * Function to close Outstanding Items modal
   */
  public cancelOutstandingItems(): void {
    this.outstandingItemsModal.close();
  }
}
