import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  Inject,
  AfterViewInit
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SendToRapporteurComponent } from './modals/send-to-rapporteur/send-to-rapporteur.component';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService, ToastNotificationsService } from 'proceduralsystem-clientcomponents';
import { ActivatedRoute, Router } from '@angular/router';
import { CancelReportComponent } from './modals/cancel-report/cancel-report.component';
import { ReportService } from '../services/report.service';
import { take, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { GLOBALS } from '../shared/globals';
import { SharedService } from '../services/shared.service';
import { RapporteurReportDays } from '../shared/models/rapporteur-report.model';
import { isEmpty } from 'lodash-es';
import { RREmailType, ReportStatuses } from '../shared/report.enum';
import { DOCUMENT } from '@angular/common';
import OirCkEditor from 'proceduralsystem-ckeditor';
import { OirCkEditorConfig } from 'proceduralsystem-ckeditor';
import { EditorConfig } from 'src/app/shared/models/editor-config.model';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'oir-rapportuers-report',
  templateUrl: './rapportuers-report.component.html',
  styleUrls: ['./rapportuers-report.component.less']
})
export class RapportuersReportComponent
  implements OnInit, OnDestroy,AfterViewInit
{
  @ViewChild('sendReportModal') sendReportModal: SendToRapporteurComponent;
  @ViewChild('cancelReportDialog') cancelReportDialog: CancelReportComponent;

  public rapporteurForm: FormGroup;
  public rapporteursData$: Observable<any>;
  public recipients$: Observable<any>;
  public dayArrangement: RapporteurReportDays[];
  public emailType = RREmailType;
  public isAnnouncementTextEmpty: boolean;
  public defaultHeaderText: string;
  public defaultAnnouncementText: string;
  public headerWithHTML: string;
  public announcementWithHTML: string;
  public rrId: string;
  public rrFileName: string;
  public rrVersion: string;
  public reportTitle: string;
  public isSingleDay: boolean;
  public reportStatus: number;
  public reportStatusId = ReportStatuses;
  private window: Window;

  Editor = OirCkEditor;
  ckEditorConfig: OirCkEditorConfig;

  public recursiveLimit = 0;

  public configToolbar = [
    { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike'] },
    { name: 'styles', items: ['Styles'] },
    { name: 'paragraph', items: ['BulletedList', 'NumberedList'] },
    { name: 'insert', items: ['Table'] },
    { name: 'tools', items: ['Maximize'] }
  ];

  private isChecked = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private reportService: ReportService,
    private sharedService: SharedService,
    private router: Router,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private navigationService: NavigationService,
    private toastService: ToastNotificationsService,
    private configurationService: AppConfigService,
    @Inject(DOCUMENT) private document: Document
    ) {
      this.window = this.document.defaultView;
      this.ckEditorConfig = {
        ...EditorConfig,
      licenseKey: this.configurationService.getValue('CKEditor5LicenseKey')
    }
    }

  public ngOnInit(): void {
    this.rrId = this.activatedRoute.snapshot.paramMap.get('documentId');
    this.activatedRoute.data.pipe(take(1)).subscribe(data => {
      if (data.createRR) {
        this.previewOrGetRapporteursReport(
          this.rrId,
          this.reportService.previewRapporteurReport(this.rrId)
        );
      } else {
        this.previewOrGetRapporteursReport(
          this.rrId,
          this.reportService.getRapporteurReport(this.rrId)
        );
      }
    });

    this.rapporteurForm = this.fb.group({
      rapporteur: [[], Validators.required]
    });

    // Stream to get rapporteurs
    this.rapporteursData$ = this.reportService.getMembers().pipe(
      take(1),
      map(members =>
        members.map(member => ({ value: member.id, title: member.name }))
      )
    );

    // Stream to get recipients
    this.recipients$ = this.reportService.getRecipients().pipe(
      take(1),
      map(recipients => {
        const filteredRecipients = recipients.filter(
          val => val.recipientEmail !== null
        );
        return filteredRecipients.map(recipient => ({
          value: recipient.recipientId,
          title: recipient.recipientEmail
        }));
      })
    );
  }

  ngAfterViewInit() {
    this.onReady()
  }
  
  /**
   * After content is checked changing aligment of Announcement ckEditor instance
   */

  /**
   * Clearing navigation on destroy
   */
  public ngOnDestroy(): void {
    this.clearNavigation();
  }

  /**
   * Check if Headers length of text is under or equal to 1000 characters
   */
  public checkHeaderMaxChars(event: any): void {
    const length = event.target.innerHTML
      .replace(/<.+?>/gi, '')
      .replace('&nbsp;', '')
      .replace('↵', '')
      .replace(/\n/g, '').length;
    if (
      length !== 0 &&
      length >= 1000 &&
      event.keyCode !== 46 &&
      event.keyCode !== 8 &&
      event.keyCode !== 37 &&
      event.keyCode !== 39
    ) {
      event.preventDefault();
    } else {
      // need timeout to detect changes
      setTimeout(() => {
        this.headerWithHTML = event.target.innerHTML;
      }, 100);
    }
  }

  /**
   * Check if Announcement length of text is under or equal to 500 characters
   */
  public checkAnnouncementMaxChars(event: any): void {
    const length = event.target.innerHTML
      .replace(/<.+?>/gi, '')
      .replace('&nbsp;', '')
      .replace('↵', '')
      .replace(/\n/g, '').length;
    if (
      length !== 0 &&
      length >= 500 &&
      event.keyCode !== 46 &&
      event.keyCode !== 8 &&
      event.keyCode !== 37 &&
      event.keyCode !== 39
    ) {
      event.preventDefault();
    } else {
      // need timeout to detect changes
      setTimeout(() => {
        this.announcementWithHTML = event.target.innerHTML;
      }, 100);
    }
  }

  /**
   * Function to check is Announcement Text is empty
   * @param event Event
   */
  public onAnnouncementTextChange(event): void {
    this.isAnnouncementTextEmpty = !isEmpty(event) ? false : true;
  }

  /**
   * Function to check is form Invalid
   */
  public isFormInvalid(): boolean {
    return this.rapporteurForm.invalid || this.isAnnouncementTextEmpty;
  }

  /**
   * Function to open Repporteur Report Modal
   */
  public openRepporteurReportModal(emailSendType: number): void {
    const reportData = {
      reportId: this.rrId,
      rapporteurId: !isEmpty(this.rapporteurForm.controls.rapporteur.value)
        ? this.rapporteurForm.controls.rapporteur.value[0].value
        : null,
      reportHeader: !isEmpty(this.headerWithHTML)
        ? this.headerWithHTML
        : this.defaultHeaderText,
      rapporteurNote: !isEmpty(this.announcementWithHTML)
        ? this.announcementWithHTML
        : this.defaultAnnouncementText
    };
    this.sendReportModal.openReportModal(emailSendType, reportData);
  }

  /**
   * Function to hide days
   * @param event event
   * @param index index
   */
  public hideDays(event, index: number) {
    const days = document.querySelector('.rr_daysAnnouncement').children;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < days.length; i++) {
      days[i].setAttribute(
        'style',
        'display: ' + (i !== index && event ? 'none' : 'block')
      );
    }
  }

  /**
   * Function to save report as draft
   * After save navigate to dashboard
   * Show toast message
   */
  public saveReportAsDraft(): void {
    const reportData = {
      reportId: this.rrId,
      rapporteurId: !isEmpty(this.rapporteurForm.controls.rapporteur.value)
        ? this.rapporteurForm.controls.rapporteur.value[0].value
        : null,
      reportHeader: !isEmpty(this.headerWithHTML)
        ? this.headerWithHTML
        : this.defaultHeaderText,
      rapporteurNote: !isEmpty(this.announcementWithHTML)
        ? this.announcementWithHTML
        : this.defaultAnnouncementText
    };
    this.reportService
      .postRapporteurReport(reportData)
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigate(['dashboard/']);
        const title = this.translateService.instant(
          'TOAST.VIEW_RAPPORTEURS_REPORT_DRAFT.TITLE'
        );
        const description = this.translateService.instant(
          'TOAST.VIEW_RAPPORTEURS_REPORT_DRAFT.BODY',
          [this.rrFileName]
        );
        this.toastService.addNotification({ title, description });
      });
  }

  /**
   * Function to cancel
   * Show Modal to confirm cancelation of report
   * If Yes navigate to dashboard and show toast message
   * If No close modal stay on same page
   */
  public cancelDraft(): void {
    this.cancelReportDialog.openCancelDialog();
  }

  /**
   * Call service to get PDF preview of report
   */
  public generatePrintPreview(): void {
    const reportData = {
      reportId: this.rrId,
      rapporteurId: !isEmpty(this.rapporteurForm.controls.rapporteur.value)
        ? this.rapporteurForm.controls.rapporteur.value[0].value
        : null,
      reportHeader: !isEmpty(this.headerWithHTML)
        ? this.headerWithHTML
        : this.defaultHeaderText,
      rapporteurNote: !isEmpty(this.announcementWithHTML)
        ? this.announcementWithHTML
        : this.defaultAnnouncementText
    };
    this.reportService
      .generateRapporteurReportPrintPreview(reportData)
      .pipe(take(1))
      .subscribe(res => {
        const file: Blob = new Blob([res], { type: 'application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file);
        } else {
          const fileURL: string = URL.createObjectURL(file);
          window.open(fileURL);
        }
      });
  }

  /**
   * Function to get or preview Repporteur Report
   * @param id Report id
   * @param getApi preview or get report API
   */
  private previewOrGetRapporteursReport(
    id: string,
    getApi: Observable<any>
  ): void {
    getApi.pipe(take(1)).subscribe(res => {
      this.defaultHeaderText = res.reportHeader;
      this.defaultAnnouncementText = res.joReportRapporteurNote;
      this.isAnnouncementTextEmpty = isEmpty(res.joReportRapporteurNote);
      this.dayArrangement = res.rapporteurReportDays;
      const startDate = this.sharedService.convertDate(
        res.businessScheduleDetailFirstDay
      );
      const endDate = this.sharedService.convertDate(
        res.businessScheduleDetailLastDay
      );
      this.rrVersion = res.reportVersion;
      this.reportStatus = res.reportStatus;
      this.isSingleDay = startDate === endDate;
      this.rrFileName =
        startDate === endDate
          ? `(${moment.utc(startDate).format(GLOBALS.dateFormat)})`
          : `(${moment.utc(startDate)
              .format(GLOBALS.dateFormat)} - ${moment
              .utc(endDate)
              .format(GLOBALS.dateFormat)})`;
      this.rapporteurForm.patchValue(
        {
          rapporteur: !isEmpty(res.rapporteur)
            ? [
                {
                  value: res.rapporteur.rapporteurId,
                  title: res.rapporteur.rapporteurName
                }
              ]
            : []
        },
        { emitEvent: false }
      );
      this.reportTitle =
        res.reportStatus === ReportStatuses.Draft
          ? this.translateService.instant(
              'VIEW_RAPPORTEURS_REPORT.PAGE_TITLE_DRAFT',
              [this.rrFileName]
            )
          : this.translateService.instant(
              'VIEW_RAPPORTEURS_REPORT.PAGE_TITLE_CIRCULATED',
              [this.rrFileName, res.reportVersion]
            );
      this.initNavigation(
        id,
        this.rrFileName,
        res.reportStatus,
        res.reportVersion
      );
    });
  }

  /**
   * Function to create navigation node
   * @param params page id
   * @param date report name
   */
  private initNavigation(
    params: any,
    date: string,
    status: number,
    version: number
  ): void {
    const title =
      status === ReportStatuses.Draft
        ? this.translateService.instant(
            'DASHBOARD.NAVIGATION_ITEM_RAPPORTEURS_REPORT.DRAFT',
            [date]
          )
        : this.translateService.instant(
            'DASHBOARD.NAVIGATION_ITEM_RAPPORTEURS_REPORT.CIRCULATED',
            [date, version]
          );
    const parentTitle = 'Dashboard';
    const taskManagerNode = this.navigationService.model.tree.find(
      x => x.title === parentTitle
    );
    this.navigationService.select(taskManagerNode);
    this.navigationService.addNode({
      path: `/rapporteurs-report/${params}`,
      title
    });
  }

  /**
   * Function to clear navigation node
   */
  private clearNavigation(): void {
    this.navigationService.select(this.navigationService.currentNode);
    const taskManagerNode = this.navigationService.model.tree.find(
      x => x.title === 'Dashboard'
    );
    taskManagerNode.expanded = false;
  }

  onReady() {
    if (this.window && this.window[0] && this.defaultHeaderText && this.recursiveLimit <= GLOBALS.recursiveMaxLimit) {
      this.window[0].document.getElementsByTagName('body')[0].style.textAlign = "center";
      this.window[0].document.getElementsByTagName('body')[0].style.fontFamily = "'Montserrat-Regular', sans-serif";
      this.window[0].document.getElementsByTagName('body')[0].style.fontSize = "15px";
      this.window[0].document.getElementsByTagName('body')[0].style.lineHeight = "20px";
      this.window[0].document.getElementsByTagName('body')[0].style.color = "rgb(68, 68, 68)";
    } else {
      setTimeout(() => {
        this.recursiveLimit++;
        this.onReady()
      }, 500);
    }
  }
}
