import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { TypeaheadValue } from 'proceduralsystem-clientcomponents';
import { Subscription, Subject, Observable } from 'rxjs';
import { isEmpty, isArray } from 'lodash-es';
import { takeUntil, map, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ReportService } from 'src/app/services/report.service';
import { SharedService } from 'src/app/services/shared.service';
import { ToggleableAccordionComponent } from 'src/app/shared/components/toggleable-accordion/toggleable-accordion.component';
import { CancelConfirmModalComponent } from 'src/app/create-report/cancel-confirm-modal/cancel-confirm-modal.component';
import { ReportStoreService } from 'src/app/services/report-store.service';
import { TranslateService } from '@ngx-translate/core';
import OirCkEditor from 'proceduralsystem-ckeditor';
import { OirCkEditorConfig } from 'proceduralsystem-ckeditor';
import { EditorConfig } from 'src/app/shared/models/editor-config.model';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'oir-day-arrangement',
  templateUrl: './day-arrangement.component.html',
  styleUrls: ['./day-arrangement.component.less']
})
export class DayArrangementComponent implements OnInit, OnDestroy {
  @Input() bsDayDate: string;
  @Input() disableDayArrangement: boolean;

  @ViewChild('dayArrangementAccordion') dayArrangementAccordion: ToggleableAccordionComponent;
  @ViewChild('cancelConfirmModalComponent') cancelConfirmModalComponent: CancelConfirmModalComponent;

  public dayArrangementForm: FormGroup;
  public arrangementData: TypeaheadValue<any>[] = [];
  public membersData$: Observable<any>;
  public canToggle = true;
  public keyUp = new Subject<{ id: number, value: string }>();
  
  Editor = OirCkEditor;
  ckEditorConfig: OirCkEditorConfig;
  
  public get arrangementsFormArray(): FormArray {
    return this.dayArrangementForm.get('arrangmentsGroup') as FormArray;
  }

  private hasInitialArrangements = false;
  private subscription = new Subscription();
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private reportService: ReportService,
    private sharedService: SharedService,
    private reportStoreService: ReportStoreService,
    private configurationService: AppConfigService
  ) {
    this.ckEditorConfig = {
      ...EditorConfig,
      licenseKey: this.configurationService.getValue('CKEditor5LicenseKey')
    }
   }

  public ngOnInit(): void {
    this.dayArrangementForm = this.formBuilder.group({
      arrangmentsGroup: this.formBuilder.array([]),
      proposal: ['', [Validators.maxLength(250), Validators.required]]
    });

    this.membersData$ = this.reportService.members
      .pipe(map(members => members.map(member => ({ value: member.id, title: member.name}))));

    this.reportService.dayArrangement
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map(arrengements => arrengements
          .map((arrengement, i) => ({value: i, title: arrengement}))))
      .subscribe(res => this.arrangementData = res);

    this.subscribeToFormValueChange();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Method to get data for Arrangement input
   */
  public getArrangementData(): TypeaheadValue<any>[] {
    return this.sharedService.filterTypeAheadArrayForNoPillsInputTypeAheadBasedOnTitle(this.arrangementsFormArray, 'arrangement', this.arrangementData);
  }


  public onArrangementChange(id: number, value: TypeaheadValue<any>) {
    if (value) {
      this.keyUp.next({ id, value: value.title });
    }
  }

  public onNoArrangementResults(id: number, value: string): void {
    const arrangementFormGroup = this.arrangementsFormArray.controls[id] as FormGroup;
    const arrangementFormControl = arrangementFormGroup.get('arrangement');
    if (arrangementFormControl) {
      arrangementFormControl.setValue(value);
    }
  }

  /**
   * Method to save day arrangement accordion data
   */
  public save(): void {
    const arrangement = [];
    const proposalTitle = this.dayArrangementForm.controls.proposal.value;
    const items = this.arrangementsFormArray;
    for (let i = 0; i < items.length; i++) {
      if (items.controls[i].get('arrangement').value.length > 0) {
        const obj = {
          arrangementId: items.controls[i].get('arrangementId').value || 0,
          arrangementDescription: items.controls[i].get('arrangement').value,
          isDissentingMember: items.controls[i].get('dissenting').value,
          isArrangementAppearOnFront: true,
          arrangementMembers: items.controls[i].get('arrangementMembers').value.map((item) => {
            return {
              id: item.value,
              name: item.title
            };
          })
        };
        arrangement.push(obj);
      }
    }
    const dayArrangementsCleared = this.hasInitialArrangements && arrangement.length === 0;
    this.reportStoreService.setCustomArrangements(arrangement, proposalTitle, this.bsDayDate, dayArrangementsCleared);
    this.closeAccordion();
  }
  /**
   * Method that is triggered every time before toggling day arrangement accordion
   * @param isOpening indicates if accordion is going to be opened
   */
  public onDayArrangementAccordionToggle(isOpening: boolean): void {
    if (isOpening) {
      this.populateDayArrangementForm();
    }
  }

  /**
   * Method to check if passed object is not empty and therefore has value
   * @param value
   */
  public hasValue(value): boolean {
    return !isEmpty(value);
  }

  /**
   * Method that is triggered every time after accordion toggle has happened
   * @param isOpen indicates if accordion is opened or closed
   */
  public afterDayArrangementAccordionToggle(isOpen: boolean): void {
    this.canToggle = !isOpen;
  }

  /**
   * Method to trigger cancelConfirmModal when accordion close toggle occurs
   */
  public onAccordionClose(): void {
    this.disableDayArrangement ? this.closeAccordion() :
      this.cancelConfirmModalComponent.toggle();
  }

  /**
   * Method to hande cancelConfirmModal response
   * @param event
   */
  public cancelModalDecision(event): void {
    if (event) {
      this.closeAccordion();
    }
  }

  /**
   * Method to populate day arrangement form
   */
  public populateDayArrangementForm(): void {
    // Geting exsisting arrangements
    const arrangements = this.reportStoreService.getCustomArrangements(this.bsDayDate);
    const arrangementsProposalTitle = this.reportStoreService.getArrangementProposalTitle(this.bsDayDate);
    this.hasInitialArrangements = !isEmpty(arrangements);
    if (this.hasInitialArrangements) {
      // Loop try arrangements and populate form
      arrangements.forEach((arrangement, i) => {
        this.addArrangementControl(
          arrangement.arrangementId,
          { value: i, title: arrangement.arrangementDescription},
          arrangement.isDissentingMember,
          arrangement.arrangementMembers.map(x => ({value: x.id, title: x.name}))
        );
      });
    } else {
      // Create default control
      this.addArrangementControl();
    }
    // If there is proposal title assign value, if no then add default value
    this.dayArrangementForm.patchValue({
      proposal: arrangementsProposalTitle || this.translateService.instant('DAY_ARRANGEMENT_ACCORDION.PROPOSAL_INPUT_VALUE')
    });
  }

  /**
   * Method to check if form is valid
   */
  public isFormValid(): boolean {
    return (this.dayArrangementForm.valid && this.firstArrangementInputHasValue()) ||
      (this.dayArrangementForm.valid && this.dayHasArrangements());
  }

  /**
   * Method to check if first arrangement input has value
   */
  public firstArrangementInputHasValue(): boolean {
    const firstArrangement = this.arrangementsFormArray.controls[0] as FormGroup;

    return !isEmpty(firstArrangement) &&
      !isEmpty(firstArrangement.controls.arrangement.value);
  }

  /**
   * Function to check is day instance has arrangements
   */
  private dayHasArrangements(): boolean {
    const arrangements = this.reportStoreService.getCustomArrangements(this.bsDayDate);

    return arrangements ? arrangements.length > 0 : false;
  }

  /**
   * Method to close day arrangement accordion
   */
  private closeAccordion(): void {
    this.canToggle = true;

    // Need to timeout to allow change detection to work for canToggle
    setTimeout(() => {
      this.dayArrangementAccordion.click();

      // Clear formArray
      while (this.arrangementsFormArray.length) {
        this.arrangementsFormArray.removeAt(0);
      }
      this.dayArrangementForm.reset();
    }, 1);
  }

  /**
   * Metod to add form control to arrangements list
   * @param index
   */
  private addArrangementControl(arrangementId?: number | string, arrangement?: any, dissenting?: any, arrangementMembers?: any): void {
    const arrangementForm: FormGroup = this.formBuilder.group({
      arrangementId: '',
      arrangement: [[]],
      dissenting: false,
      arrangementMembers: [[], Validators.required]
    });
    this.arrangementsFormArray.push(arrangementForm);

    this.subscription.add(
      arrangementForm.controls.arrangement.valueChanges
        .pipe(filter(val => !isEmpty(val) && isArray(val) && !isEmpty(val[0])))
        .subscribe(res => {
          arrangementForm.controls.arrangement.patchValue(res[0].title);
        }));

    this.sharedService.showFieldIfOtherFieldValueIs(this.subscription, arrangementForm, 'dissenting', true, 'arrangementMembers');
    // If arrangement exsist populate form
    if (arrangement) {
      arrangementForm.patchValue({
        arrangementId,
        arrangement: [arrangement],
        dissenting,
        arrangementMembers
      });
    }
  }

  /**
   * Method to subscribe to form value change to add/remove arrangement form groups
   */
  private subscribeToFormValueChange(): void {
    this.subscription.add(this.dayArrangementForm.valueChanges.subscribe(formValue => {
      if (isArray(formValue.arrangmentsGroup)) {

        // Loop through arrangementsFormArray and remove empty input form groups
        formValue.arrangmentsGroup.forEach((element, i) => {
          // Skip last element as it can be empty
          if (i < formValue.arrangmentsGroup.length - 1 &&
            isEmpty(element.arrangement)) {
            this.arrangementsFormArray.removeAt(i);
          }
        });

        // If last form group has Arrangement value then add new form group
        if (!isEmpty(formValue.arrangmentsGroup[formValue.arrangmentsGroup.length - 1]) &&
          !isEmpty(formValue.arrangmentsGroup[formValue.arrangmentsGroup.length - 1].arrangement)) {
          this.addArrangementControl();
        }
        this.keyUp.pipe(
          debounceTime(2000),
          distinctUntilChanged(),
          takeUntil(this.ngUnsubscribe)
        ).subscribe(res => {
          const arrangement = this.arrangementsFormArray.controls[res.id] as FormGroup;
          if (isEmpty(arrangement.controls.arrangement.value)) {
            arrangement.controls.arrangement.setValue([{ value: 0, title: res.value }], { onlySelf: true, emitEvent: false });
          }
        });
      }
    }));
  }

  validateArrangementLength(arrangement: FormControl, value: string) {
    return this.sharedService.validateArrangementLength(arrangement, value);
  }
}
