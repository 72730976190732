import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ReportService } from '../../../services/report.service';
import { SharedService } from '../../../services/shared.service';
import { SelectValue, TypeaheadValue } from 'proceduralsystem-clientcomponents';
import { Subscription, Observable, Subject, of } from 'rxjs';
import {
  take,
  map,
  takeUntil,
  filter,
  debounceTime,
  distinctUntilChanged
} from 'rxjs/operators';
import { ToggleableAccordionComponent } from '../toggleable-accordion/toggleable-accordion.component';
import {
  Amendments,
  WorkItemTypes,
  WorkItemTypeValues,
  FormTemplate
} from '../../report.enum';
import { WorkItem } from '../../models/report.model';
import { isEmpty, findIndex, isArray } from 'lodash-es';
import { GLOBALS } from '../../globals';
import { TranslateService } from '@ngx-translate/core';
import { OirDialogService } from 'proceduralsystem-clientcomponents/components/oir-dialog/oir-dialog.service';
import { DIALOG_POSITION, OirDialogButton } from 'proceduralsystem-clientcomponents/components/oir-dialog/oir-dialog.types';
import OirCkEditor from 'proceduralsystem-ckeditor';
import { OirCkEditorConfig } from 'proceduralsystem-ckeditor';
import { EditorConfig } from '../../models/editor-config.model';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';

const FIRST_STAGE_ID = 1;

@Component({
  selector: 'oir-work-item-form',
  templateUrl: './work-item-form.component.html',
  styleUrls: ['./work-item-form.component.less']
})
export class WorkItemFormComponent implements OnInit, OnDestroy {
  @Input() saveButtonTitle = 'BUTTONS.ADD';
  @Input() workItem: WorkItem = null;
  @Input() sectionData;
  @Input() sectionType: any;
  @Input() disableWorkItem: boolean;

  @Output() saveWorkItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() accordionClosed: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('workItemAccordion')
  workItemAccordion: ToggleableAccordionComponent;

  public workItemForm: FormGroup;
  public workItemTypesPerSection = {
    motions: ['motions'],
    governmentBusiness: ['motions', 'bills', 'statements'],
    privateMembersBusiness: ['motions', 'bills'],
    privateMembersBill: ['motions', 'bills'],
    billsForIntroduction: ['bills'],
    referralToCommittee: ['bills'],
    deferredDivisions: ['motions', 'bills'],
  };
  public workItemTypeData = [];
  public workItemBillOrMotionData: TypeaheadValue<string>[] = [];
  public workItemBillOrMotionDataWithTags: TypeaheadValue<string>[] = [];
  public canToggle = true;
  public arrangementData: TypeaheadValue<string>[] = [];
  public membersData$: Observable<any>;
  public keyUp = new Subject<{ id: number; value: string }>();
  
  Editor = OirCkEditor;
  ckEditorConfig: OirCkEditorConfig;

  // Used to indicate if form is used to add new or edit existing work item
  // false = add new
  // true = edit existing
  public editMode = false;
  public sponsor = '';
  public currentStage = '';
  public workItemTypes = WorkItemTypes;
  public workItemType = '';
  public noConfirm: boolean = false;

  public get arrangementsFormArray(): FormArray {
    return this.workItemForm.get('arrangmentsGroup') as FormArray;
  }
  public stagesData: SelectValue<any>[];
  public FormTemplate = FormTemplate;
  public workItemsSponsors;
  public billStageId: number;
  public amendmentTypes: SelectValue<any>[] = [
    {
      value: Amendments.Amendment,
      title: 'Amendment'
    },
    {
      value: Amendments.AmendmentToAmendment,
      title: 'Amendment to an amendment'
    },
  ];

  private subscription: Subscription = new Subscription();
  private ngUnsubscribe = new Subject<void>();
  private billMotionStatementData = [];

  private workItemOtherValue = {
    value: GLOBALS.workItemOtherValue,
    title: this.translateService.instant(
      'WORK_ITEM_FORM.WORK_ITEM_SELECT.VALUE.OTHER'
    )
  };

  constructor(
    private formBuilder: FormBuilder,
    private reportService: ReportService,
    private sharedService: SharedService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private dialogService: OirDialogService,
    private configurationService: AppConfigService
  ) {
    this.ckEditorConfig = {
      ...EditorConfig,
      licenseKey: this.configurationService.getValue('CKEditor5LicenseKey')
    }
  }

  public ngOnInit(): void {
    this.workItemForm = this.formBuilder.group({
      workItemId: '',
      workItemType: ['', Validators.required],
      workItemBillOrMotion: [
        { value: [], disabled: true },
        Validators.required
      ],
      billStages: [{ value: [], disabled: true }, Validators.required],
      businessDaySectionWorkItemPlaceholder: [
        { value: '', disabled: true },
        [Validators.required, Validators.maxLength(250)]
      ],
      arrangmentsGroup: this.formBuilder.array([]),
      proposalTitle: [
        { value: '', disabled: true },
        [Validators.maxLength(250), Validators.required]
      ],
      motionSponsors: [{ value: null, disabled: true }],
      billSponsors: [{ value: null, disabled: true }],
      referralBillStage: [{value: false, disabled: true }],
      billStage: [{ value: null, disabled: true }, Validators.required],
      amendmentType: null,
    });

    this.membersData$ = this.reportService.members.pipe(
      map(members =>
        members.map(member => ({ value: member.id, title: member.name }))
      )
    );

    this.reportService.workItemArrangement
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map(arrengements =>
          arrengements.map(
            (arrengement, i) => ({ value: i, title: arrengement })
          )
        )
      )
      .subscribe(res => (this.arrangementData = res));

    this.workItemChange();
    this.onWorkItemChange();
    this.subscribeToFormValueChange();

    if (!isEmpty(this.workItem)) {
      this.editMode = true;

      switch (this.workItem.workItemTypeInfo) {
        case WorkItemTypeValues.bills:
          this.workItemForm.controls.workItemType.patchValue(
            WorkItemTypes.bills
          );
          break;
        case WorkItemTypeValues.motions:
          this.workItemForm.controls.workItemType.patchValue(
            WorkItemTypes.motions
          );
          break;
        case WorkItemTypeValues.statements:
          this.workItemForm.controls.workItemType.patchValue(
            WorkItemTypes.statements
          );
          break;

        default:
          break;
      }
    } else if (
      this.sectionData &&
      this.sectionData.businessDaySectionTypeId === FormTemplate.motions
    ) {
      this.workItemForm.controls.workItemType.patchValue(WorkItemTypes.motions);
    } else if (this.isSectionBillsForIntroduction || this.isSectionReferralCommittee) {
      this.workItemForm.controls.workItemType.patchValue(WorkItemTypes.bills);
      this.onWorkItemSelect();
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Function to check max chars
   */
  public maxSearchSelectChar(event) {
    const length = event.target.value.length;
    if (
      length !== 0 &&
      length >= 1000 &&
      event.keyCode !== 46 &&
      event.keyCode !== 8 &&
      event.keyCode !== 37 &&
      event.keyCode !== 39
    ) {
      event.preventDefault();
    }
  }

  /**
   * Method to get data for Arrangement input
   */
  public getArrangementData(): TypeaheadValue<string>[] {
    return this.sharedService.filterTypeAheadArrayForNoPillsInputTypeAheadBasedOnTitle(
      this.arrangementsFormArray,
      'arrangement',
      this.arrangementData
    );
  }

  /**
   * Method to handle save click
   */
  public onSave(): void {
    // User getRawValue because we need value from disabled fields
    this.noConfirm = true;
    const data = {
      form: this.workItemForm.getRawValue(),
      workItemDataWithTags: this.workItemBillOrMotionDataWithTags
    };

    if (this.isSectionReferralCommittee && data.form.billStage !== null) {
      data.form.billStages = [data.form.billStage];
    } else if (
      this.isSectionBillsForIntroduction
      && (!data.form.billStages || !data.form.billStages.length)
    ) {
      // Bills for introduction is constantly first stage (see template condition of #isFirstStage)
      data.form.billStages = [FIRST_STAGE_ID];
    }

    this.saveWorkItem.emit(data);
    this.processCancelationDecision(true);
  }

  /**
   * Method to close accordion
   */
  private closeAccordion(): void {
    this.canToggle = true;
    setTimeout(() => {
      this.workItemAccordion.click();
      this.workItemType = '';
      this.workItemForm.controls.workItemType.enable({ emitEvent: false });
      this.workItemForm.controls.billStages.disable({ emitEvent: false });

      if (!this.isSectionBillsForIntroduction && !this.isSectionReferralCommittee) {
        this.workItemForm.controls.workItemBillOrMotion.disable({
          emitEvent: false
        });
      }
      this.workItemForm.reset();
    }, 100);
  }

  /**
   * Function to clear work item for on template change
   */
  public onAddNewSectionTemplateChange(): void {
    setTimeout(() => {
      if (!this.canToggle) {
        this.noConfirm = true;
        this.processCancelationDecision(true);
      }
      this.workItemForm.reset();
      this.workItemForm.controls.workItemType.enable({ emitEvent: false });
      this.workItemForm.controls.workItemBillOrMotion.disable({
        emitEvent: false
      });
      this.workItemForm.controls.billStages.disable({ emitEvent: false });
    }, 100);
  }
  /**
   * Toggle Cancel Modal
   */
  public confirmCancel() {
    if (!this.noConfirm) {
      this.disableWorkItem
        ? this.processCancelationDecision(true)
        : this.dialogService.open({
          title: 'DIALOG.CANCEL_CONFIRM.TITLE',
          message: 'DIALOG.CANCEL_CONFIRM.TEXT',
          confirmButton: OirDialogButton.YES,
          cancelButton: OirDialogButton.NO,
          position: DIALOG_POSITION.CENTER,
          onConfirm: () => {
            this.processCancelationDecision(true);
            return of(true);
          },
          onCancel: () => {
            this.processCancelationDecision(false);
            return of(false);
          }
        });
    }
    this.noConfirm = false;
  }

  /**
   * Method to hande cancelConfirmModal response
   * @param decision - confirm cancelation
   */
  public processCancelationDecision(decision: boolean): void {
    if (decision) {
      this.closeAccordion();
      // Need to timeout to allow change detection to work for canToggle
      setTimeout(() => {
        // Clear formArray
        while (this.arrangementsFormArray.length) {
          this.arrangementsFormArray.removeAt(0);
        }
        this.workItemForm.controls.amendmentType.setValue(null ,{ emitEvent: false });
        this.workItemForm.controls.billStages.setValue([] ,{ emitEvent: false });
        this.workItemForm.controls.billStages.disable({ emitEvent: false });
        this.accordionClosed.emit(true);
      }, 100);
    }
  }

  /**
   * Method that is triggered every time after accordion toggle has happened
   * @param isOpen indicates if accordion is opened or closed
   */
  public afterWorkItemAccordionToggle(isOpen: boolean): void {
    this.canToggle = !isOpen;
    if (
      (this.sectionData &&
        this.sectionData.businessDaySectionTypeId === FormTemplate.motions &&
        !this.editMode) ||
      (this.sectionType === FormTemplate.motions && !this.editMode)
    ) {
      this.workItemForm.controls.workItemType.patchValue(WorkItemTypes.motions);
    } else {
      this.generateWorkItemsTypeData();
    }

    // Because we need accoridon to be already opened we must run change detection because canToggle changes on onInit in accordion component
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Method to check if passed object is not empty and therefore has value
   * @param value
   */
  public hasValue(value): boolean {
    return !isEmpty(value);
  }

  /**
   * Method to check if form is valid
   */
  public isFormValid(): boolean {
    return (
      this.workItemForm.valid ||
      (this.arrangementsFormArray.length === 1 &&
        isEmpty(
          (this.arrangementsFormArray.controls[0] as FormGroup).controls
            .arrangement.value
        ) &&
        !(this.arrangementsFormArray.controls[0] as FormGroup).controls
          .dissenting.value &&
        this.workItemHasArrangements())
    );
  }

  /**
   * Method to check if first arrangement input has value
   */
  public firstArrangementInputHasValue(): boolean {
    const firstArrangement = this.arrangementsFormArray
      .controls[0] as FormGroup;

    return (
      !isEmpty(firstArrangement) &&
      !isEmpty(firstArrangement.controls.arrangement.value)
    );
  }

  /**
   * If work item type is set to Bill, the multi select
   * "Stages to be conidered by Dail" needs to be displayed.
   */
  public enableBillStages(type: string): void {
    if (type === WorkItemTypes.bills) {
      this.reportService
        .getStagesToConsider(this.isSectionReferralCommittee ? 'referral' : '')
        .pipe(take(1))
        .subscribe(res => {
          this.stagesData = res.map(
            x => ({ value: x.stageToConsiderId, title: x.stageToConsiderName })
          );
          if (this.isSectionReferralCommittee) {
            this.workItemForm.get('referralBillStage').enable({ emitEvent: false });
          } else {
            this.workItemForm.get('billStages').enable({ emitEvent: false });
          }
        });
    } else {
      this.workItemForm.get('billStages').disable({ emitEvent: false });
    }
  }

  /**
   * Max character in arrangments
   */
  public maxArrangementChar(event): any {
    const length =  event.target.value !== undefined
      ? event.target.value.length
      : event.target.innerText;
    if (
      length !== 0 &&
      length >= 2000 &&
      event.keyCode !== 46 &&
      event.keyCode !== 8 &&
      event.keyCode !== 37 &&
      event.keyCode !== 39
    ) {
      event.preventDefault();
    }
  }

  public onArrangementChange(id: number, value: TypeaheadValue<string>) {
    if (value) {
      this.keyUp.next({ id, value: value.title });
    }
  }

  public onNoArrangementResults(id: number, value: string): void {
    const arrangementFormGroup = this.arrangementsFormArray.controls[
      id
    ] as FormGroup;
    const arrangementFormControl = arrangementFormGroup.get('arrangement');
    if (arrangementFormControl) {
      arrangementFormControl.setValue(value);
    }
  }

  /**
   * Generates work items type select data
   */
  public generateWorkItemsTypeData() {
    this.workItemTypeData = [];
    if (!this.editMode) {
      this.workItemTypesPerSection[
        FormTemplate[
          this.sectionData
            ? this.sectionData.businessDaySectionTypeId
            : this.sectionType
        ]
      ].forEach(element => {
        this.workItemTypeData.push(
          {
            value: WorkItemTypes[element],
            title: this.translateService.instant(`WORK_ITEM_FORM.WORK_ITEM_TYPE_SELECT.VALUE.${element.toUpperCase()}`)
          }
        );
      });
    }
  }

  /**
   * Function to select work item
   * @param val Optional Work item type
   */
  public onWorkItemSelect(val?: string): void {
    val = val ? val : this.workItemForm.controls.workItemType.value;

    if (!val) {
      return;
    }

    if (!this.isSectionBillsForIntroduction) {
      this.enableBillStages(val);
    }

    this.workItemType = val;
    
    // Get values for work items typeahead
    this.reportService
      .getWorkItems(val, this.billsEndpoint)
      .pipe(take(1))
      .subscribe(res => {
        this.workItemBillOrMotionData = [this.workItemOtherValue];
        this.workItemBillOrMotionDataWithTags = [this.workItemOtherValue];
        this.billMotionStatementData = res ? res : [];
        this.fillWorkItemBillOrMotionData();
        // Handle form controls
        this.workItemForm.controls.workItemBillOrMotion.enable({
          emitEvent: false
        });
        this.workItemForm.controls.workItemType.disable({ emitEvent: false });
        this.populateForm();
      });
  }

  /**
   * Function on work item change
   */
  public onWorkItemChange(): void {
    this.subscription.add(
      this.workItemForm.controls.workItemType.valueChanges.subscribe(_val => {
        if (
          this.editMode ||
          (this.sectionData &&
            this.sectionData.businessDaySectionTypeId ===
              FormTemplate.motions) ||
          this.sectionType === FormTemplate.motions
        ) {
          this.onWorkItemSelect();
        } else if (!this.editMode
            && (this.isSectionBillsForIntroduction || this.isSectionReferralCommittee)) {
          this.workItemForm.controls.workItemType.patchValue(WorkItemTypes.bills, { emitEvent: false });
          this.onWorkItemSelect();
        }
      })
    );
  }

  /**
   * Method to check if work item has arrangements
   */
  private workItemHasArrangements(): boolean {
    return (
      !isEmpty(this.workItem) && !isEmpty(this.workItem.arrangementOfWorkItems)
    );
  }

  /**
   * Method to populate form
   */
  private populateForm(): void {
    if (this.editMode && !isEmpty(this.workItem)) {
      this.adjustWorkItemData();

      // If stages to consider exist - populate form
      if (this.workItem.billStagesToConsider) {
        this.populateBillStagesToConsider();
      }

      if (this.workItem.amendmentType) {
        this.workItemForm.controls.amendmentType.patchValue(this.workItem.amendmentType, {
          emitEvent: false
        });
      }

      if (!isEmpty(this.workItem.arrangementOfWorkItems)) {
        this.workItem.arrangementOfWorkItems.forEach(item =>
          this.addArrangementControl(
            item.arrangementOfWorkItemId,
            item.arrangementOfWorkItemDescription,
            item.isDissentingMembersAssigned,
            item.isArrangementAppearOnFront,
            item.dissentingMembers
          )
        );
        this.workItemForm.controls.proposalTitle.patchValue(
          this.workItem.arrangementOfWorkItemProposal
        );
      } else {
        this.addArrangementControl();
      }
    } else {
      this.addArrangementControl();
    }
  }

  /**
   * Method to subscribe to work item value change
   */
  private workItemChange(): void {
    this.subscription.add(
      this.workItemForm.controls.workItemBillOrMotion.valueChanges.subscribe((val) => {
        this.workItemChangeHandler(val);
      })
    );
  }

  /**
   * Metod to add form control to arrangements list
   * @param index
   */
  private addArrangementControl(
    arrangementId?: number | string,
    arrangement?: any,
    dissenting?: any,
    isArrangementAppearOnFront?: boolean,
    arrangementMembers?: any
  ): void {
    const arrangementForm: FormGroup = this.formBuilder.group({
      arrangementId: '',
      arrangement: [[]],
      dissenting: false,
      isArrangementAppearOnFront: false,
      arrangementMembers: [[], [Validators.required]]
    });
    this.arrangementsFormArray.push(arrangementForm);

    this.subscription.add(
      arrangementForm.controls.arrangement.valueChanges
        .pipe(filter(val => !isEmpty(val) && isArray(val) && !isEmpty(val[0])))
        .subscribe(res => {
          arrangementForm.controls.arrangement.patchValue(res[0].title);
        })
    );
    this.sharedService.showFieldIfOtherFieldValueIs(
      this.subscription,
      arrangementForm,
      'dissenting',
      true,
      'arrangementMembers'
    );
    this.sharedService.addValidatorIfOtherFieldHasValue(
      this.subscription,
      arrangementForm,
      'dissenting',
      [Validators.required],
      'arrangement'
    );
    this.sharedService.addValidatorIfOtherFieldHasValue(
      this.subscription,
      arrangementForm,
      'isArrangementAppearOnFront',
      [Validators.required],
      'arrangement'
    );

    // If arrangement exsist populate form
    if (arrangement) {
      arrangementForm.patchValue({
        arrangementId,
        arrangement: [{ value: 1, title: arrangement}],
        dissenting,
        isArrangementAppearOnFront,
        arrangementMembers: !isEmpty(arrangementMembers)
          ? arrangementMembers.map(
              element => ({ value: element.id, title: element.name })
            )
          : []
      });
    }
  }

  /**
   * Method to subscribe to form value change to add/remove arrangement form groups
   */
  private subscribeToFormValueChange(): void {
    this.keyUp
      .pipe(
        debounceTime(2000),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(res => {
        const arrangement = this.arrangementsFormArray.controls[
          res.id
        ] as FormGroup;
        if (arrangement && isEmpty(arrangement.controls.arrangement.value)) {
          arrangement.controls.arrangement.setValue(
            [{ value: 0, title: res.value }],
            { onlySelf: true, emitEvent: false }
          );
        }
      });
    this.subscription.add(
      this.workItemForm.controls.arrangmentsGroup.valueChanges.subscribe(
        formValue => {
          if (isArray(formValue)) {
            // Loop through arrangementsFormArray and remove empty input form groups
            formValue.forEach((element, i) => {
              // Skip last element as it can be empty
              if (i < formValue.length - 1 && isEmpty(element.arrangement)) {
                this.arrangementsFormArray.removeAt(i);
              }
            });

            // If last form group has Arrangement value then add new form group
            if (
              !isEmpty(formValue[formValue.length - 1]) &&
              !isEmpty(formValue[formValue.length - 1].arrangement)
            ) {
              this.addArrangementControl();
            }
          }

          // Enable/disable and clear proposal control
          this.workItemForm.controls.proposalTitle[
            this.firstArrangementInputHasValue() ? 'enable' : 'disable'
          ]({ emitEvent: false });
          if (!this.firstArrangementInputHasValue()) {
            this.workItemForm.controls.proposalTitle.setValue(
              !isEmpty(this.workItemForm.controls.workItemBillOrMotion.value) &&
                this.workItemForm.controls.workItemBillOrMotion.value[0]
                  .title !== 'Other'
                ? this.workItemForm.controls.workItemBillOrMotion.value[0].title.split(
                    '['
                  )[0]
                : ''
            );
          }
        }
      )
    );
  }

  private clearHtmlTags(value: any): string {
    const element = document.createElement('div');
    element.innerHTML = value;
    value = element.textContent;
    return value.replace(/[\xA0\x20]/g, ' ');
  }

  validateArrangementLength(arrangement: FormControl, { editor }: ChangeEvent ) {
    const value = editor.getData();

    return this.sharedService.validateArrangementLength(arrangement, value);
  }

  get isSectionBillsForIntroduction(): boolean {
    return (this.sectionData
        && this.sectionData.businessDaySectionTypeId
        === FormTemplate.billsForIntroduction)
      || this.sectionType === FormTemplate.billsForIntroduction;
  }

  get isSectionReferralCommittee(): boolean {
    return (this.sectionData
        && this.sectionData.businessDaySectionTypeId
        === FormTemplate.referralToCommittee)
      || this.sectionType === FormTemplate.referralToCommittee
  }

  get isSectionGovernmentBusiness(): boolean {
    return (this.sectionData &&
      this.sectionData.businessDaySectionTypeId === FormTemplate.governmentBusiness)
      || this.sectionType === FormTemplate.governmentBusiness;  
  }

  get isSectionDeferredDivisions(): boolean {
    return (this.sectionData &&
      this.sectionData.businessDaySectionTypeId === FormTemplate.deferredDivisions)
      || this.sectionType === FormTemplate.deferredDivisions;  
  }

  public checkBillStage() {
    if (this.workItemForm.controls.referralBillStage.value) {
      this.workItemForm.controls.billStage.enable({emitEvent: false});
    } else {
      this.workItemForm.controls.billStage.reset(null, {
        emitEvent: false
      });
      this.workItemForm.controls.billStage.disable({emitEvent: false});
    }
  }

  private setBillStage(stageId: number) {
    this.workItemForm.controls.referralBillStage.patchValue(true, {
      emitEvent: false
    });
    this.workItemForm.controls.billStage.patchValue(stageId, {
      emitEvent: false
    });
    this.workItemForm.controls.billStage.enable({ emitEvent: false });
  }

  private get billsEndpoint(): string {
    if (this.workItemType !== 'bills') {
      return '';
    }

    if (this.isSectionGovernmentBusiness || this.isSectionDeferredDivisions) {
      return 'billsWithDepartment';
    } else if (
      (this.sectionData &&
        this.sectionData.businessDaySectionTypeId ===
          FormTemplate.privateMembersBusiness) ||
      this.sectionType === FormTemplate.privateMembersBusiness ||
      (this.sectionData &&
        this.sectionData.businessDaySectionTypeId ===
          FormTemplate.privateMembersBill) ||
      this.sectionType === FormTemplate.privateMembersBill
    ) {
      return 'billsWithChamber';
    } else if (this.isSectionBillsForIntroduction) {
      return 'billsForIntroduction';
    } else if (this.isSectionReferralCommittee) {
      return 'billsReferralToCommittee';
    } else {
      return 'bills';
    }
  }

  private fillWorkItemBillOrMotionData(): void {
    this.billMotionStatementData.forEach(x => {
      switch (this.workItemType) {
        case 'statements':
          this.workItemBillOrMotionDataWithTags.push(
            {
              value: x.statementId,
              title: x[
              'statementTitle' +
              (this.translateService.currentLang === 'en'
                ? 'ENG'
                : 'GLE')
                ]
            }
          );
          this.workItemBillOrMotionData.push(
            {
              value: x.statementId,
              title: this.clearHtmlTags(
                x[
                'statementTitle' +
                (this.translateService.currentLang === 'en'
                  ? 'ENG'
                  : 'GLE')
                  ]
              )
            }
          );
          break;
        case 'motions':
          this.workItemBillOrMotionDataWithTags.push(
            {
              value: x.motionId,
              title: x[
              'motionTitle' +
              (this.translateService.currentLang === 'en'
                ? 'ENG'
                : 'GLE')
                ]
            }
          );
          this.workItemBillOrMotionData.push(
            {
              value: x.motionId,
              title: this.clearHtmlTags(
                x[
                'motionTitle' +
                (this.translateService.currentLang === 'en'
                  ? 'ENG'
                  : 'GLE')
                  ]
              )
            }
          );
          break;
        default:
          this.workItemBillOrMotionDataWithTags.push(
            {
              value: x.billID,
              title: x[
              'billTitle' +
              (this.translateService.currentLang === 'en'
                ? 'ENG'
                : 'GLE')
                ]
            }
          );
          this.workItemBillOrMotionData.push(
            {
              value: x.billID,
              title: x[
                'billTitle' +
                (this.translateService.currentLang === 'en'
                  ? 'ENG'
                  : 'GLE')
              ]
            }
          );
      }
    });
  }

  private adjustWorkItemData(): void {
    if (!this.workItem.isWorkItemOutstanding) {
      const index = findIndex(this.workItemBillOrMotionData, [
        'title',
        this.getTitleTag()
      ]);
      if (index > -1) {
        this.workItemForm.controls.workItemBillOrMotion.patchValue([
         this.workItemBillOrMotionData[index]
        ]);
      }
    } else {
      this.workItemForm.controls.workItemBillOrMotion.patchValue([
        this.workItemOtherValue
      ]);
      this.workItemForm.controls.businessDaySectionWorkItemPlaceholder.patchValue(
        this.workItem.businessDaySectionWorkItemPlaceholder
      );
    }
    this.workItemForm.controls.workItemId.patchValue(
      this.workItem.workItemId || ''
    );
  }

  private getTitleTag(): string {
    if (this.workItem.workItemTitles) {
      return this.clearHtmlTags(this.workItem.workItemTitles[0])
    }

    const titleLangTag = this.translateService.currentLang === 'en' ? 'Eng' : 'Gle';

    return (this.isSectionBillsForIntroduction || this.isSectionReferralCommittee)
            ? this.clearHtmlTags(this.workItem.arrangementOfWorkItemProposal)
            : this.clearHtmlTags(this.workItem[`workItemTitle${titleLangTag}`])
  }

  private populateBillStagesToConsider(): void {
    const selectedStages = [];
    this.workItem.billStagesToConsider.forEach(x => {
      selectedStages.push(x);
    });

    if (this.isSectionReferralCommittee && selectedStages.length) {
      this.setBillStage(selectedStages[0]);
    } else {
      this.workItemForm.controls.billStages.patchValue(selectedStages, {
        emitEvent: false
      });
    }
  }

  private workItemChangeHandler(val: TypeaheadValue<string>[]): void {
    // Handle 'other' work item field
    this.workItemForm.controls.businessDaySectionWorkItemPlaceholder[
      !isEmpty(val) && val[0].value === GLOBALS.workItemOtherValue
        ? 'enable'
        : 'disable'
    ]({ emitEvent: false });

    this.workItemForm.controls.businessDaySectionWorkItemPlaceholder.setValue(
      '',
      { emitEvent: false }
    );

    this.workItemForm.controls.motionSponsors.setValue('', {
      emitEvent: false
    });

    this.workItemForm.controls.proposalTitle.setValue(
      !isEmpty(this.workItemForm.controls.workItemBillOrMotion.value) &&
        this.workItemForm.controls.workItemBillOrMotion.value[0].title !==
          'Other'
        ? this.workItemForm.controls.workItemBillOrMotion.value[0].title.split(
            '['
          )[0]
        : ''
    );

    // Handle getting sponsor value
    this.sponsor = '';
    this.currentStage = '';

    if (
      !isEmpty(val) &&
      val[0].value !== GLOBALS.workItemOtherValue &&
      this.workItemForm.controls.workItemType.value
    ) {
      this.setWorkItemSponsor();
    }
  }

  private setWorkItemSponsor(): void {
      switch (this.workItemForm.controls.workItemType.value) {
        case WorkItemTypes.bills:
          this.setBillsSponsor();
          break;
        case WorkItemTypes.motions:
          this.setMotionsSponsor()
          break;
        default:
          break;
      }
  }

  private setBillsSponsor(): void {
    const currentSelectedWorkItem = this.billMotionStatementData.filter(
      x =>
        x.billID ===
        this.workItemForm.controls.workItemBillOrMotion.value[0]
          .value
    );
    let sponsorsIds = '';

    if (
      currentSelectedWorkItem[0].billSponsorDepartmentName &&
      this.sectionType === FormTemplate.governmentBusiness
    ) {
      sponsorsIds =
        currentSelectedWorkItem[0].billSponsorDepartmentId;
      this.workItemForm.controls.billSponsors.patchValue(
        sponsorsIds,
        { emitEvent: false }
      );
      this.sponsor =
        currentSelectedWorkItem[0].billSponsorDepartmentName;
    }

    if (
      this.sectionType !== FormTemplate.privateMembersBusiness &&
      this.sectionType !== FormTemplate.privateMembersBill
    ) {
      this.setBillsStage();
      return;
    }

    if (
      !currentSelectedWorkItem[0] ||
      !currentSelectedWorkItem[0].sponsors ||
      !currentSelectedWorkItem[0].sponsors.length
    ) {
      this.setBillsStage();
      return;
    }

    const sponsorIDsDivider = sponsorsIds !== '' ? ', ' : '';
    const sponsorsDivider = this.sponsor !== '' ? ', ' : '';

    currentSelectedWorkItem[0].sponsors.forEach(element => {
      // Render sponsor IDs and patch into form
      sponsorsIds =
        sponsorsIds + sponsorIDsDivider +
        (element.billSponsorDepartmentID || element.sponsorChamberMemberID);
      this.workItemForm.controls.billSponsors.patchValue(
        sponsorsIds,
        { emitEvent: false }
      );

      // Render sponsor names/title
      this.sponsor =
        this.sponsor + sponsorsDivider +
        (element.billSponsorDepartmentName
          || `${element.sponsorChamberMemberFirstName} ${element.sponsorChamberMemberLastName}`);
    });

    this.setBillsStage();
  }

  private setBillsStage(): void {
    if (
      isEmpty(
        this.workItemForm.controls.workItemBillOrMotion.value
      ) ||
      isEmpty(this.stagesData)
    ) {
      return;
    }

    const billIndexEng = findIndex(this.billMotionStatementData, [
      'billTitleENG',
      this.workItemForm.controls.workItemBillOrMotion.value[0]
        .title
    ]);
    const billIndexGle = findIndex(this.billMotionStatementData, [
      'billTitleGLE',
      this.workItemForm.controls.workItemBillOrMotion.value[0]
        .title
    ]);

    if (billIndexEng > -1 || billIndexGle > -1) {
      const index =
        billIndexEng > -1 ? billIndexEng : billIndexGle;
      const billStage = this.stagesData.filter(
        item =>
          item.value ===
          this.billMotionStatementData[index].billStageID
      );
      this.currentStage =
        this.billMotionStatementData[index].billStageName;
      this.billStageId = billStage[0].value;
    }
  }

  private setMotionsSponsor(): void {
    const currentSelectedWorkItem = this.billMotionStatementData.filter(
      x =>
        x.motionID ===
        this.workItemForm.controls.workItemBillOrMotion.value[0]
          .value
    );
    if (
      currentSelectedWorkItem[0] &&
      currentSelectedWorkItem[0].sponsors &&
      currentSelectedWorkItem[0].sponsors.length > 0
    ) {
      this.workItemForm.controls.motionSponsors.patchValue(
        currentSelectedWorkItem[0].sponsors[0].motionSponsorId !==
          null &&
          this.workItemForm.controls.workItemType.value ===
            GLOBALS.workItemOtherValue
          ? JSON.stringify(currentSelectedWorkItem[0].sponsors[0])
          : '',
        { emitEvent: false }
      );
    }
  }
}
