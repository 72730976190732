import {
  Component,
  Input,
  ViewChild,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectValue } from 'proceduralsystem-clientcomponents';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

import { ReportService } from 'src/app/services/report.service';
import { ReportStoreService } from 'src/app/services/report-store.service';
import { CustomModalComponent } from 'src/app/shared/components/custom-modal/custom-modal.component';
import { AvailableSectionDate, BusinessDaySectionHeader } from 'src/app/shared/models/report.model';
import { GLOBALS } from 'src/app/shared/globals';

@Component({
  selector: 'oir-move-section-modal',
  templateUrl: './move-section-modal.component.html',
  styleUrls: ['./move-section-modal.component.less']
})

export class MoveSectionModalComponent implements OnInit {
  @ViewChild('moveSectionModal') moveSectionModal: CustomModalComponent;
  @Input() sectionHeader: BusinessDaySectionHeader;

  private _bsDayDate: string;

  @Input() set bsDayDate(bsDayDate: string) {
    this._bsDayDate = bsDayDate;
    this.formattedBsDayDate = moment(bsDayDate, `${GLOBALS.dateFormat} hh:mm:ss`).format(GLOBALS.dateFormat);
  }

  get bsDayDate(): string {
    return this._bsDayDate;
  }

  @Output() public sectionCopyCurrentBcr = new EventEmitter<string>();

  public title: string;
  public description: string;
  public toDuplicate = true;
  public form: FormGroup;
  public datesSelectValues: SelectValue<AvailableSectionDate>[];
  private availableSectionDates: AvailableSectionDate[];
  private reportId: number;
  private formattedBsDayDate: string;
  private readonly today = moment.utc().set({ hour: 0, minute: 0, second: 0 });

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private reportService: ReportService,
    private reportStoreService: ReportStoreService,
    private activatedRoute: ActivatedRoute
  ) {
    this.reportId = +this.activatedRoute.snapshot.paramMap.get('documentId');
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      selectedDate: [[], Validators.required]
    });
  }

  public toggle(): void {
    if (!this.availableSectionDates) {
      this.fetchDates();
    }
    this.form.reset();

    const actionLabel = this.translateService.instant(`MOVE_SECTION_MODAL.${this.toDuplicate ? 'DUPLICATE' : 'MOVE'}`)
    this.title = this.translateService.instant('MOVE_SECTION_MODAL.TITLE', [actionLabel]);
    this.description = this.translateService.instant('MOVE_SECTION_MODAL.DESCRIPTION', [actionLabel]);

    this.moveSectionModal.toggle();
  }

  public proceedSelectedDate(): void {
    this.copySectionData();
    if (!this.toDuplicate) {
      this.removeSectionData();
    }
  }

  private fetchDates(): void {
    const currentDate = moment.utc(this.formattedBsDayDate, GLOBALS.dateFormat);

    this.reportService.getAvailableSectionDates()
      .pipe(take(1))
      .subscribe(sectionDates => {
        this.availableSectionDates = sectionDates
          .filter(sectionDate => this.filterValidSectionDates(sectionDate, currentDate));
        this.datesSelectValues = this.availableSectionDates
          .map((val, index) => ({ title: `${val.bsDayDateString} - ${val.bcrReportTitle}`, value: index }) as any);
      });
  }

  private copySectionData(): void {
    const { selectedDate } = this.form.getRawValue();
    const dateDetails = this.availableSectionDates[selectedDate];
    const isCurrentReport = dateDetails.bcrReportId === this.reportId;

    if (isCurrentReport) {
      // Note: could be current report, but different day (filtered out @fetchDates()).
      const bsDayDate = moment.utc(dateDetails.bsDayDateString, GLOBALS.dateFormat)
        .set({ hour: 0, minute: 0, second: 0 })
        .format(`${GLOBALS.dateFormat} HH:mm:ss`);
      this.sectionCopyCurrentBcr.emit(bsDayDate);
      this.reportStoreService.setNewSectionHeader(bsDayDate, this.sectionHeader, true);
      this.toggle();
    } else {
      this.reportService.duplicateBusinessSection(
        +this.sectionHeader.bdSectionId,
        dateDetails.bcrReportId,
        dateDetails.bsDayId
      ).pipe(take(1)).subscribe(_ => this.toggle());
    }
  }

  private removeSectionData(): void {
    this.reportStoreService.disableHeaderForDefaultSection(this.bsDayDate, this.sectionHeader.bdSectionId);
  }

  private filterValidSectionDates(val: AvailableSectionDate, currentDate: moment.Moment): boolean {
    const sectionDate = moment.utc(val.bsDayDateString, GLOBALS.dateFormat);

    if (sectionDate.isSame(currentDate) && val.bcrReportId === this.reportId) {
      return false;
    }

    return sectionDate.isAfter(this.today);
  }
}
