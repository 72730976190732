import { Pipe, PipeTransform } from "@angular/core";
import { Amendments } from "../shared/report.enum";

@Pipe({
  name: "amendmentByValue",
})
export class AmendmentByValuePipe implements PipeTransform {
  private readonly formattedNames = {
    [Amendments.Amendment]: "Amendment",
    [Amendments.AmendmentToAmendment]: "Amendment to an amendment"
  };

  transform(value: number): string {
    if (!value) {
        return `${value}`;
    }
    const formattedName = this.formattedNames[value];
    return formattedName ? formattedName : `${value}`;
  }
}