import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DashboardService } from './dashboard-services/dashboard-service.service';
import { QueryTypes } from '../shared/models/queryType.constant';
import { take, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { DashboardReportTypes, TabIndexes, ReportSubTabs } from '../shared/report.enum';
import { CurrentDraftReportComponent } from './current-draft-report/current-draft-report.component';
import { SharedService } from '../services/shared.service';
import { ReportService } from '../services/report.service';
import { isEmpty } from 'lodash-es';
import { OutstandingItemsComponent } from './outstanding-items/outstanding-items.component';
import { BcrMotionHubService } from '../services/bcr-motion-hub.service';

@Component({
  selector: 'oir-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('previousReportsTab') previousReportsTab: CurrentDraftReportComponent;
  @ViewChild('outstandingItemsTab') outstandingItemsTab: OutstandingItemsComponent;

  public queryTypes = QueryTypes;
  public tabIndex = TabIndexes;
  public activeIndex = 1;
  public activeSubTab = 1;
  public reportSubTabs = ReportSubTabs;
  public totalPages = {};
  public reports = {};
  public outstandingItems = {};
  public outstandingCount = {};
  public isOutstanding = false;
  public selectedTab = false;
  public onTabChange = true;
  public dashboardReportTypes = DashboardReportTypes;

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private outstandingItemsHubSubscription: Subscription;

  constructor(
    private dashboardService: DashboardService,
    private sharedService: SharedService,
    private reportService: ReportService,
    private bcrMotionHubService: BcrMotionHubService
  ) { }

  public ngOnInit(): void {
    this.bcrMotionHubService.createConnection();
    this.listenToHubAndUpdateList();
    // On report delete update
    this.dashboardService.report.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.getReports(0, res);
    });
    // Subscription to open draft tab on Save As Draft
    this.reportService.savedAsDraft.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (!isEmpty(res)) {
        this.selectedTab = res.isDraftSaved;
        const event = {
          index: res.index - 1
        };
        this.tabChange(event);
      }
    });

    this.dashboardService.isOutstanding.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res) {
        this.isOutstanding = res;
        this.onTabChange = false;
        this.getOutstandingItems(0, TabIndexes.Current);
      } else {
        this.isOutstanding = false;
        this.onTabChange = true;
      }
    });
  }

  ngAfterViewInit(): void {
    this.getReports(0, TabIndexes.Current);
  }

  async ngOnDestroy(): Promise<void> {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    await this.bcrMotionHubService.stopConnection();
    if (this.outstandingItemsHubSubscription) {
      this.outstandingItemsHubSubscription.unsubscribe();
      this.outstandingItemsHubSubscription = null;
    }
  }

  /**
   * Function to navigate to outstanding items tab
   * @param event Event
   */
  public handleTabClose(event: any): void {
    if (event.index === 0) {
      this.isOutstanding = false;
      this.onTabChange = true;
    } else {
      this.isOutstanding = true;
      this.onTabChange = false;
    }
  }

  /**
   * Changes the current tab
   * @param event Captured DOM event
   */
  public tabChange(event): void {
    // Small delay added due to some response issue with tabs.
    this.activeIndex = event.index + 1;
    if ((event.index + 1) !== TabIndexes.Previous) {
      // Add one because tab inexdes start at 0, query types start at 1.
      this.onTabChange = true;
      this.isOutstanding = false;
      this.getReports(0, event.index + 1);
    } else if ((event.index + 1) === TabIndexes.Previous) {
      const previousReportsTabElements = setInterval(() => {
        if (this.previousReportsTab) {
          this.previousReportsTab.onPageChange(1, event.index + 1);
          clearInterval(previousReportsTabElements);
        }
      }, 100);
    }
  }

  /**
   * Get reports on page change
   * @param res Data
   */
  public onPageChange(res): void {
    if (res.queryType !== QueryTypes.Previous) {
      this.getReports(res.pageNumber, res.queryType);
    } else if (this.previousReportsTab) {
      this.onPreviousReportFormChange(this.previousReportsTab.previousReportForm.value, res.pageNumber);
    }
  }

  /**
   * Get outstanding items on page change
   * @param res Data
   */
  public onOutstandingItemPageChange(res): void {
    this.getOutstandingItems(res.pageNumber, res.queryType);
  }

  /**
   * Function to Populate tab with data
   * @param event event
   * @param queryType query type
   */
  public onSubTabChange(event, queryType: number) {
    this.activeSubTab = event.index + 1;
    if (event.index + 1 === ReportSubTabs.Reports) {
      this.getReports(0, queryType);
    } else {
      this.getOutstandingItems(0, queryType);
    }
  }

  /**
   * Method to handle previous report form change emit
   * @param val value
   */
  public onPreviousReportFormChange(val: any, page = 0): void {
    const data = {
      reportType: val.reportType,
      dateFrom: this.sharedService.convertDate(val.dateRange ? val.dateRange.split(' - ')[0] : null) || '',
      dateTill: this.sharedService.convertDate(val.dateRange ? val.dateRange.split(' - ')[1] : null) || ''
    };
    this.getPreviousReports(data, page);
  }

  /**
   * Methode to listen for hub and reload list on update
   */
  private listenToHubAndUpdateList(): void {
    this.outstandingItemsHubSubscription =
      this.bcrMotionHubService.dashboardDetailsUpdate.asObservable()
        .subscribe(() => {
          if (this.outstandingItemsTab) {
            this.outstandingItemsTab.onPageChange(1, this.activeIndex);
          }
        });
  }

  /**
   * Function to get reports
   * @param pageNumber Page number
   * @param queryType Query type
   */
  private getReports(pageNumber: number, queryType: number): void {
    this.dashboardService.getReport(pageNumber, queryType).pipe(take(1)).subscribe(response => {
      this.totalPages[queryType] = response.totalCount;
      this.reports[queryType] = response.reports;
      const data = {
        totalPages: this.totalPages,
        reports: this.reports
      };
      this.dashboardService.reportsDataArr(data);
    });
  }

  /**
   * Function to get outstanding items
   * @param pageNumber Page number
   * @param queryType Query type
   */
  private getOutstandingItems(pageNumber: number, queryType: number): void {
    this.dashboardService.getOutstandingItems(pageNumber, queryType).pipe(take(1)).subscribe(res => {
      this.outstandingCount[queryType] = res.count;
      this.outstandingItems[queryType] = res.outstandingItems;
      const outstandingItemsData = {
        outstandingItems: this.outstandingItems,
        outstandingCount: this.outstandingCount,
        queryType
      };
      this.dashboardService.outstandingItemsData(outstandingItemsData);
    });
  }

  /**
   * Method to get previous reports
   * @param data
   */
  private getPreviousReports(data: any, page: number): void {
    this.dashboardService.getPreviousReports(page, data.reportType, data.dateFrom, data.dateTill)
      .pipe(take(1))
      .subscribe(res => {
        this.totalPages[QueryTypes.Previous] = res.totalCount;
        this.reports[QueryTypes.Previous] = res.reports;
        this.dashboardService.reportsDataArr({
          totalPages: this.totalPages,
          reports: this.reports
        });
      });
  }
}
