import {
  Component,
  Input,
  ViewChild,
  OnInit,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastNotificationsService, TypeaheadValue } from 'proceduralsystem-clientcomponents';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from 'src/app/services/report.service';
import { OrderOfBusiness } from 'src/app/shared/models/order-of-business.model';
import { SharedService } from 'src/app/services/shared.service';
import { CancelConfirmModalComponent } from 'src/app/create-report/cancel-confirm-modal/cancel-confirm-modal.component';
import { CustomModalComponent } from 'src/app/shared/components/custom-modal/custom-modal.component';

@Component({
  selector: 'oir-action-bar-email-modal-ob',
  templateUrl: './action-bar-email-modal-ob.component.html',
  styleUrls: ['./action-bar-email-modal-ob.component.less']
})
export class ActionBarEmailModalObComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() startObTime: string;
  @Input() endObTime: string;
  @Input() orderOfBusiness: OrderOfBusiness;
  @Input() recipientsEmails: TypeaheadValue<any>[];
  @Input() versionOb: string;
  @Input() isSingleDay: boolean;
  @Input() reportName: boolean;

  @ViewChild('actionBarEmailModalOb')
  actionBarEmailModalOb: CustomModalComponent;
  @ViewChild('cancelConfirmModalComponent')
  cancelConfirmModalComponent: CancelConfirmModalComponent;

  public form: FormGroup;
  public emailDefaultValue: string;
  public emailBodyDefaultValue: string;
  public orderOfBusinessInModal: OrderOfBusiness;
  public versionObInModal: string;
  public sendEmailAction = false;

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private defaultEmail = 'journal.office@oireachtas.ie';

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private toastService: ToastNotificationsService,
    private reportService: ReportService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      toEmail: [[], Validators.required],
      ccEmail: [],
      bccEmail: [],
      emailSubject: ['', Validators.maxLength(250)],
      emailBody: ['', Validators.maxLength(1000)]
    });
  }

  ngOnChanges() {
    this.versionObInModal = this.versionOb;
    this.emailDefaultValue = this.translateService.instant(
      'ORDER_OF_BUSINESS.CIRCULATE_EMAIL.SUBJECT.DEFAULT_VALUE',
      [this.reportName, this.versionObInModal]
    );
    this.emailBodyDefaultValue = this.isSingleDay
      ? this.translateService.instant(
          'ORDER_OF_BUSINESS.CIRCULATE_EMAIL.BODY.DEFAULT_SINGLE_VALUE',
          [this.reportName]
        )
      : this.translateService.instant(
          'ORDER_OF_BUSINESS.CIRCULATE_EMAIL.BODY.DEFAULT_VALUE',
          [this.reportName]
        );
    this.orderOfBusinessInModal = this.orderOfBusiness;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Method to send mail
   */
  public sendEmailFile(): void {
    this.sendEmailAction = true;
    const emailHeader = this.emailFormData(
      this.form.controls.toEmail.value,
      this.form.controls.ccEmail.value,
      this.form.controls.bccEmail.value,
      this.form.controls.emailSubject.value[0],
      this.form.controls.emailBody.value[0]
    );
    this.reportService
      .actionBarSendEmailOb(this.orderOfBusinessInModal.obId, emailHeader)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.toastService.addNotification({
          title: this.translateService.instant(
            'TOAST.ORDER_OF_BUSINESS_SEND_EMAIL.TITLE'
          ),
          description: this.translateService.instant(
            'TOAST.ORDER_OF_BUSINESS_SEND_EMAIL.BODY',
            [this.reportName, this.versionObInModal]
          )
        });
        this.actionBarEmailModalOb.close();
        this.sendEmailAction = false;
      });
  }

  /**
   * Open the email modal
   */
  public open(): void {
    this.form.patchValue({
      toEmail: [{ value: 1, title: this.defaultEmail }],
      emailSubject: [this.emailDefaultValue],
      emailBody: [this.emailBodyDefaultValue]
    });
    this.actionBarEmailModalOb.toggle();
  }

  /**
   * Function to cancel email modal
   * @param event event
   */
  public cancelEmailModal(event) {
    if (event) {
      this.form.reset();
      this.actionBarEmailModalOb.close();
      const title = this.translateService.instant(
        'TOAST.VIEW_ORDER_OF_BUSINESS_EMAIL.NOT_SEND'
      );
      this.toastService.addNotification({ title, description: '' });
    }
  }

  /**
   * Cancel the email modal
   */
  public cancel(): void {
    this.cancelConfirmModalComponent.toggle();
  }

  /**
   * Clear form on modal close
   */
  public onModalClose(): void {
    if (!this.sendEmailAction) {
      this.cancelConfirmModalComponent.toggle();
    }
  }

  /**
   * Email Form Data Model
   * @param receiver Email Reciver
   * @param ccReceiver Email ccReceiver
   * @param bccReceiver Email bccReceiver
   * @param subject Email subject
   * @param body Email body
   */
  private emailFormData(
    receiver: TypeaheadValue<any>[],
    ccReceiver: TypeaheadValue<any>[],
    bccReceiver: TypeaheadValue<any>[],
    subject: string,
    body: string
  ): object {
    return {
      receiver: receiver
        ? this.sharedService.convertRecipientsToString(receiver)
        : null,
      ccReceiver: ccReceiver
        ? this.sharedService.convertRecipientsToString(ccReceiver)
        : null,
      bccReceiver: bccReceiver
        ? this.sharedService.convertRecipientsToString(bccReceiver)
        : null,
      subject,
      body
    };
  }
}
