import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { WorkItem } from '../../models/report.model';
import { DeleteModalComponent } from '../../modals/delete-modal/delete-modal.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'oir-work-item-instance',
  templateUrl: './work-item-instance.component.html',
  styleUrls: ['./work-item-instance.component.less']
})
export class WorkItemInstanceComponent implements OnInit {

  @Input() index: number;
  @Input() workItem: WorkItem;
  @Input() sectionData;
  @Input() sectionType;
  @Input() disableWorkItem: boolean;

  @Output() saveWorkItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() editWorkItem: EventEmitter<any> = new EventEmitter<boolean>();
  @Output() removeWorkItem: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('commonDeleteModal') commonDeleteModal: DeleteModalComponent;

  public editMode = false;
  public workItemId: number;

  constructor(
    private translateService: TranslateService
  ) { }

  public ngOnInit(): void {
    this.workItemId = this.workItem.workItemId;
  }

  /**
   * Method to get work item title
   */
  public getWorkItemTitle(): string {
    if (this.isWorkItemOutstanding()) {
      return this.workItem.businessDaySectionWorkItemPlaceholder;
    }

    return this.workItem.workItemTitles
      ? this.workItem.workItemTitles[0]
      : this.workItem[`workItemTitle${this.workItemTitleLangTag}`];
  }

  /**
   * Method to check if work item is outstanding
   */
  public isWorkItemOutstanding(): boolean {
    return this.workItem.isWorkItemOutstanding;
  }

  /**
   * Method to handle edit click
   */
  public onEdit(): void {
    this.editMode = true;
    this.editWorkItem.emit(this.editMode);
  }

  /**
   * Method to handle work item update emit
   * @param data
   */
  public onUpdateWorkItem(data: any): void {
    this.saveWorkItem.emit({
      formValue: data,
      index: this.index
    });
    this.editWorkItem.emit(false);
  }

  /**
   * Method to handle work item remove click
   */
  public onRemove(): void {
    this.commonDeleteModal.toggle();
  }

  /**
   * Function to confirm delete
   * @param event event
   */
  public deleteModalDecision(event: boolean): void {
    if (event) {
      this.removeWorkItem.emit(this.index);
    }
  }

  /**
   * Method to handle accordion closing
   */
  public onClose(): void {
    this.editMode = false;
    this.editWorkItem.emit(this.editMode);
  }

  private get workItemTitleLangTag(): string {
    return this.translateService.currentLang === 'en' ? 'Eng' : 'Gle';
  }

}
