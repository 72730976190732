import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "SpanStyle",
  pure: false,
})
export class SpanStylePipe implements PipeTransform {
  transform(value: string): string {
    return value && typeof value === "string"
      ? value.replace(/background:white|\n|\t/g, "")
      : value;
  }
}
