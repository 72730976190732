import {
  Component,
  OnInit,
  forwardRef,
  QueryList,
  ViewChildren,
  ElementRef,
  ViewChild,
  Input,
  EventEmitter,
  Output,
  Renderer2,
  SimpleChanges,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import {
  SelectValue,
  MultiselectOptions,
  SelectModel,
  ArrayHelper,
} from "proceduralsystem-clientcomponents";
import { Stages } from "../../report.enum";

@Component({
  selector: "oir-custom-multiselect",
  templateUrl: "./custom-multiselect.component.html",
  styleUrls: ["./custom-multiselect.component.less"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomMultiselectComponent),
      multi: true,
    },
  ],
})
export class CustomMultiselectComponent implements OnInit {
  @ViewChildren("inputs") inputs: QueryList<ElementRef>;
  @ViewChildren("searchInput") searchInputs: QueryList<ElementRef>;

  @ViewChild(NgbDropdown) dropdown: NgbDropdown;

  @Input() placeholder: string;
  @Input() data: SelectValue<any>[] = [];
  @Input() options: MultiselectOptions;
  @Input() disabled: boolean;
  @Input() id = "";
  @Input() defaultStage: number;

  @Input() model = [];
  @Output() modelChange: EventEmitter<any> = new EventEmitter();

  dataObj: SelectValue<any>[] = [];
  query: string;
  title = "";
  multiselectModel: SelectModel = {
    headings: [],
    active: false,
    animating: false,
  };

  private init = false;
  private clickEventListener: Function;
  private keyDownEventLisener: Function;
  private stagesToCheck: any;

  propagateChange = (_: any) => {};
  propagateTouch = (_?: any) => {};

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  get activePills(): any {
    return this.dataObj.filter((x) => x.selected);
  }

  ngOnInit(): void {
    this.stagesToCheck = [
      Stages.FirstStage,
      Stages.SecondStage,
      Stages.OrderForSecondStage,
      Stages.CommitteeStage,
      Stages.FifthStage,
      Stages.OrderForCommitteeStage,
      Stages.OrderForFifthStage,
      Stages.OrderForReport,
      Stages.ReportStage,
    ];

    this.multiselectModel = {
      headings: [],
      active: false,
      animating: false,
    };
    this.dataObj = JSON.parse(JSON.stringify(this.data || []));

    this.initOptions();

    if (!this.placeholder) {
      this.placeholder = "Select an option";
    }
    if (!this.dataObj) {
      this.dataObj = [];
    }
    if (this.dataObj.length > 0) {
      this.refreshModel();
    }

    this.init = true;
  }

  ngAfterViewInit(): void {
    this.dropdown.placement = "bottom";
    this.dropdown.autoClose = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.init) {
      if (changes.data && this.data) {
        this.dataObj = JSON.parse(JSON.stringify(this.data));
      }

      this.refreshModel();
    }
  }

  writeValue(obj: any): void {
    obj = obj || [];
    if (this.init) {
      this.model = obj.hasOwnProperty("value") ? obj.value : obj;

      this.refreshModel();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState(value: any): void {
    this.disabled = value;
  }

  displayTitle(): boolean {
    return (
      !this.options.pills ||
      (this.options.pills && this.model && this.model.length === 0)
    );
  }

  selectKeyDown(event): void {
    if (event.which === 13 || event.which === 32) {
      this.dropdown.toggle();
      if (this.options.search && this.dropdown.isOpen()) {
        if (!this.options.headings) {
          this.setFocusOnSearchBox(0);
        } else {
          this.setFocusOnSearchBox(1);
        }
      }
    } else if (event.which === 46) {
      this.clearSelected();
    }
  }

  setFocusOnSearchBox(num): void {
    setTimeout(() => {
      this.searchInputs.toArray()[num].nativeElement.focus();
    }, 100);
  }

  clearSearchInput(): void {
    this.searchInputs.toArray().forEach((el) => {
      el.nativeElement.value = "";
    });
    if (this.query !== "") {
      this.query = "";
      this.searchValue();
    }
  }

  dropdownToggled(): void {
    if (!this.dropdown.isOpen()) {
      // Clears search option.
      this.clearSearchInput();
      // Removes listener if dropdown closed.
      this.clickEventListener();
      this.keyDownEventLisener();
    } else {
      // On dropdown open add event listener.
      this.registerListener();
    }
  }

  toggle(): void {
    if (!this.disabled && !this.multiselectModel.animating) {
      this.query = "";
      this.searchValue();

      this.multiselectModel.active = !this.multiselectModel.active;
      this.multiselectModel.animating = true;

      setTimeout(() => (this.multiselectModel.animating = false), 280);
    }
  }

  onDropDownOpen(event): void {
    const button: NodeListOf<HTMLElement> = event.target
      .closest(".dropdown")
      .querySelectorAll(".dropdown-item");

    if (this.dataObj[Stages.AllStages - 1].selected) {
      this.dataObj.forEach((item) => {
        if (item.value !== Stages.AllStages) {
          button[item.value - 1].setAttribute("disabled", "true");
        }
      });
    }

    if (this.dataObj[Stages.AmendmentsFromTheSeanad - 1].selected) {
      this.dataObj.forEach((item) => {
        if (item.value !== Stages.AmendmentsFromTheSeanad) {
          button[item.value - 1].setAttribute("disabled", "true");
        }
      });
    }

    for (const item of this.stagesToCheck) {
      if (this.dataObj[item - 1].selected) {
        button[Stages.AllStages - 1].setAttribute("disabled", "true");
        button[Stages.AmendmentsFromTheSeanad - 1].setAttribute(
          "disabled",
          "true"
        );
        break;
      }
    }
  }

  stageSelect(option: SelectValue<any>, action: string, event?: any): void {
    setTimeout(() => this.handleStageOptionSelect(option, action, event), 100);
  }

  select(option: SelectValue<any>, event: any): void {
    this.stageSelect(option, "select", event);
    if (!option.disabled) {
      if (option.selected) {
        option.selected = false;
        this.model = this.model.filter((x) => x !== option.value) || [];
      } else {
        this.dataObj.filter((x) => x.value === option.value)[0].selected = true;
        this.model.push(option.value);
        // Angular change detection doesn't check the contents of arrays or object.
        this.model = this.model.slice();
      }

      this.refreshTitle();
      this.modelChange.emit(this.model);
      this.propagateChange(this.model);
    }
  }

  selectAllValues(): void {
    for (const dataItem of this.dataObj) {
      if (!dataItem.hidden && !dataItem.disabled) {
        dataItem.selected = true;
      }
    }

    this.model = this.dataObj.filter((x) => x.selected).map((x) => x.value);

    this.refreshTitle();

    this.propagateChange(this.model);
    this.modelChange.emit(this.model);
  }

  clearSelected(): void {
    for (const dataItem of this.dataObj) {
      if (!dataItem.hidden) {
        dataItem.selected = false;
      }
    }

    this.model = [];

    this.refreshTitle();

    this.propagateChange(this.model);
    this.modelChange.emit(this.model);
  }

  close(): void {
    if (this.multiselectModel.active) {
      this.multiselectModel.active = false;
      this.multiselectModel.animating = true;

      setTimeout(() => (this.multiselectModel.animating = false), 280);

      this.propagateTouch();
    }
  }

  removePill($event: any, option: SelectValue<any>): void {
    const isButtonPress = $event.screenX === 0 && $event.screenY === 0;
    this.stageSelect(option, "remove", $event);

    if (this.disabled) {
      return;
    }

    option.animate = true;

    setTimeout(() => {
      option.selected = false;
      option.animate = false;

      this.model = this.model.filter(
        (x) => JSON.stringify(x) !== JSON.stringify(option.value)
      );
      const itemRemoved = this.dataObj.filter(
        (x) => x.value === option.value
      )[0];
      itemRemoved.selected = false;

      this.refreshTitle();

      this.modelChange.emit(this.model);
      this.propagateChange(this.model);

      let selectedIndex = 0;

      if (!isButtonPress) {
        return;
      }
      this.inputs.forEach((child, index) => {
        if (child.nativeElement.textContent === itemRemoved.title) {
          selectedIndex = index;
        }
      });

      if (this.inputs.length > 1) {
        if (selectedIndex - 1 < 0) {
          selectedIndex = 2;
        }
        this.inputs.toArray()[selectedIndex - 1].nativeElement.focus();
      }
    }, 200);

    $event.stopPropagation();
  }

  onSearch(): void {
    setTimeout(() => this.searchValue());
  }

  private refreshModel(): void {
    let values = [];

    if (!this.model) {
      return this.refreshTitle();
    }

    this.clearUnselected(this.model);

    for (const modelItem of this.model) {
      if (typeof modelItem === "object") {
        if (this.options.modelPropertyName) {
          values = this.dataObj.filter(
            (x) =>
              x.value[this.options.modelPropertyName] ===
              modelItem[this.options.modelPropertyName]
          );
        } else if (modelItem) {
          values = this.dataObj.filter(
            (x) => JSON.stringify(x.value) === JSON.stringify(modelItem)
          );
          console.log("Model Property Name was not provided for Multiselect");
        }
      } else {
        values = this.dataObj.filter((x) => x.value === modelItem) || [];
      }

      if (values.length > 0) {
        values[0].selected = true;
      }
    }

    this.refreshTitle();
  }

  private initOptions(): void {
    this.options = this.options || {
      pills: false,
      search: false,
      headings: false,
      selectAll: true,
      modelPropertyName: "",
    };

    if (this.options.headings) {
      this.groupValuesByHeading();
    }
  }

  private clearUnselected(items: Array<any>): void {
    if (this.dataObj.length) {
      this.dataObj
        .filter((x) => items.indexOf(x.value) < 0)
        .forEach((x) => (x.selected = false));
    }
  }

  private groupValuesByHeading(): void {
    const headingsGroup = ArrayHelper.groupBy<SelectValue<any>>(
      this.dataObj,
      "heading"
    );
    for (const headingGroup of headingsGroup) {
      this.multiselectModel.headings.push({ title: headingGroup.key });
    }
  }

  private searchValue(): void {
    if (!this.options.search) {
      return;
    }

    if (this.query === "" || typeof this.query === "undefined") {
      for (const dataItem of this.dataObj) {
        dataItem.hidden = false;
      }
    } else {
      const results = this.dataObj.filter(
        (x) => x.title.toLowerCase().indexOf(this.query.toLowerCase()) >= 0
      );
      if (results) {
        for (const dataItem of this.dataObj) {
          dataItem.hidden = results.indexOf(dataItem) === -1;
        }
      }
    }

    if (this.options.headings) {
      this.checkHeadingsVisibility();
    }
  }

  private checkHeadingsVisibility(): void {
    const headingsGroup = ArrayHelper.groupBy<SelectValue<any>>(
      this.dataObj,
      "heading"
    );

    for (const headingGroup of headingsGroup) {
      const hidden =
        headingGroup.values.filter((x) => x.hidden).length ===
        headingGroup.values.length;
      this.multiselectModel.headings.filter(
        (x) => x.title === headingGroup.key
      )[0].hidden = hidden;
    }
  }

  private refreshTitle(): void {
    this.title = this.placeholder;

    if (!this.options.pills && this.model) {
      if (this.model.length === 1) {
        this.title = this.dataObj.filter(
          (x) => x.value === this.model[0]
        )[0].title;
      } else if (this.model.length > 1) {
        this.title = "Multiple options selected";
      }
    }
  }

  private registerListener(): void {
    // If clicked outside of the component close dropdown if open.
    this.clickEventListener = this.renderer.listen(
      "document",
      "click",
      (event: any) => {
        if (!this.el.nativeElement.contains(event.target)) {
          if (this.dropdown.isOpen) {
            this.dropdown.toggle();
          }
          this.clickEventListener();
        }
      }
    );
    // If keypressed outside of the component close dropdown if open.
    this.keyDownEventLisener = this.renderer.listen(
      "document",
      "keyup",
      (event: any) => {
        if (!this.el.nativeElement.contains(event.target)) {
          if (this.dropdown.isOpen) {
            this.dropdown.toggle();
          }
          this.keyDownEventLisener();
        }
      }
    );
  }

  private handleStageOptionSelect(
    option: SelectValue<any>,
    action: string,
    event?: any
  ): void {
    let button: NodeListOf<HTMLElement>;

    if (action === "remove") {
      button = event.target
        .closest("#dropdownMultiSelect")
        .closest(".dropdown")
        .querySelectorAll(".dropdown-item");
    } else {
      button = event.target
        .closest(".dropdown-menu")
        .querySelectorAll(".dropdown-item");
    }

    if (
      [Stages.AllStages, Stages.AmendmentsFromTheSeanad].includes(option.value)
    ) {
      return this.disableMainStages(option, action, button);
    }
    return this.disableOtherStages(action, button);
  }

  private disableMainStages(
    option: SelectValue<any>,
    action: string,
    button: NodeListOf<HTMLElement>
  ): void {
    // Disable stages from 1-9.
    this.dataObj.forEach((item) => {
      if (option.selected && action !== "remove") {
        if (item.value !== option.value) {
          button[item.value - 1].setAttribute("disabled", "true");
        }
      } else {
        button[item.value - 1].removeAttribute("disabled");
      }
    });
  }

  private disableOtherStages(
    action: string,
    button: NodeListOf<HTMLElement>
  ): void {
    // Disable All stages and Amendments From The Seanad options if stage 1-9 is selected
    for (const item of this.stagesToCheck) {
      if (
        this.dataObj[item - 1].selected &&
        !this.dataObj[item - 1].disabled &&
        action !== "remove"
      ) {
        button[Stages.AllStages - 1].setAttribute("disabled", "true");
        button[Stages.AmendmentsFromTheSeanad - 1].setAttribute(
          "disabled",
          "true"
        );
        break;
      }
    }

    // Need timeout to detect changes
    setTimeout(() => {
      for (const items of this.dataObj) {
        if (items.selected && !items.disabled) {
          button[Stages.AllStages - 1].setAttribute("disabled", "true");
          button[Stages.AmendmentsFromTheSeanad - 1].setAttribute(
            "disabled",
            "true"
          );
          break;
        }
        button[Stages.AllStages - 1].removeAttribute("disabled");
        button[Stages.AmendmentsFromTheSeanad - 1].removeAttribute("disabled");
      }
    }, 100);
  }
}
