import {Injectable} from '@angular/core';

import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import * as Moment from 'moment';

const moment: any = (Moment as any).default || Moment;

export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

@Injectable()
export class MyFormatter extends NgbDateParserFormatter {

  parse(value: string): NgbDateStruct {
    let dateInput = value.trim().replace('-', '/');
    // Formats allowed.
    const formats = ['D/MM/YYYY', 'DD/MM/YYYY', 'D/M/YYYY', 'DD/M/YYYY'];
    dateInput = moment(dateInput, formats).format('DD/MM/YYYY');
    if ( dateInput !== 'Invalid date' ) {
      // Find by 4 numbers togther
      const year = dateInput.match(/\d{4}/g).map(Number);
      dateInput = dateInput.replace(String(year), '');
      // Find date and month.
      const dayAndMonth = dateInput.match(/\d{2}/g).map(Number);
      // Handles 1 digit input for year.
      if (String(year[0]).length === 1) {
        year[0] = Number(String(moment().year()).substring(0, 2) + '0' + String(year[0]));
      }
      return { day: toInteger(dayAndMonth[0]), month: toInteger(dayAndMonth[1]), year: toInteger(year) };
    }
    return null;
  }
  // Set the date format to be displayed.
  format(date: NgbDateStruct): string {
    return date
      ? `${this.getPadNumber(date.day)}/${this.getPadNumber(date.month)}/${date.year}`
      : '';
  }

  private getPadNumber(value: number): string {
    return isNumber(value) ? padNumber(value) : ''
  }

}
