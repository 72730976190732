import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'proceduralsystem-clientcomponents';
import { ActionInProgressService } from 'src/app/services/action-in-progress.service';
import { AppConfigService } from 'src/app/services/app-config.service';

@Injectable({ providedIn: 'root' })
export class UserAuthInterceptor implements HttpInterceptor {

  constructor(
    private configurationService: ConfigurationService<any>,
    private actionInProgressService: ActionInProgressService,
    private readonly config: AppConfigService,
  ) { }

  /**
   * Interceptor intercepts every outgoing/incoming http request
   * @param req
   * @param next
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userAuth: any = {
      userGuid: this.config.getValue('UserId'),
      userEmail: this.configurationService.getValue('UserName'),
    };
    let headers = req.headers;
    headers.append('userAuth', JSON.stringify(userAuth))
    headers.append('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', '0')
    const userInfoReq: any = req.clone({
      headers: headers
    });

    this.actionInProgressService.actionStarted();

    return next.handle(userInfoReq);
  }
}

