import { Injectable } from '@angular/core';
import {
  Report,
  BusinessDayArrangement,
  BusinessScheduleDay,
  BusinessDayCommonSectionDetail,
  BusinessDayLeadersQuestionsSectionDetail,
  BusinessDaySectionsPQOralDetail,
  BusinessDayMotionsWithoutDebateSectionDetail,
  BusinessDaySectionHeader
} from '../shared/models/report.model';
import { SharedService } from './shared.service';
import { SectionTypes, FormTemplate } from '../shared/report.enum';
import { isEmpty, findIndex, isArray, sortBy, findLast, take as takeLodash, maxBy, cloneDeep } from 'lodash-es';
import { take, tap, mergeMap, delay } from 'rxjs/operators';
import { ReportService } from './report.service';
import { Observable, from, Subject } from 'rxjs';
import * as moment from 'moment';
import { GLOBALS } from '../shared/globals';
import { OrderOfBusiness } from '../shared/models/order-of-business.model';
import { ReportUtils } from '../shared/utils/report.utils';

@Injectable({
  providedIn: 'root'
})

// LOGIC TO KEEP IN MIND
// In 'Add new section' accordion we can create new sections and
// headers for said sections
// To be able to manipulate 'new' sections and headers we assigned to // them fake IDs with typeof string (same property as real one)
// Thats why we have functionlaity to change these fake IDs to 0 before // sending data to db
export class ReportStoreService {

  public reportData: Report;
  public reportData$: Observable<Report>;

  public reportObData: OrderOfBusiness;
  public reportObData$: Observable<OrderOfBusiness>;

  private reportDataChanged = new Subject<Report>();
  private reportObDataChanged = new Subject<OrderOfBusiness>();

  constructor(
    private sharedService: SharedService,
    private reportService: ReportService
  ) {
    this.reportData$ = this.reportDataChanged.asObservable();
    this.reportObData$ = this.reportObDataChanged.asObservable();
  }

  /**
   * Function to clear data on destroy
   */
  public clearData(): void {
    this.reportData = null;
    this.reportDataChanged.next(null);
  }

  /**
   * Function to set business day instance time
   * @param componentId component Id
   * @param startTime start time
   * @param endtTime end time
   */
  public setBusinessDayStartAndEndTime(bsDayDate: string, newStartTimeValue: any): void {
    if (!isEmpty(this.reportData)) {
      const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
      if (index > -1) {
        this.reportData.businessScheduleDetail.businessScheduleDays[index].bsDayStartTime =
          this.sharedService.setTimeToDate(this.reportData.businessScheduleDetail.businessScheduleDays[index].bsDayDate, newStartTimeValue);

        this.recalculateReportDaysTime();
        this.emitReportUpdate();
      }
    }
  }

  /**
   * Method to get end time of businessDay by id
   * @param bsDayId business day id
   */
  public getBusinessDayEndTime(bsDayDate: string): string {
    if (isEmpty(this.reportData)) {
      return null;
    }

    const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
    return (index > -1) ?
      this.sharedService.convertTime(this.reportData.businessScheduleDetail.businessScheduleDays[index].bsDayEndTime) :
      null;
  }

  /**
   * Function to get sitting day status
   * @param bsDayId toggle id
   * @param sittingToggleData toggle data
   */
  public setSittingDayStatus(bsDayDate: string, sittingToggleData: any): void {
    if (!isEmpty(this.reportData)) {
      const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
      if (index > -1) {
        this.reportData.businessScheduleDetail.businessScheduleDays[index].isBSDaySitting = sittingToggleData;
        // Clear bsDayNotSittingReason if sittingToggleData is true
        if (sittingToggleData) {
          this.reportData.businessScheduleDetail.businessScheduleDays[index].bsDayNotSittingReason = null;
        }
        this.emitReportUpdate();
      }
    }
  }

  /**
   * Function to get day not sitting reason text
   * @param bsDayId field id
   * @param dayInstanceReasonData field data
   */
  public setDayReasonText(bsDayDate: string, dayInstanceReasonData: any): void {
    if (!isEmpty(this.reportData)) {
      const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
      if (index > -1) {
        // !Important - Only update value - No need to emit report update
        this.reportData.businessScheduleDetail.businessScheduleDays[index].bsDayNotSittingReason = dayInstanceReasonData;
      }
    }
  }

  /**
   * Method to disable default section
   * @param bsDayDate
   * @param bdSectionId
   */
  public disableHeaderForDefaultSection(bsDayDate: string, bdSectionId: number | string): void {
    if (isEmpty(this.reportData)) {
      return this.recalculateReportDaysTime(true);
    }

    const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
    if (index > -1) {

      const headerIndex = findIndex(this.reportData.businessScheduleDetail.businessScheduleDays[index].businessDaySectionHeaders, ['bdSectionId', bdSectionId]);
      if (headerIndex > -1) {
        this.reportData.businessScheduleDetail.businessScheduleDays[index].businessDaySectionHeaders[headerIndex].businessDaySectionPositionNo = null;
        // Recalculate position in section data
        const header = this.reportData.businessScheduleDetail.businessScheduleDays[index].businessDaySectionHeaders[headerIndex];
        header.wasSectionRemoved = true;

        if (!isEmpty(this.reportData.businessScheduleDetail.businessScheduleDays[index][SectionTypes[header.businessDaySectionType.bdSectionTypeId]])) {
          this.reportData.businessScheduleDetail.businessScheduleDays[index][SectionTypes[header.businessDaySectionType.bdSectionTypeId]].forEach(sectionData => {
            if (sectionData.businessDaySectionId === header.bdSectionId) {
              sectionData.businessDaySectionPositionNo = header.businessDaySectionPositionNo;
            }
          });
        }
      }
    }
    
    this.recalculateReportDaysTime(true);
  }

  /**
   * Method to enable default section
   * @param bsDayDate
   * @param bdSectionId
   */
  public enableHeaderForDefaultSection(bsDayDate: string, bdSectionId: number | string): void {
    if (!isEmpty(this.reportData)) {

      const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
      if (index > -1) {
        const headerIndex = findIndex(this.reportData.businessScheduleDetail.businessScheduleDays[index].businessDaySectionHeaders, ['bdSectionId', bdSectionId]);
        if (headerIndex > -1) {
          const lastPosition = maxBy(this.reportData.businessScheduleDetail.businessScheduleDays[index].businessDaySectionHeaders, 'businessDaySectionPositionNo');
          const header = this.reportData.businessScheduleDetail.businessScheduleDays[index].businessDaySectionHeaders[headerIndex];
          
          header.wasSectionRemoved = false;
          header.businessDaySectionPositionNo = lastPosition ? lastPosition.businessDaySectionPositionNo + 1 : 0;
        }
      }
    }
    this.recalculateReportDaysTime(true);
  }

  /**
   * Method to update section position after drop
   * @param bsDayDate
   * @param businessDaySectionHeaders
   */
  public updateSectionPosition(bsDayDate: string, businessDaySectionHeaders: BusinessDaySectionHeader[]): void {
    if (isEmpty(this.reportData)) {
      return;
    }

    const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
    if (index === -1) {
      return this.recalculateReportDaysTime(true);
    }

    this.reportData.businessScheduleDetail.businessScheduleDays[index].businessDaySectionHeaders =
      businessDaySectionHeaders.map((header, i) => {

        // Because we convert start & end times we must convert them back (if they exist), be careful to pass the correct format
        const bsSectionStartTime = header.bsSectionStartTime ? moment(this.sharedService.convertDate(bsDayDate)).utc().format(GLOBALS.dateFormat) +
          ' ' + header.bsSectionStartTime : null;
        const bsSectionEndTime = header.bsSectionEndTime ? moment(this.sharedService.convertDate(bsDayDate)).utc().format(GLOBALS.dateFormat) +
          ' ' + header.bsSectionEndTime : null;

        return {
          ...header,
          businessDaySectionPositionNo: header.businessDaySectionPositionNo !== null ? i + 1 : null,
          bsSectionStartTime,
          bsSectionEndTime
        };
      });

    // Recalculate position in section data
    this.reportData.businessScheduleDetail.businessScheduleDays[index].businessDaySectionHeaders.forEach(headers => {
      if (!isEmpty(this.reportData.businessScheduleDetail.businessScheduleDays[index][SectionTypes[headers.businessDaySectionType.bdSectionTypeId]])) {
        this.reportData.businessScheduleDetail.businessScheduleDays[index][SectionTypes[headers.businessDaySectionType.bdSectionTypeId]].forEach(sectionData => {
          if (sectionData.businessDaySectionId === headers.bdSectionId) {
            sectionData.businessDaySectionPositionNo = headers.businessDaySectionPositionNo;
          }
        });
      }
    });

    this.recalculateReportDaysTime(true);
  }

  /**
   * Function to get data from API and return as data
   * @param id Section id
   */
  private getSectionDataFromApi(id: number | string, type: number, date: string, position: number): Observable<any> {
    return this.reportService.getSectionData(id, type)
      .pipe(
        take(1),
        tap(res => this.storeSectionData(position, res, type, date, false)),
        // Because of possibility that user can change start time before requesting any section data
        // We need to show data that considers localy updated start time
        // So we simply store section (where it is updated while stored) and then return it from store
        mergeMap(() => this.getSectionsData(id, type, date, position))
      );
  }

  /**
   * Method to get and set day template for non default business day
   * @param bsDayDate
   * @param templateId
   */
  public getAndSetNonDefaultBusinessDayTemplate(bsDayDate: string, templateId: number, businessScheduleDay: BusinessScheduleDay): void {

    this.reportService.getSectionsList(templateId, this.sharedService.convertDate(bsDayDate))
      .pipe(take(1))
      .subscribe(res => {
        this.updateNonDefaultDayWithDayTemplate(bsDayDate, res, templateId, businessScheduleDay);
      });
  }

  /**
   * Function to set default day sections
   * @param businessScheduleDay Business Schedule Day
   */
  public setDefaultDaySection(businessScheduleDay: any) {
    businessScheduleDay.businessDaySectionHeaders.forEach(header => {
      const randomSectionId = this.sharedService.generateId();
      const hasStartEndTime = (!isEmpty(header.bsSectionStartTime) && !isEmpty(header.bsSectionEndTime));
      const duration = hasStartEndTime ? this.calculateDurationTime(header.bsSectionStartTime, header.bsSectionEndTime) : 0;
      header.bdSectionId = randomSectionId;
      header.businessDaySectionTypeId = header.businessDaySectionType.bdSectionTypeId;
      const sectionData = {
        businessScheduleDayId: businessScheduleDay.bsDayId,
        businessDaySectionId: randomSectionId,
        bsSectionStartTime: this.sharedService.convertDate(header.bsSectionStartTime),
        bsSectionEndTime: this.sharedService.convertDate(header.bsSectionEndTime),
        businessDaySectionDuration: duration,
        businessDaySectionTemplateId: header.businessDaySectionType.bdSectionTypeId,
        businessDaySectionTitle: header.bsDaySectionTitle,
        businessDaySectionTypeId: header.businessDaySectionType.bdSectionTypeId,
        businessDaySectionPositionNo: header.businessDaySectionPositionNo,
      };
      const index = this.getBusinessDayArrayElementIndexByDayDate(businessScheduleDay.bsDayDate);
      if (header.businessDaySectionType && header.businessDaySectionType.bdSectionTypeId) {
        if (!isArray(this.reportData.businessScheduleDetail.businessScheduleDays[index]
        [SectionTypes[header.businessDaySectionType.bdSectionTypeId]])) {
          this.reportData.businessScheduleDetail.businessScheduleDays[index]
          [SectionTypes[header.businessDaySectionType.bdSectionTypeId]] = [sectionData];
        } else {
          this.reportData.businessScheduleDetail.businessScheduleDays[index]
          [SectionTypes[header.businessDaySectionType.bdSectionTypeId]].push(sectionData);
        }
      }
    });
    this.emitReportUpdate();
  }

  /**
   * Function to get section data from local store
   * If data not existing in local store then from API
   * @param id Section id
   */
  public getSectionsData(sectionId: number | string, sectionTypeId: number, bsDayDate: string, position: number, forceRefresh = false): Observable<any> {
    if (!isEmpty(this.reportData) && !forceRefresh) {
      const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
      if (index > -1) {
        if (this.reportData.businessScheduleDetail.businessScheduleDays[index][SectionTypes[sectionTypeId]]) {
          const sectionIndex = findIndex(this.reportData.businessScheduleDetail.businessScheduleDays[index][SectionTypes[sectionTypeId]],
            ['businessDaySectionId', sectionId]);
          if (sectionIndex > -1) {
            return from(
              // Delay to allow change detection to complete
              [this.reportData.businessScheduleDetail.businessScheduleDays[index][SectionTypes[sectionTypeId]][sectionIndex]]).pipe(delay(100));
          }
        }
      }
    }
    return this.getSectionDataFromApi(sectionId, sectionTypeId, bsDayDate, position);
  }

  /**
   * Function to store section data
   * @param sectionData section data
   * @param businessScheduleDayId day id
   * @param daySectionTemplateId template id
   * @param emitUpdate indicator to emit update
   */
  public storeSectionData(position: number, sectionData: any, daySectionTemplateId?: number, bsDayDate?: string, emitUpdate = true): void {

    if (position === null) {
      sectionData.businessDaySectionPositionNo = null;
    } else {
      sectionData.businessDaySectionPositionNo = !isEmpty(sectionData.businessDaySectionPositionNo) ?
        sectionData.businessDaySectionPositionNo : position;
    }
    // converting values from true and false to 1 if true, to 0 if false
    sectionData.isSectionArrangementUsed = this.sharedService.convertBoolenToNumber(sectionData.isSectionArrangementUsed);

    // converting values from true and false to 1 if true, to 0 if false
    sectionData.isSectionNoteUsed = this.sharedService.convertBoolenToNumber(sectionData.isSectionNoteUsed);

    // converting values from true and false to 1 if true, to 0 if false
    sectionData.isSectionNextWeekBusinessUsed = this.sharedService.convertBoolenToNumber(sectionData.isSectionNextWeekBusinessUsed);

    // if arrangement of section is null conver it to empty array, becuse API not taking null value
    sectionData.arrangementOfSections = sectionData.arrangementOfSections === null ? [] : sectionData.arrangementOfSections;

    // replacing null value to empty array becouse API not taking null
    switch (daySectionTemplateId) {
      case FormTemplate.leadersQuestions:
        sectionData.partiesToAskQuestions = sectionData.partiesToAskQuestions || [];
        break;
      case FormTemplate.pqOral:
        sectionData.departments = sectionData.departments || [];
        break;
      case FormTemplate.governmentBusiness:
        if (sectionData.workItems) {
          sectionData.workItems = ReportUtils.sortList('businessDaySectionWorkItemPositionNo', sectionData.workItems);
        }
        break;
      default:
        break;
    }

    if (isEmpty(this.reportData)) {
      return;
    }
    const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);

    if (index === -1) {
      return;
    }
    sectionData = {
      ...sectionData,
      businessScheduleDayId: this.reportData.businessScheduleDetail.businessScheduleDays[index].bsDayId
    };

    // Checking if section already exists
    const sectionIndex = findIndex(
      this.reportData.businessScheduleDetail.businessScheduleDays[index][SectionTypes[daySectionTemplateId]],
      ['businessDaySectionId', sectionData.businessDaySectionId]);
    this.updateDaySectionData(sectionIndex, index, daySectionTemplateId, sectionData);
    this.updateExistingSectionHeader(bsDayDate, sectionData);

    if (emitUpdate) {
      this.emitReportUpdate();
    }
  }

  /**
   * Function to save custom arrangements
   * @param arrangementData arrangement data
   * @param bsDayId bussines day id
   */
  public setCustomArrangements(arrangementData: BusinessDayArrangement[], proposalTitle: string, bsDayDate: string, isArrangementDataCleared: boolean): void {
    if (!isEmpty(this.reportData)) {
      const businessScheduleDays = this.reportData.businessScheduleDetail.businessScheduleDays;
      const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
      if (index > -1) {
        businessScheduleDays[index].businessScheduleArrangementProposal = proposalTitle;
        businessScheduleDays[index].businessDayArrangements = arrangementData;
        businessScheduleDays[index].isbusinessDayArrangementsChanged = arrangementData.length === 0 ? false : true;
        if (isArrangementDataCleared) {
          businessScheduleDays[index].dayArrangementsCleared = true;
        }
      }
      this.emitReportUpdate();
    }
  }

  /**
   * Function to get arrangements data
   * @param bsDayId bussines day id
   */
  public getCustomArrangements(bsDayDate: string): BusinessDayArrangement[] {
    const businessScheduleDays = this.reportData.businessScheduleDetail.businessScheduleDays;
    const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
    if (index > -1) {
      return businessScheduleDays[index].businessDayArrangements;
    }
  }

  /**
   * Function to get saved proposal title for arrangements
   * @param bsDayId Bussines day id
   */
  public getArrangementProposalTitle(bsDayDate: string): string {
    const businessScheduleDays = this.reportData.businessScheduleDetail.businessScheduleDays;
    const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
    if (index > -1) {
      return businessScheduleDays[index].businessScheduleArrangementProposal;
    }
  }

  /**
   * Method to store value that indicates if business day section is expanded
   * Used to keep section expanded after reports business days list is re-rendered
   * @param bsDayDate
   * @param expanded
   */
  public setDaySectionExpanded(bsDayDate: string, expanded: boolean): void {
    if (!isEmpty(this.reportData)) {
      const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
      if (index > -1) {
        // !Important - Only update value - No need to emit report update
        this.reportData.businessScheduleDetail.businessScheduleDays[index].daySectionExpanded = expanded;
      }
    }
  }

  /**
   * Function to set expanded Day and section inside this day
   * @param bsDayId Day id
   * @param bsSectionId Section id
   * @param expanded Boolean to expand or not
   */
  public setDayAndSectionExpanded(bsDayId: number, bsSectionId: number, expanded: boolean): void {
    if (!isEmpty(this.reportData)) {
      const businessScheduleDays = this.reportData.businessScheduleDetail.businessScheduleDays;
      // Searching for day index whitch should be expanded
      const index = findIndex(businessScheduleDays, ['bsDayId', bsDayId]);
      if (index > -1) {
        // Searching for section index whitch should be expanded
        const sectionIndex = findIndex(businessScheduleDays[index].businessDaySectionHeaders, ['bdSectionId', bsSectionId]);
        if (sectionIndex > -1) {
          // Setting Day expanded
          this.reportData.businessScheduleDetail.businessScheduleDays[index].daySectionExpanded = expanded;
          // Setting Section expanded
          this.reportData.businessScheduleDetail.businessScheduleDays[index].
            businessDaySectionHeaders[sectionIndex].sectionExpanded = expanded;
          // Emmit report update
          this.emitReportUpdate();

          // Reset sectionExpanded to false after report was updated
          this.reportData.businessScheduleDetail.businessScheduleDays[index].
            businessDaySectionHeaders[sectionIndex].sectionExpanded = false;
        }
      }
    }
  }

  /**
   * Function to set day new section header
   * @param bsDayDate Day date
   * @param sectionHeader New header
   */
  public setNewSectionHeader(bsDayDate: string, sectionHeader: any, isCopy = false): void {
    if (!isEmpty(this.reportData)) {
      const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
      if (index > -1) {
        const newSectionHeader = cloneDeep(sectionHeader);
        const businessDay = this.reportData.businessScheduleDetail.businessScheduleDays[index]
        const businessDaySectionHeaders = businessDay.businessDaySectionHeaders.filter(header => header.businessDaySectionPositionNo);

        // Adjusting if comes from different day
        if (isCopy) {
          const momentSectionStart = moment.utc(newSectionHeader.bsSectionStartTime, 'HH:mm');
          const lastItem = businessDaySectionHeaders[businessDaySectionHeaders.length - 1];
          const hasStartEndTime = (!isEmpty(newSectionHeader.bsSectionStartTime) && !isEmpty(newSectionHeader.bsSectionEndTime));
          const duration = hasStartEndTime
            ? moment.utc(
              moment.utc(newSectionHeader.bsSectionEndTime, 'HH:mm')
                .diff(momentSectionStart)
            ).format('HH:mm')
            : 0;

          newSectionHeader.isNewSection = true;
          newSectionHeader.businessDaySectionPositionNo = businessDaySectionHeaders.length + 1;
          newSectionHeader.bdSectionId = 0;

          if (!lastItem) {
            businessDay.bsDayStartTime = moment.utc(businessDay.bsDayDate, GLOBALS.dateTimeFormat)
              .set({
                hour: momentSectionStart.get('hour'),
                minute: momentSectionStart.get('minute')
              }).format();

            businessDay.bsDayEndTime = moment.utc(businessDay.bsDayStartTime)
              .add(duration).format();
            businessDay.bsDayEndTime = moment.utc(businessDay.bsDayStartTime)
              .add(duration).format();

            newSectionHeader.bsSectionStartTime = businessDay.bsDayStartTime;
            newSectionHeader.bsSectionEndTime = businessDay.bsDayEndTime;
          } else {
            newSectionHeader.bsSectionStartTime = lastItem.bsSectionEndTime;
            newSectionHeader.bsSectionEndTime = moment.utc(lastItem.bsSectionEndTime)
              .add(duration).format();
            newSectionHeader.businessDaySectionPositionNo = lastItem.businessDaySectionPositionNo + 1;
          }
        }

        // Adding new section headers
        businessDay.businessDaySectionHeaders.push(newSectionHeader);

        // Recalculate time
        this.recalculateBusinessDayEndTime(bsDayDate);
      }
    }
  }

  /**
   * Function to set form validity
   * @param isValid Boolean form valid or not
   * @param bsDayDate Day date
   * @param bsDaySectionId Section ID
   */
  public setSectionFormValidity(isValid: boolean, bsDayDate: string, bsDaySectionId: string | number, emitUpdate: boolean): void {
    if (!isEmpty(this.reportData)) {
      const dayIndex = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
      if (dayIndex > -1) {
        const day = this.reportData.businessScheduleDetail.businessScheduleDays[dayIndex].businessDaySectionHeaders;
        const headerId = findIndex(day, ['bdSectionId', bsDaySectionId]);
        if (headerId > -1) {
          day[headerId].isDataValid = isValid;
        }
      }
      if (emitUpdate) {
        this.emitReportUpdate();
      }
    }
  }

  /**
   * Function to find previous header end time and patch it to correct field
   * @param bsDayDate Day date
   * @param bsDaySectionId Section id
   */
  public findPreviousHeaderEndTime(bsDayDate: string, bsDaySectionId: number | string): string {
    const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
    if (index > -1) {
      const headerId =
        findIndex(this.reportData.businessScheduleDetail.businessScheduleDays[index].businessDaySectionHeaders,
          ['bdSectionId', bsDaySectionId]);
      const previousLastHeaderWithEndTime = findLast(
        takeLodash(
          this.reportData.businessScheduleDetail.businessScheduleDays[index].businessDaySectionHeaders, headerId),
        (element) => !!element.bsSectionEndTime);
      if (headerId === 0) {
        return this.reportData.businessScheduleDetail.businessScheduleDays[index].bsDayStartTime;
      } else {
        return previousLastHeaderWithEndTime !== undefined ? previousLastHeaderWithEndTime.bsSectionEndTime :
          this.reportData.businessScheduleDetail.businessScheduleDays[index].bsDayStartTime;
      }
    }
  }

  /**
   * Function do delete added custom section and header
   * @param bsSectionId Section id
   * @param bsDayDate Day Date
   * @param bsSectionTypeId Section type
   */
  public deleteNewCustomSectionAndHeader(bsSectionId: string, bsDayDate: string, bsSectionTypeId: string): void {
    if (!isEmpty(this.reportData)) {
      const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
      if (index > -1) {

        // Searching header index
        const headerId =
          findIndex(this.reportData.businessScheduleDetail.businessScheduleDays[index].businessDaySectionHeaders,
            ['bdSectionId', bsSectionId]);
        if (headerId > -1) {
          // Remove section header
          this.reportData.businessScheduleDetail.businessScheduleDays[index].businessDaySectionHeaders.splice(headerId, 1);
          this.recalculateBusinessDayEndTime(bsDayDate);
        }

        // Searching sectionId index
        const sectionId = findIndex(
          this.reportData.businessScheduleDetail.businessScheduleDays[index][SectionTypes[bsSectionTypeId]],
          ['businessDaySectionId', bsSectionId]);
        if (sectionId > -1) {
          // Remove section data
          this.reportData.businessScheduleDetail.businessScheduleDays[index][SectionTypes[bsSectionTypeId]].splice(sectionId, 1);
        }
      }
    }
    this.emitReportUpdate();
  }

  /**
   * Function to set report data
   * @param obj data object
   */
  public setReportData(obj: Report): void {
    if (obj) {
      obj.businessScheduleDetail.businessScheduleDays.sort((a, b) => {
        return new Date(this.sharedService.convertDate(a.bsDayDate)).getTime() -
          new Date(this.sharedService.convertDate(b.bsDayDate)).getTime();
      });
      
      obj.businessScheduleDetail.businessScheduleDays.forEach(scheduleDay => {
        if (scheduleDay.businessDaySectionHeaders) {
          // CR-57221 local property
          scheduleDay.businessDaySectionHeaders.forEach(header => header.wasSectionRemoved = !!header.wasSectionRemoved);
        }
      });
    }
    this.reportData = obj;
    this.emitReportUpdate();
  }

  /**
   * Function to set order of business report data
   */
  public setObReportData(obj: OrderOfBusiness): void {
    this.reportObData = obj;
    this.emitObReportUpdate();
  }

  /**
   * Function to map report data
   * If 'businessDaySectionId' is string convert it to null
   * @param arrayToMap Array to map
   */
  private convertBusinessDaySectionIdFromStringToNull(arrayToMap: string): Report {
    const report: Report = this.reportData;
    report.businessScheduleDetail.businessScheduleDays = report.businessScheduleDetail.businessScheduleDays.map((items) => {
      return {
        ...items,
        [arrayToMap]: isArray(items[arrayToMap]) ? items[arrayToMap].map((bdSection) => {
          return {
            ...bdSection,
            businessDaySectionId: typeof bdSection.businessDaySectionId === 'string' ? 0 : bdSection.businessDaySectionId,
            businessDaySectionDuration: moment.duration(bdSection.businessDaySectionDuration).asMinutes()
          };
        }) : []
      };
    });
    return report;
  }



  /**
   * Function to get final object whirtch will be sent to API
   */
  public getReportData(): Report {
    const report: Report = this.reportData;
    report.businessScheduleDetail.bsDetailFirstDay =
      this.sharedService.convertDate(report.businessScheduleDetail.bsDetailFirstDay);
    report.businessScheduleDetail.bsDetailLastDay =
      this.sharedService.convertDate(report.businessScheduleDetail.bsDetailLastDay);
    report.businessScheduleDetail.businessScheduleDays = report.businessScheduleDetail.businessScheduleDays.map((items) => {
      return {
        ...items,
        bsDayDate: this.sharedService.convertDate(items.bsDayDate),
        bsDayStartTime: this.sharedService.convertDate(items.bsDayStartTime),
        bsDayEndTime: this.sharedService.convertDate(items.bsDayEndTime),
        businessDayArrangements: items.isbusinessDayArrangementsChanged === true ? items.businessDayArrangements : null,
        businessDaySectionHeaders: items.businessDaySectionHeaders.map(header => {
          return {
            ...header,
            bdSectionId: typeof header.bdSectionId === 'string' ? 0 : header.bdSectionId,
            bsSectionStartTime: this.sharedService.convertDate(header.bsSectionStartTime),
            bsSectionEndTime: this.sharedService.convertDate(header.bsSectionEndTime),
            businessDaySectionTypeId: header.businessDaySectionType.bdSectionTypeId,
          };
        })
      };
    });

    this.convertBusinessDaySectionIdFromStringToNull('businessDayLeadersQuestionsSectionDetails');
    this.convertBusinessDaySectionIdFromStringToNull('businessDayCustomSectionDetails');
    this.convertBusinessDaySectionIdFromStringToNull('businessDaySectionsPQOralDetails');
    this.convertBusinessDaySectionIdFromStringToNull('businessDayCommonSectionDetails');
    this.convertBusinessDaySectionIdFromStringToNull('businessDayOrderOfBusinessSectionDetails');
    this.convertBusinessDaySectionIdFromStringToNull('businessDayMotionsWithoutDebateSectionDetails');
    this.convertBusinessDaySectionIdFromStringToNull('businessDayGovernmentBusinessSectionDetails');
    this.convertBusinessDaySectionIdFromStringToNull('businessDayPrivateMembersBusinessSectionDetails');
    this.convertBusinessDaySectionIdFromStringToNull('businessDayBillsForIntroductionSectionDetails');
    this.convertBusinessDaySectionIdFromStringToNull('businessDayReferralToCommitteeSectionDetails');
    this.convertBusinessDaySectionIdFromStringToNull('deferredDivisionsSectionDetails');
    this.convertBusinessDaySectionIdFromStringToNull('privateMemberBillSectionDetails');
    return report;
  }

  /**
   * Method to check if section has all required data
   * Used to display according icon
   * @param bsDayDate Day date
   * @param bsSectionId Section id
   */
  public doesSectionHaveAllRequiredData(bsDayDate: string, bsSectionId: string | number): boolean {
    const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
    if (index > -1) {
      const sectionHeaders = this.reportData.businessScheduleDetail.businessScheduleDays[index].businessDaySectionHeaders;
      const headerId = findIndex(sectionHeaders, ['bdSectionId', bsSectionId]);
      if (headerId > -1) {
        return sectionHeaders[headerId].isDataValid;
      }
    }
  }

  /**
   * Function to collect all section validity information
   */
  public isSectionFormsValid(): Array<any> {
    if (!isEmpty(this.reportData)) {
      const sectionFormValidityData = [];
      this.reportData.businessScheduleDetail.businessScheduleDays.forEach(day => {
        day.businessDaySectionHeaders = day.businessDaySectionHeaders.map(item => {
          return {
            ...item,
            sittingDay: day.isBSDaySitting
          };
        });
        day.businessDaySectionHeaders.forEach(header => {
          sectionFormValidityData.push(header);
        });
      });
      return sectionFormValidityData;
    }
  }

  /**
   * Function to delete custom added props
   */
  public deleteCustomProps(): void {
    const report: Report = this.reportData;
    report.businessScheduleDetail.businessScheduleDays.forEach(day => {
      delete day.daySectionExpanded;
      delete day.isbusinessDayArrangementsChanged;
      day.businessDaySectionHeaders.forEach(header => {
        delete header.isNewSection;
        delete header.sectionExpanded;
        delete header.sittingDay;
      });
    });
  }

  /**
   * Method to update and store non default sitting day with day template data
   * @param bsDayDate
   * @param day
   * @param templateId
   */
  private updateNonDefaultDayWithDayTemplate(bsDayDate: string, day: BusinessScheduleDay[], templateId: number, oldDayHeaders: BusinessScheduleDay): void {
    if (isEmpty(this.reportData)) {
      return;
    }

    const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
    if (index === -1) {
      return;
    }

    // Update day start time, if it is blank template set Start time null
    this.reportData.businessScheduleDetail.businessScheduleDays[index].bsDayStartTime =
      !isEmpty(day) ?
        this.sharedService.convertDate(day[0].bsDayStartTime) : null;

    // Update day end time, if it is blank template set End time null
    this.reportData.businessScheduleDetail.businessScheduleDays[index].bsDayEndTime =
      !isEmpty(day) ?
        this.sharedService.convertDate(day[0].bsDayEndTime) : null;

    // Update day section headers
    this.reportData.businessScheduleDetail.businessScheduleDays[index].businessDaySectionHeaders =
      !isEmpty(day) ? day[0].businessDaySectionHeaders : [];
    // Add missing property `businessDaySectionTypeId` required for BE when saving new templated day
    for (const header of this.reportData.businessScheduleDetail.businessScheduleDays[index].businessDaySectionHeaders) {
      header.businessDaySectionTypeId = header.businessDaySectionType.bdSectionTypeId;
    }

    // Update day template id
    this.reportData.businessScheduleDetail.businessScheduleDays[index].bsDayTemplateId =
      !isEmpty(day) ? templateId : null;

    // Remove all day sections details
    Object.keys(SectionTypes).forEach(key => {
      delete this.reportData.businessScheduleDetail.businessScheduleDays[index][SectionTypes[key]];
    });

    // Populate day sections
    if (!isEmpty(day)) {
      this.populateDaySections(day[0].businessDaySectionHeaders, index);
    }

    if (!isEmpty(oldDayHeaders.businessDaySectionHeaders)) {
      this.populateOldDaySections(bsDayDate, oldDayHeaders.businessDaySectionHeaders);
    }

    this.emitReportUpdate();
  }

  /**
   * Method to recalculate bu
   * @param bsDayId
   * @param emitUpdate indicator to emit update
   */
  private recalculateBusinessDayEndTime(bsDayDate: string, emitUpdate = true) {
    const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
    // Also skip days with no start time, becouse if there will be no start time it will apply Invalid date to End time
    if (index > -1 && !isEmpty(this.reportData.businessScheduleDetail.businessScheduleDays[index].bsDayStartTime)) {
      let endTime: any = moment.utc(this.sharedService.convertDate(this.reportData.businessScheduleDetail.businessScheduleDays[index].bsDayStartTime)).format();

      // Loop through businessDay section headers to calculate duration of each section to add to end time
      this.reportData.businessScheduleDetail.businessScheduleDays[index].businessDaySectionHeaders.forEach(daySectionHeader => {
        if (daySectionHeader.bsSectionStartTime && daySectionHeader.bsSectionEndTime && daySectionHeader.businessDaySectionPositionNo !== null) {

          const timeDifference = this.sharedService.timeDifference(
            this.sharedService.getTimeFromDate(daySectionHeader.bsSectionStartTime),
            this.sharedService.getTimeFromDate(daySectionHeader.bsSectionEndTime)
          );
          const h = timeDifference.split(':')[0];
          const m = timeDifference.split(':')[1];
          endTime = moment.utc(endTime).add(m, 'minutes').add(h, 'hours').format();
        }
      });

      this.reportData.businessScheduleDetail.businessScheduleDays[index].bsDayEndTime = endTime;

      if (emitUpdate) {
        this.emitReportUpdate();
      }
    }
  }

  /**
   * Method to get array index of businessDay in report businessScheduleDays array by bsDayDate
   * @param bsDayId
   */
  private getBusinessDayArrayElementIndexByDayDate(date: string): number {
    return findIndex(this.reportData.businessScheduleDetail.businessScheduleDays, ['bsDayDate', date]);
  }

  /**
   * Method to emit report data change
   */
  private emitReportUpdate(): void {
    this.reportDataChanged.next(this.reportData);
  }

  /**
   * Method to emit order of business report data change
   */
  private emitObReportUpdate(): void {
    this.reportObDataChanged.next(this.reportObData);
  }



  /**
   * Method to update sections header when section is updated
   * @param bsDayDate
   * @param sectionData
   */
  private updateExistingSectionHeader(
    bsDayDate: string,
    sectionData: BusinessDayCommonSectionDetail |
      BusinessDayLeadersQuestionsSectionDetail |
      BusinessDaySectionsPQOralDetail |
      BusinessDayMotionsWithoutDebateSectionDetail
  ): void {
    if (!isEmpty(this.reportData)) {
      const index = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);

      if (index > -1) {

        // Check if section id is same as header id
        const headerIndex = findIndex(this.reportData.businessScheduleDetail.businessScheduleDays[index].businessDaySectionHeaders, ['bdSectionId', sectionData.businessDaySectionId]);

        if (headerIndex > -1) {
          this.reportData.businessScheduleDetail.businessScheduleDays[index].businessDaySectionHeaders[headerIndex].bsSectionStartTime = sectionData.bsSectionStartTime;
          this.reportData.businessScheduleDetail.businessScheduleDays[index].businessDaySectionHeaders[headerIndex].bsSectionEndTime = sectionData.bsSectionEndTime;
        }

        this.recalculateReportDaysTime();
      }
    }
  }

  /**
   * Method to recalculate end time for all days and all day section start & end time
   * Since start time for all section is not editable it is taken from day's start time
   * And again since start time is not editable it means that it must be same as previous sections (that has it) end time
   * So we can kind of use 'chain' logic for each section with next one to update times
   * This method can be used both when section is updated or when day's start time is updated
   * @param emitUpdate flag to emit update
   */
  private recalculateReportDaysTime(emitUpdate = false): void {
    if (isEmpty(this.reportData)) {
      return;
    }
    this.reportData.businessScheduleDetail.businessScheduleDays.forEach(businessDay => {
      // Sort busines day header by position number
      businessDay.businessDaySectionHeaders = sortBy(businessDay.businessDaySectionHeaders, ['businessDaySectionPositionNo']);

      businessDay.businessDaySectionHeaders.forEach((header, i) => {

        // Skip headers with no time and disabled headers
        if (header.bsSectionStartTime && header.bsSectionEndTime && header.businessDaySectionPositionNo !== null) {

          // Take all headers from beginning till current header and reverse list
          const previousLastHeaderWithEndTime = findLast(takeLodash(businessDay.businessDaySectionHeaders, i), (element) => !!element.bsSectionEndTime);

          // Calculate existing time difference for current header
          const duration = this.calculateDurationTime(header.bsSectionStartTime, header.bsSectionEndTime);

          this.assignHeaderSectionDuration(header, previousLastHeaderWithEndTime, businessDay, duration);
        }

      });

      // Update end time for day
      this.recalculateBusinessDayEndTime(businessDay.bsDayDate, false);

    });

    if (emitUpdate) {
      this.emitReportUpdate();
    }
  }

  /**
   * Method to calculate duration between two dates
   * @param start ISO date
   * @param end ISO date
   */
  private calculateDurationTime(start: string, end: string): string {
    start = moment(this.sharedService.convertDate(start)).utc().format(GLOBALS.timeFormat);
    end = moment(this.sharedService.convertDate(end)).utc().format(GLOBALS.timeFormat);
    const minutes = moment(end, GLOBALS.timeFormat).diff(moment(start, GLOBALS.timeFormat), 'minutes', true);
    const durationHours = minutes / 60;
    const durationMinutes = minutes % 60;
    return moment.utc().hours(durationHours).minutes(durationMinutes).format(GLOBALS.timeFormat);
  }

  /**
   * Method to add duration to date time
   * @param date as ISO
   * @param duration as HH:MM
   */
  private addDurationToDateTime(date: string, duration: string): string {
    date = this.sharedService.convertDate(date);
    const hoursToMinutes = moment.duration(duration).asMinutes();

    return moment.utc(date).add(hoursToMinutes, 'minutes').format();
  }

  private updateDaySectionData(sectionIndex: number, dayIndex: number, daySectionTemplateId: number, sectionData: any): void {
    if (sectionIndex > -1) {
      // If exists overwriting data
      this.reportData.businessScheduleDetail.businessScheduleDays[dayIndex][SectionTypes[daySectionTemplateId]][sectionIndex] =
        this.sharedService.cleanObjectNullValue(sectionData);
    } else {
      // Checking if array exists
      if (!isArray(this.reportData.businessScheduleDetail.businessScheduleDays[dayIndex][SectionTypes[daySectionTemplateId]])) {
        // If array not exists creating array with section
        this.reportData.businessScheduleDetail.businessScheduleDays[dayIndex][SectionTypes[daySectionTemplateId]] =
          [this.sharedService.cleanObjectNullValue(sectionData)];
      } else {
        // If array exists pushing new section data
        this.reportData.businessScheduleDetail.businessScheduleDays[dayIndex][SectionTypes[daySectionTemplateId]].push(
          this.sharedService.cleanObjectNullValue(sectionData));
      }
    }
  }

  private populateDaySections(businessDaySectionHeaders: BusinessDaySectionHeader[], index: number): void {
    businessDaySectionHeaders.forEach(header => {
      const randomSectionId = this.sharedService.generateId();
      const hasStartEndTime = (!isEmpty(header.bsSectionStartTime) && !isEmpty(header.bsSectionEndTime));
      const duration = hasStartEndTime ? this.calculateDurationTime(header.bsSectionStartTime, header.bsSectionEndTime) : 0;
      header.bdSectionId = randomSectionId;
      header.businessDaySectionTypeId = header.businessDaySectionType.bdSectionTypeId;
      const sectionTemplate = {
        businessScheduleDayId: this.reportData.businessScheduleDetail.businessScheduleDays[index].bsDayId,
        businessDaySectionId: randomSectionId,
        bsSectionStartTime: this.sharedService.convertDate(header.bsSectionStartTime),
        bsSectionEndTime: this.sharedService.convertDate(header.bsSectionEndTime),
        businessDaySectionDuration: duration,
        businessDaySectionTemplateId: header.businessDaySectionType.bdSectionTypeId,
        businessDaySectionTitle: header.bsDaySectionTitle,
        businessDaySectionTypeId: header.businessDaySectionType.bdSectionTypeId,
        businessDaySectionPositionNo: header.businessDaySectionPositionNo,
      };
      if (header.businessDaySectionType && header.businessDaySectionType.bdSectionTypeId) {
        if (!isArray(this.reportData.businessScheduleDetail.businessScheduleDays[index]
        [SectionTypes[header.businessDaySectionType.bdSectionTypeId]])) {
          this.reportData.businessScheduleDetail.businessScheduleDays[index]
          [SectionTypes[header.businessDaySectionType.bdSectionTypeId]] = [sectionTemplate];
        } else {
          this.reportData.businessScheduleDetail.businessScheduleDays[index]
          [SectionTypes[header.businessDaySectionType.bdSectionTypeId]].push(sectionTemplate);
        }
      }
    });
  }

  private populateOldDaySections(bsDayDate: string, oldBusinessDaySectionHeaders: BusinessDaySectionHeader[]): void {
    const indexOfday = this.getBusinessDayArrayElementIndexByDayDate(bsDayDate);
    if (indexOfday === -1) {
      return;
    }
    oldBusinessDaySectionHeaders.forEach((item: any) => {
      if (typeof item.bdSectionId !== 'string') {
        if (isEmpty(this.reportData.businessScheduleDetail.businessScheduleDays[indexOfday].deletedSections)) {
          this.reportData.businessScheduleDetail.businessScheduleDays[indexOfday].deletedSections = [item.bdSectionId];
        } else {
          this.reportData.businessScheduleDetail.businessScheduleDays[indexOfday].deletedSections.push(item.bdSectionId);
        }
      }
    });
  }

  private assignHeaderSectionDuration(
    header: BusinessDaySectionHeader,
    previousLastHeaderWithEndTime: BusinessDaySectionHeader,
    businessDay: BusinessScheduleDay,
    duration: string
  ): void {
    // Align start time with last previous end time or use the start time of the day
    header.bsSectionStartTime = !isEmpty(previousLastHeaderWithEndTime) ? previousLastHeaderWithEndTime.bsSectionEndTime : businessDay.bsDayStartTime;

    // Recalculate end time by adding duration to new start time
    header.bsSectionEndTime = this.addDurationToDateTime(header.bsSectionStartTime, duration);

    const sectionTypeId = header.businessDaySectionType.bdSectionTypeId || header.businessDaySectionTypeId;

    // Check if section data exists and update it
    if (!isEmpty(businessDay[SectionTypes[sectionTypeId]])) {
      const sectionIndex = findIndex(businessDay[SectionTypes[sectionTypeId]], ['businessDaySectionId', header.bdSectionId]);
      if (sectionIndex > -1) {
        businessDay[SectionTypes[sectionTypeId]][sectionIndex].bsSectionStartTime = header.bsSectionStartTime;
        businessDay[SectionTypes[sectionTypeId]][sectionIndex].bsSectionEndTime = header.bsSectionEndTime;
        businessDay[SectionTypes[sectionTypeId]][sectionIndex].businessDaySectionDuration = duration;
      }
    }
  }
}
