import { Component, Input, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ToggleableAccordionComponent } from 'src/app/shared/components/toggleable-accordion/toggleable-accordion.component';
import { ReportStoreService } from 'src/app/services/report-store.service';
import { take } from 'rxjs/operators';
import { SectionsFormComponent } from './sections-form/sections-form.component';
import { AddNewSectionDeleteModalComponent } from 'src/app/shared/modals/add-new-section-delete-modal/add-new-section-delete-modal.component';
import { BusinessDaySectionHeader } from 'src/app/shared/models/report.model';
import { cloneDeep } from 'lodash-es';
import { CancelConfirmModalComponent } from 'src/app/create-report/cancel-confirm-modal/cancel-confirm-modal.component';

@Component({
  selector: 'oir-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.less']
})
export class SectionComponent implements AfterViewInit {
  @Input() businessDaySectionHeader: BusinessDaySectionHeader;
  @Input() itemIndex: number;
  @Input() bsDayDate: string;
  @Input() bsDayId: number;
  @Input() disableSection: boolean;

  @Output() toggleAccordion: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('toggleableAccordion') toggleableAccordion: ToggleableAccordionComponent;
  @ViewChild('sectionForm') sectionForm: SectionsFormComponent;
  @ViewChild('newSectionDeleteModal') newSectionDeleteModal: AddNewSectionDeleteModalComponent;
  @ViewChild('cancelConfirmModalComponent') cancelConfirmModalComponent: CancelConfirmModalComponent;

  public canToggle = true;

  constructor(
    private reportStoreService: ReportStoreService
  ) { }

  public ngAfterViewInit(): void {
    this.sectionIsValid();
  }

  /**
   * Function to toggle delete modal
   */
  public deleteNewSection(): void {
    this.newSectionDeleteModal.toggleDeleteModal();
  }

  /**
   * Method to disable section
   */
  public removeSection(): void {
    this.reportStoreService.disableHeaderForDefaultSection(this.bsDayDate, this.businessDaySectionHeader.bdSectionId);
  }

  /**
   * Call API to get section data
   */
  public populateSectionWithData(forceRefresh = false): void {
    this.reportStoreService.getSectionsData(
      this.businessDaySectionHeader.bdSectionId,
      this.businessDaySectionHeader.businessDaySectionType.bdSectionTypeId,
      this.bsDayDate,
      this.businessDaySectionHeader.businessDaySectionPositionNo,
      forceRefresh).pipe(take(1))
      .subscribe(res => this.sectionForm.populateForm(cloneDeep(res)));
  }

  /**
   * Function to show only opened section, others should hide
   * @param isOpen Boolean
   */
  public onDefaultOpened(isOpen: boolean): void {
    this.toggleAccordion.emit(
      {
        dayId: this.bsDayId,
        sectionId: this.businessDaySectionHeader.bdSectionId,
        hideOthers: isOpen
      }
    );
  }

  /**
   * Function whitch triggers after accordion was opened and reasign can toggle param
   * @param isOpen Boolean param
   */
  public afterSectionAccordionToggle(isOpen: boolean): void {
    this.canToggle = !isOpen;
    if (isOpen) {
      this.populateSectionWithData();
    }
    this.toggleAccordion.emit(
      {
        dayId: this.bsDayId,
        sectionId: this.businessDaySectionHeader.bdSectionId,
        hideOthers: isOpen
      }
    );
  }

  /**
   * Function whitch triggers on accordion close
   */
  public onAccordionClose(): void {
    this.disableSection ? this.closeSection() :
      this.cancelConfirmModalComponent.toggle();
    this.sectionIsValid();
  }

  /**
   * Event on cancel click in section
   */
  public onCancelClick(): void {
    this.disableSection ? this.closeSection() :
      this.cancelConfirmModalComponent.toggle();
  }

  /**
   * Function to close accordion on cancel
   * @param event Event
   */
  public cancelModalDecision(event: any): void {
    if (event) {
      this.closeSection();
    }
  }

  /**
   * Function to close section
   */
  public closeSection() {
    this.canToggle = true;
    // Need to timeout to allow change detection to work
    setTimeout(() => {
      this.toggleableAccordion.click();
      // No need to clear form because component it is destroyed with ngIf
    }, 1);
  }

  /**
   * Function to set image for section
   */
  private sectionIsValid(): void {
    if (this.reportStoreService.doesSectionHaveAllRequiredData(this.bsDayDate, this.businessDaySectionHeader.bdSectionId)) {
      this.toggleableAccordion.setOpenImageUrl = 'filled-circle-true';
    } else {
      this.toggleableAccordion.setOpenImageUrl = 'unfilled-plus';
    }
  }
}
