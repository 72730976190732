export const SectionTemplates = {
  leadersQuestions: [
    'parties',
    'sectionDetails',
    'sectionArrangements',
  ],

  orderOfBusiness: [
    'sectionDetails',
    'sectionArrangements'
  ],

  motions: [
    'sectionDetails',
    'sectionArrangements'
  ],

  taoiseach: [
    'sectionDetails',
    'sectionArrangements'
  ],

  pqOral: [
    'department',
    'sectionDetails',
    'sectionArrangements',
  ],

  topicalIssues: [
    'sectionDetails',
    'sectionArrangements'
  ],

  privateNoticeQuestion: [
    'sectionDetails',
    'sectionArrangements'
  ],

  governmentBusiness: [
    'sectionDetails',
    'nextWeeksBusiness',
    'sectionArrangements'
  ],

  privateMembersBusiness: [
    'parties',
    'sectionDetails',
    'nextWeeksBusiness',
    'sectionArrangements'
  ],

  privateMembersBill: [
    'sectionDetails',
    'nextWeeksBusiness',
    'sectionArrangements'
  ],

  questionsOnPromisedLegislation: [
    'sectionDetails',
    'sectionArrangements'
  ],

  sos: [
    'sectionDetails',
    'sectionArrangements'
  ],

  deferredDivisions: [
    'sectionDetails',
    'sectionArrangements'
  ],

  dayArrangements: [],

  adjournment: [],

  custom: [
    'sectionDetails',
    'sectionArrangements'
  ],

  expressionsOfSympathy: [
    'sectionDetails',
    'sectionArrangements'
  ],

  messages: [
    'sectionDetails',
    'sectionArrangements'
  ],

  announcements: [
    'sectionDetails',
    'sectionArrangements'
  ],

  billsForIntroduction: [
    'sectionDetails',
    'sectionArrangements'
  ],

  referralToCommittee: [
    'sectionDetails',
    'sectionArrangements'
  ],
};
