import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {NavigationService, OverlayService, ToastNotificationsService} from 'proceduralsystem-clientcomponents';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {extendMoment} from 'moment-range';
import {last, switchMap, take, takeUntil} from 'rxjs/operators';
import {ReportService} from '../services/report.service';
import {DatePickerCustomComponent} from './../shared/components/datepicker-custom/datepicker-custom.component';
import {GLOBALS} from '../shared/globals';
import {BusinessDayCommonSectionDetail, BusinessScheduleDay, ConcurrentUser, Report} from '../shared/models/report.model';
import {ReportStoreService} from '../services/report-store.service';
import {SharedService} from '../services/shared.service';
import {ArrangementTypes, FileExtensionType, FormTemplate, ReportStatuses, SectionTypes, TabIndexes} from '../shared/report.enum';
import {concat, Observable, Subject} from 'rxjs';
import {cloneDeep, compact, isEmpty} from 'lodash-es';
import {DayInstanceComponent} from './day-instance/day-instance.component';
import {CirculateBcrModalComponent} from './modals/circulate-bcr-modal/circulate-bcr-modal.component';
import {ActionBarEmailModalComponent} from './modals/action-bar-email-modal/action-bar-email-modal.component';
import {CancelConfirmModalComponent} from '../create-report/cancel-confirm-modal/cancel-confirm-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {AppConfigService} from '../services/app-config.service';
import { DOCUMENT } from '@angular/common';
import OirCkEditor from 'proceduralsystem-ckeditor';
import { OirCkEditorConfig } from 'proceduralsystem-ckeditor';
import { EditorConfig } from '../shared/models/editor-config.model';

@Component({
  selector: "oir-view-report",
  templateUrl: "./view-report.component.html",
  styleUrls: ["./view-report.component.less"],
})
export class ViewReportComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("ckEditor") ckEditor: any;

  public observer: MutationObserver;
  public exsistingDates: Array<any> = [];
  public newDates: Array<any> = [];
  public daysOfWeek: Array<any> = [];
  public startDate;
  public defaultHeader: string;
  public endDate;
  public selectedRange: string;
  public fallbackDate: string;
  public isReadOnly = false;
  public isEditModeDate = false;
  public isEditModeDayTemplate = false;
  public day: BusinessScheduleDay;
  public dateChanged = false;
  public report;
  public rangeForm: FormGroup;
  public noteForm: FormGroup = new FormGroup({
    note: new FormControl(""),
  });
  public dayInstance: FormGroup;
  public iterableDiff;
  public weekDays: string;
  public dayCount: number;
  public reportStatusId: number;
  public preselectDates = true;
  public dateRange = true;
  public invalidInputDate = false;
  public textWithHTML: string;
  public textStripped: string;
  public disableConfirm = false;
  public revisedBcrNumber: string;
  public updateBcrString: string;
  public ReportStatuses = ReportStatuses;
  public reportTitle: string;
  public reportNote: string;
  public reportVersion: number;

  private currentWeek: number;
  private deletedDays = [];
  private documentId: string;
  private dateText: string;
  private dayText: string;
  private ngUnsubscribe = new Subject<void>();
  private hasReportLoaded = false;
  private userEdits: string;
  private userName: string;
  private window: Window;

  Editor = OirCkEditor;
  ckEditorConfig: OirCkEditorConfig;

  public recursiveLimit = 0;

  @ViewChild("reportDatesRange") reportDatesRange: DatePickerCustomComponent;
  @ViewChildren("dayInstanceComponent")
  dayInstanceComponents: QueryList<DayInstanceComponent>;
  @ViewChild("circulateBcrModalComponent")
  circulateBcrModalComponent: CirculateBcrModalComponent;
  @ViewChild("actionBarEmailModalComponent")
  actionbarEmailModalComponent: ActionBarEmailModalComponent;
  @ViewChild("cancelConfirmModalComponent")
  cancelConfirmModalComponent: CancelConfirmModalComponent;

  constructor(
    private translateService: TranslateService,
    private navigationService: NavigationService,
    private reportService: ReportService,
    private route: ActivatedRoute,
    private reportStore: ReportStoreService,
    private router: Router,
    private sharedService: SharedService,
    private toastService: ToastNotificationsService,
    private appConfigService: AppConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private overlayService: OverlayService,
    private configurationService: AppConfigService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
    this.ckEditorConfig = {
      ...EditorConfig,
      licenseKey: this.configurationService.getValue('CKEditor5LicenseKey')
    }
  }

  public ngOnInit(): void {
    // Getting current document id
    this.documentId = this.route.snapshot.paramMap.get("documentId");

    // Calling API to get Days of the week
    this.reportService
      .getDayOfTheWeek()
      .pipe(take(1))
      .subscribe((res) => {
        for (const day of res) {
          this.daysOfWeek.push(day.dayId);
        }
      });

    // Calling API to get Party data
    this.reportService
      .getParties()
      .pipe(take(1))
      .subscribe((res) => {
        this.reportService.partiesData(res);
      });

    // Calling API to get Departments data
    this.reportService
      .getDepartments()
      .pipe(take(1))
      .subscribe((res) => {
        this.reportService.departmentsData(res);
      });

    // Calling API to get Arrangements section data
    this.reportService
      .getArrangements(ArrangementTypes.Section)
      .pipe(take(1))
      .subscribe((res) => {
        this.reportService.sectionArrangementData(res);
      });

    // Calling API to get Arrangements day data
    this.reportService
      .getArrangements(ArrangementTypes.Day)
      .pipe(take(1))
      .subscribe((res) => {
        this.reportService.dayArrangementData(res);
      });

    // Calling API to get Arrangements work items data
    this.reportService
      .getArrangements(ArrangementTypes.WorkItem)
      .pipe(take(1))
      .subscribe((res) => {
        this.reportService.workItemArrangementData(res);
      });

    // Calling API to get Members data
    this.reportService
      .getMembers()
      .pipe(take(1))
      .subscribe((res) => {
        this.reportService.membersData(res);
      });

    // Calling API to get Section type data
    this.reportService
      .getSectionTypes()
      .pipe(take(1))
      .subscribe((res) => {
        this.reportService.getSectionTypesData(res);
      });

    // Subscribe to report data change in report store service
    this.reportStore.reportData$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((report) => {
        this.report = cloneDeep(report);
        if (report) {
          this.changeDetectorRef.detectChanges();
        }
        if (!this.hasReportLoaded) {
          this.hasReportLoaded = true;
          this.populateReportView();

          // Deployed version sometimes having overlay stucked.
          if (this.overlayService.model.value === true) {
            setTimeout(() => this.overlayService.hide(true), 100);
          }
        }
      });

    this.appConfigService
      .get("UserName")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((userName) => {
        this.userName = userName;
      });

    this.reportService.titleTranslationRequested
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((requestDetails) => {
        const { data, isSaveRequiredBeforeTranslation } = requestDetails;

        if (!data) {
          return;
        }

        if (isSaveRequiredBeforeTranslation) {
          let unsavedSection;

          this.report.businessScheduleDetail.businessScheduleDays.forEach(
            (day) => {
              const foundSection =
                day.businessDayCustomSectionDetails &&
                day.businessDayCustomSectionDetails.find(
                  (section) => section.businessDaySectionId === data.id
                );

              if (foundSection) {
                unsavedSection = foundSection;
              }
            }
          );

          const report = this.getAdjustedReportToPost();

          concat(
            this.reportService.postReport(this.documentId, report),
            this.reportService.getReport(this.documentId)
          )
            .pipe(
              take(2),
              last(),
              switchMap((report: Report) => {
                this.reportStore.setReportData(report);

                if (unsavedSection) {
                  const expectedStartTime = moment
                    .utc(unsavedSection.bsSectionStartTime)
                    .format("DD/MM/YYYY HH:mm:ss");
                  const expectedEndTime = moment
                    .utc(unsavedSection.bsSectionEndTime)
                    .format("DD/MM/YYYY HH:mm:ss");

                  report.businessScheduleDetail.businessScheduleDays.forEach(
                    (day) => {
                      const foundSection = day.businessDaySectionHeaders.find(
                        (header) =>
                          header.bsSectionStartTime === expectedStartTime &&
                          header.bsSectionEndTime === expectedEndTime
                      );

                      if (foundSection) {
                        data.id = foundSection.bdSectionId;
                        this.reportStore.setDayAndSectionExpanded(
                          day.bsDayId,
                          foundSection.bdSectionId as number,
                          true
                        );
                        return;
                      }
                    }
                  );
                }

                return !!data.dueDate
                  ? this.reportService.requestTitleTranslation(data)
                  : this.reportService.requestTitleTranslationWithdraw(data);
              })
            )
            .subscribe((isSuccess: boolean) =>
              this.titleTranslationSuccess(isSuccess, !data.dueDate)
            );
        } else if (!!data.dueDate) {
          this.reportService
            .requestTitleTranslation(data)
            .pipe(take(1))
            .subscribe((isSuccess: boolean) =>
              this.titleTranslationSuccess(isSuccess)
            );
        } else {
          this.reportService
            .requestTitleTranslationWithdraw(data)
            .pipe(take(1))
            .subscribe((isSuccess: boolean) =>
              this.titleTranslationSuccess(isSuccess, true)
            );
        }
      });

    this.rangeForm = new FormGroup({
      reportDatesRange: new FormControl(
        this.selectedRange,
        Validators.required
      ),
    });
  }

  ngOnChanges() {
    this.changeDetectorRef.detectChanges();
  }

  public ngAfterViewInit(): void {
    // Calling API to get report
    this.reportService
      .getReport(this.documentId)
      .pipe(take(1))
      .subscribe((res) => {
        this.reportStore.setReportData(res);
        // Subscribe to get activated route queryParams
        this.route.queryParams
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((params) => {
            // If params exsist
            if (!isEmpty(params)) {
              const bsDayId = parseInt(params.bsDayId, 10);
              const bsSectionId = parseInt(params.bsSectionId, 10);
              this.reportStore.setDayAndSectionExpanded(
                bsDayId,
                bsSectionId,
                true
              );
              // Scroll to opened section
              document.getElementById(params.bsSectionId).scrollIntoView();

              this.observeChanges();
            }
          });
      });
  }

  onReady() {
    setTimeout(() => {
      if (
        this.window &&
        this.window[0] &&
        this.defaultHeader &&
        this.recursiveLimit <= GLOBALS.recursiveMaxLimit
      ) {
        this.window[0].document.getElementsByTagName(
          "body"
        )[0].style.textAlign = "center";
        this.window[0].document.getElementsByTagName(
          "body"
        )[0].style.fontFamily = "'Montserrat-Regular', sans-serif";
        this.window[0].document.getElementsByTagName("body")[0].style.fontSize =
          "15px";
        this.window[0].document.getElementsByTagName(
          "body"
        )[0].style.lineHeight = "20px";
        this.window[0].document.getElementsByTagName("body")[0].style.color =
          "rgb(68, 68, 68)";
      } else {
        setTimeout(() => {
          this.recursiveLimit++;
          this.onReady();
        }, 1000);
      }
    }, 1000);
  }

  public ngOnDestroy(): void {
    this.clearNavigation();
    if (this.observer) {
      this.observer.disconnect();
    }
    this.reportStore.clearData();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Method to trigger cancelConfirmModal when accordion close toggle occurs
   */
  public onCancel(): void {
    this.cancelConfirmModalComponent.toggle();
  }

  /**
   * Method to hande cancelConfirmModal response
   * @param event Event
   */
  public cancelModalDecision(event): void {
    if (event) {
      this.cancelEditing();
    }
  }

  /**
   * Function to check Day instance form validity
   */
  public isDayInstanceFormValid(): boolean {
    let hasForms = false;
    let formValid = true;
    if (this.dayInstanceComponents) {
      this.dayInstanceComponents.toArray().forEach((dayInstanceComponent) => {
        if (dayInstanceComponent.dayInstanceForm) {
          hasForms = true;
          formValid = dayInstanceComponent.dayInstanceForm.invalid
            ? false
            : formValid;
        }
      });
    }
    return hasForms ? formValid : false;
  }

  /**
   * Function to check is user able to circulate report
   */
  public canCirculate(): boolean {
    let formValid = false;

    if (!this.dayInstanceComponents) {
      return formValid;
    }

    for (const dayInstance of this.dayInstanceComponents.toArray()) {
      formValid =
        !dayInstance.dayInstanceForm.controls.sittingDay.value &&
        !dayInstance.dayInstanceForm.invalid;

      if (dayInstance.dayInstanceForm.controls.sittingDay.value) {
        if (isEmpty(this.reportStore.isSectionFormsValid())) {
          continue;
        }
        formValid = this.areSectionFormsValid();
      }

      if (!formValid) {
        break;
      }
    }
    return this.isDayInstanceFormValid() && formValid;
  }

  private areSectionFormsValid(): boolean {
    let formValid = true;
    for (const headers of this.reportStore.isSectionFormsValid()) {
      if (
        !headers.isDataValid &&
        headers.businessDaySectionPositionNo !== null &&
        headers.sittingDay
      ) {
        formValid = false;
        break;
      }
    }
    return formValid;
  }

  /**
   * Check if length of text is under or equal to 1000 characters
   */
  public checkMaxChars(event): any {
    const length = event.target.innerHTML
      ? event.target.innerHTML
          .replace(/<.+?>/gi, "")
          .replace("&nbsp;", "")
          .replace("↵", "")
          .replace(/\n/g, "").length
      : 0;
    if (
      length !== 0 &&
      length >= 1000 &&
      event.keyCode !== 46 &&
      event.keyCode !== 8 &&
      event.keyCode !== 37 &&
      event.keyCode !== 39
    ) {
      event.preventDefault();
    } else {
      // need timeout to detect changes
      setTimeout(() => {
        this.textWithHTML = event.target.innerHTML;
        let savedHeader = compact(this.defaultHeader.split("\n"));
        savedHeader = savedHeader.splice(4, savedHeader.length);
        this.userEdits = savedHeader.join("\n");
        this.reportStore.reportData.reportHeader = this.textWithHTML;
      }, 100);
    }
  }

  public checkNoteChars(event): void {
    const length = event.target.innerHTML
      .replace(/<.+?>/gi, "")
      .replace("&nbsp;", "")
      .replace("↵", "")
      .replace(/\n/g, "").length;
    if (
      length !== 0 &&
      length >= 3000 &&
      event.keyCode !== 46 &&
      event.keyCode !== 8 &&
      event.keyCode !== 37 &&
      event.keyCode !== 39
    ) {
      event.preventDefault();
    } else {
      setTimeout(() => {
        this.reportStore.reportData.reportNote =
          this.noteForm.controls.note.value;
      }, 100);
    }
  }

  /**
   * Force blur event to preselect days (need client component fixes)
   */
  public preselectDays(): void {
    if (this.preselectDates) {
      this.reportDatesRange.onBlur(
        document.querySelector(`.reportDatesRange .datepicker`)
      );
      this.preselectDates = !this.preselectDates;
    }
    setTimeout(() => {
      document.querySelector(".calender-navigate").classList.add("disabled");
    }, 1);
  }

  /**
   * Disable create draft button
   */
  public disableConfirmButton(event: any): void {
    this.disableConfirm = event.selectingRange;
  }

  /**
   * Function to convert date
   * @param date date variable
   */
  public convertDate(date: string) {
    return this.sharedService.convertDate(date);
  }

  /**
   * Event emmiter on date change
   */
  public dateRangeChanged(date): void {
    if (date) {
      const dates = date.split(" - ");
      this.startDate = dates[0];
      this.endDate = dates[1] ? dates[1] : dates[0];
      this.selectedRange =
        this.startDate + (this.dateRange ? " - " + this.endDate : "");
      this.rangeForm.get("reportDatesRange").clearValidators();
      this.rangeForm.get("reportDatesRange").updateValueAndValidity();
      this.getReportDates(this.startDate, this.endDate);
      this.onReady();
    }
  }

  public getReportNotes(): string {
    return this.noteForm.controls.note.value;
  }

  /**
   * Function to save report
   */
  public postReports(): void {
    const report = this.getAdjustedReportToPost();

    if (
      this.reportStatusId === ReportStatuses.Circulated ||
      this.reportStatusId === ReportStatuses.Update
    ) {
      this.saveReport(
        report,
        this.reportService.circulatedDraft(this.documentId, report)
      );
    } else {
      this.saveReport(
        report,
        this.reportService.postReport(this.documentId, report)
      );
    }
  }

  private getAdjustedReportToPost(): Report {
    const report = this.reportStore.getReportData();
    this.reportStore.deleteCustomProps();
    report.reportHeader = this.defaultHeader;
    report.reportNote = this.noteForm.controls.note.value;

    report.businessScheduleDetail.businessScheduleDays.forEach(
      (e: BusinessScheduleDay) => {
        this.updateWorkitemPositions(
          e[SectionTypes[FormTemplate.governmentBusiness]]
        );
        this.updateWorkitemPositions(
          e[SectionTypes[FormTemplate.billsForIntroduction]]
        );
        this.updateWorkitemPositions(
          e[SectionTypes[FormTemplate.referralToCommittee]]
        );
        this.updateWorkitemPositions(
          e[SectionTypes[FormTemplate.deferredDivisions]]
        );
      }
    );

    return report;
  }

  /**
   * Function to save report
   * @param report report
   * @param apiURL Api url
   */
  public saveReport(report: Report, apiURL: Observable<any>): void {
    apiURL.pipe(take(1)).subscribe(() => {
      const title = this.translateService.instant(
        "TOAST.SAVE_REPORT_DRAFT.TITLE"
      );
      const description = this.translateService.instant(
        "TOAST.SAVE_REPORT_DRAFT.BODY",
        this.dateRange
          ? [
              `${moment
                .utc(report.businessScheduleDetail.bsDetailFirstDay)
                .format(GLOBALS.dateFormat)} -
          ${moment
            .utc(report.businessScheduleDetail.bsDetailLastDay)
            .format(GLOBALS.dateFormat)}`,
            ]
          : [
              `${moment
                .utc(report.businessScheduleDetail.bsDetailFirstDay)
                .format(GLOBALS.dateFormat)}`,
            ]
      );

      this.toastService.addNotification({ title, description });
      this.deletedDays = [];
      this.router.navigate(["/dashboard"]);
      this.reportService.onSaveAsDraft(true, TabIndexes.Draft);
    });
  }

  /**
   * Function whitch on date change check if days exist
   * If exsist exsisting days stays new days is added
   * If not exsist in selected date range day's are removed
   */
  public checkDays(): void {
    const report: Report = this.reportStore.getReportData();
    const date = this.getSelectedDateString();

    this.initNavigation(date);
    this.isEditModeDate = false;
    this.reportService.isEditMode(false);
    let reportDays;
    const reportDaysOrig = report.businessScheduleDetail.businessScheduleDays;

    const reportDaysCompare = [];
    reportDaysOrig.forEach((element) => {
      reportDaysCompare.push(element.bsDayDate);
    });

    const selectedDates = this.getSelectedDate();
    const diffDays = this.sharedService.compareDaysArrays(
      reportDaysCompare,
      selectedDates
    );

    const newDatesIso = this.newDates.map((x) => moment.utc(x).format());

    if (diffDays.length > 0) {
      reportDays = [];
      for (let x of newDatesIso) {
        if (!reportDaysOrig.find((y) => y.bsDayDate === x)) {
          const day = moment(x).day();
          if (this.sharedService.isDayDefaultSittinDayByDate(x)) {
            this.reportService
              .getDefaultBusinessDayTemplate(x, day)
              .pipe(take(1))
              .subscribe((res) => {
                res[0].bsDayDate = x;
                reportDays.push(res[0]);
                this.report.businessScheduleDetail.businessScheduleDays =
                  reportDays;
                this.reportStore.setReportData(this.report);
                this.reportStore.setDefaultDaySection(res[0]);
              });
          } else {
            reportDays.push(this.addNewDay(x, true));
          }
          continue;
        }
        reportDays.push(reportDaysOrig.find((y) => y.bsDayDate === x));
      }

      reportDaysOrig.forEach((day) => {
        if (diffDays.indexOf(day.bsDayDate) !== -1 && day.bsDayId !== 0) {
          return this.deletedDays.push(day.bsDayId);
        }
      });
    } else {
      reportDays = report.businessScheduleDetail.businessScheduleDays;
    }

    this.dateChanged = true;
    this.fallbackDate = this.selectedRange;

    this.report.businessScheduleDetail.businessScheduleDays = reportDays;
    this.report.businessScheduleDetail.deletedDays = this.deletedDays;
    this.report.reportHeader = this.defaultHeader;
    delete this.report.reportId;

    if (!this.dateRange) {
      this.defaultEditorText(this.startDate, this.endDate);
    }
    this.reportStore.setReportData(this.report);
    this.onReady();
  }

  /**
   * Call service to get PDF preview of report
   */
  public generatePrintPreview(): void {
    this.reportService
      .generatePrintPreview(this.report.reportId)
      .pipe(take(1))
      .subscribe((res) => {
        const file: Blob = new Blob([res], { type: "application/pdf" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file);
        } else {
          const fileURL: string = URL.createObjectURL(file);
          window.open(fileURL);
        }
      });
  }

  /**
   * Call service to get MSWord preview of report
   */
  public generateWordPreview(): void {
    const MSWORD_CONTENT_TYPE =
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    this.reportService
      .generatePrintPreview(this.report.reportId, FileExtensionType.BcrDocx)
      .pipe(take(1))
      .subscribe((res) => {
        console.log("Response:", res);
        const filename = res.headers
          .get("Content-Disposition")
          .split(";")[1]
          .trim()
          .split("=")[1]
          .replace(/"/g, "");
        const file: Blob = new Blob([res.body], {
          type: MSWORD_CONTENT_TYPE,
        });
        const link = document.createElement("a");
        link.download = filename;
        link.href = URL.createObjectURL(file);
        link.target = "_blank";
        link.click();
      });
  }

  /**
   * Get all dates in selected range as date ISO strings
   * @param startDate - start date
   * @param endDate - end date
   */
  private getDatesInRange(startDate: string, endDate: string): string[] {
    const dates = [] as string[];
    const convertedStartDate = startDate.split("/").reverse().join("-");
    const convertedEndDate = endDate.split("/").reverse().join("-");
    const currDate = moment.utc(startDate, GLOBALS.dateFormat).startOf("day");
    const lastDate = moment.utc(endDate, GLOBALS.dateFormat).startOf("day");

    dates.push(moment.utc(convertedStartDate).format());
    while (currDate.add(1, "days").diff(lastDate) < 0) {
      dates.push(moment.utc(currDate.clone()).format());
    }
    dates.push(moment.utc(convertedEndDate).format());

    return dates;
  }

  /**
   * Validate dates based on user input
   */
  public validateDates(enteredDates: any): void {
    const dates = enteredDates
      ? enteredDates.target
        ? enteredDates.target.value.split("-")
        : enteredDates.split("-")
      : this.selectedRange;
    this.invalidInputDate = this.sharedService.validateDates(
      dates,
      this.currentWeek,
      this.dateRange
    );
    if (!this.invalidInputDate) {
      this.startDate = dates[0];
      this.endDate = !!dates[1] ? dates[1] : dates[0];
      this.defaultEditorText(this.startDate, this.endDate);
    }
  }

  /**
   * Function to enable edit mode
   */
  public editMode(): void {
    this.isEditModeDate = true;
    this.isEditModeDayTemplate = true;
    this.reportService.isEditMode(true);
    setTimeout(() => {
      this.rangeForm.get("reportDatesRange").setValidators(Validators.required);
      this.rangeForm
        .get("reportDatesRange")
        .patchValue(this.startDate + " - " + this.endDate);
      this.rangeForm.get("reportDatesRange").updateValueAndValidity();
    }, 50);
  }

  /**
   * Cancel editing draft and return to dashboard
   */
  public cancelEditing() {
    const title =
      this.reportStatusId === ReportStatuses.Circulated
        ? this.translateService.instant("TOAST.CANCEL_REPORT_CIRCULATED.TITLE")
        : this.translateService.instant("TOAST.CANCEL_REPORT_DRAFT.TITLE");
    const description =
      this.reportStatusId === ReportStatuses.Circulated
        ? this.translateService.instant("TOAST.CANCEL_REPORT_CIRCULATED.BODY")
        : this.translateService.instant("TOAST.CANCEL_REPORT_DRAFT.BODY");
    this.toastService.addNotification({ title, description });
    this.router.navigate(["/dashboard"]);
  }

  /**
   * Function to cancel date editing
   */
  public editDatesCancel(): void {
    this.isEditModeDate = false;
    this.reportService.isEditMode(false);
    const dates = this.fallbackDate.split(" - ");
    this.startDate = dates[0];
    this.selectedRange = this.fallbackDate;
    this.endDate = !!dates[1] ? dates[1] : dates[0];
    this.validateDates(this.selectedRange);
    this.preselectDates = !this.preselectDates;
    this.defaultHeader = this.reportStore.reportData.reportHeader;
  }

  /**
   * Method to open circulate bcr modal
   */
  public openCirculateBCR(action: string): void {
    this.circulateBcrModalComponent.open(action);
  }

  public onConcurrentUserListChange(users: ConcurrentUser[]): void {
    const { userName: mainUser = null } =
      users.find((user) => user.canEditResource) || {};
    this.isReadOnly = this.userName !== mainUser;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Method to open revise circulate bcr modal
   */
  public openCirculateRevisedBCR(action: string): void {
    this.circulateBcrModalComponent.open(action);
  }

  /**
   * Method to update BCR
   */
  public updateBcr(): void {
    const report = this.reportStore.getReportData();
    const newUpdateNumber = (this.reportVersion + 0.01).toFixed(2);
    const description =
      this.dayCount === 1
        ? this.translateService.instant(
            "TOAST.BUSINESS_COMMITTEE_REPORT_UPDATE_SINGLE_DAY.BODY",
            [this.startDate, newUpdateNumber]
          )
        : this.translateService.instant(
            "TOAST.BUSINESS_COMMITTEE_REPORT_UPDATE.BODY",
            [this.startDate, this.endDate, newUpdateNumber]
          );
    report.reportHeader = this.ckEditor.data;
    report.reportNote = this.noteForm.controls.note.value;

    const result$ = this.report.isCirculatedDraft
      ? this.reportService.updateMinorReport(this.documentId, report)
      : this.reportService.updateReport(this.documentId, report);

    result$.pipe(take(1)).subscribe(() => {
      this.toastService.addNotification({
        title: this.translateService.instant(
          "TOAST.BUSINESS_COMMITTEE_REPORT_UPDATE.TITLE"
        ),
        description,
      });
      this.router.navigate(["/dashboard"]);
    });
  }

  /**
   * Method to send file from action bar
   */
  public sendEmailFile(): void {
    this.actionbarEmailModalComponent.open();
  }

  /**
   * Method to populate all needed data for view report component
   */
  private populateReportView(): void {
    this.startDate = moment
      .utc(
        this.report.businessScheduleDetail.bsDetailFirstDay,
        GLOBALS.dateTimeFormat
      )
      .format(GLOBALS.dateFormat);
    this.endDate = moment
      .utc(
        this.report.businessScheduleDetail.bsDetailLastDay,
        GLOBALS.dateTimeFormat
      )
      .format(GLOBALS.dateFormat);
    this.reportStatusId = this.report.reportStatusId;
    this.reportVersion = this.report.reportVerison;
    this.dateRange = this.startDate !== this.endDate;
    this.selectedRange =
      this.startDate + (this.dateRange ? " - " + this.endDate : "");
    this.fallbackDate = this.selectedRange;
    this.defaultHeader = this.report.reportHeader;
    this.dateRange =
      this.report.businessScheduleDetail.bsDetailFirstDay !==
      this.report.businessScheduleDetail.bsDetailLastDay;
    this.currentWeek = moment(
      this.report.businessScheduleDetail.bsDetailFirstDay,
      GLOBALS.dateFormat
    ).isoWeek();
    const date = this.dateRange
      ? `(${this.startDate}-${this.endDate})`
      : `(${this.startDate})`;
    this.initNavigation(date);
    this.dayCount = this.getReportDates(this.startDate, this.endDate);
    let savedHeader = compact(this.defaultHeader.split("\n"));
    savedHeader = savedHeader.splice(4, savedHeader.length);
    this.userEdits = savedHeader.join("\n");
    this.reportTitle = this.generateReportTitle(
      date,
      this.reportStatusId,
      false,
      this.reportVersion
    );
    this.revisedBcrNumber = this.report.reportVerison.toString().split(".")[0];
    this.updateBcrString = this.report.reportVerison.toString().split(".")[1];

    this.noteForm.controls.note.setValue(
      !isEmpty(this.report.reportNote) ? this.report.reportNote : null
    );
  }

  /**
   * Function with default ckEditor text
   * @param start Start time
   * @param end End time
   */
  private defaultEditorText(start: string, end: string): void {
    if (this.dateRange) {
      this.dayText = `${moment
        .utc(start, GLOBALS.dateFormat)
        .format("dddd")
        .toUpperCase()}
      ${this.translateService.instant("VIEW_REPORT.REPORT_HEADER.LINE_3")}
      ${moment.utc(end, GLOBALS.dateFormat).format("dddd").toUpperCase()}`;
      this.dateText = `${moment
        .utc(start, GLOBALS.dateFormat)
        .format("D MMMM")
        .toUpperCase()} -
      ${moment
        .utc(end, GLOBALS.dateFormat)
        .format("D MMMM YYYY")
        .toUpperCase()}`;
    } else {
      this.dayText = moment
        .utc(start, GLOBALS.dateFormat)
        .format("dddd")
        .toUpperCase();
      this.dateText = moment
        .utc(start, GLOBALS.dateFormat)
        .format("D MMMM YYYY")
        .toUpperCase();
    }
    this.defaultHeader = `<h1><b>${this.translateService.instant(
      "VIEW_REPORT.REPORT_HEADER.LINE_1"
    )}</b></h1>
      <p>${this.translateService.instant(
        "VIEW_REPORT.REPORT_HEADER.LINE_2"
      )}</p>
      <p>${this.dayText}, ${this.dateText}</p>
      <p>(${this.translateService.instant("VIEW_REPORT.REPORT_HEADER.LINE_5")}
      ${moment(moment(start, GLOBALS.dateFormat).format())
        .subtract(1, "weeks")
        .isoWeekday(4)
        .format("D MMMM YYYY")
        .toUpperCase()})</p>
      ${this.userEdits}`;
  }

  /**
   * Empty model for new day
   * @param day Date
   * @param isSitingDay Sitting day default value
   */
  private addNewDay(day, isSitingDay) {
    const newDay: BusinessScheduleDay = {
      bsDayId: 0,
      bsDayDate: day,
      bsDayStartTime: null,
      bsDayEndTime: null,
      deletedSections: [],
      bsDayNotSittingReason: null,
      isBSDayCustom: 0,
      bsDayTemplateId: 0,
      isBSDaySitting: isSitingDay,
      businessDaySectionHeaders: [],
      businessDayArrangements: [],
      businessDayCustomSectionDetails: [],
      daySectionExpanded: true,
    };
    return newDay;
  }

  /**
   * Function to get day diffrence
   * @param startDate Start day
   * @param endDate End day
   */
  private getReportDates(startDate, endDate): number {
    const end = moment.utc(endDate, GLOBALS.dateFormat);
    const start = moment.utc(startDate, GLOBALS.dateFormat);
    this.report.businessScheduleDetail.bsDetailFirstDay = moment
      .utc(start, GLOBALS.dateFormat)
      .format(GLOBALS.dateFormat);
    this.report.businessScheduleDetail.bsDetailLastDay = moment
      .utc(end, GLOBALS.dateFormat)
      .format(GLOBALS.dateFormat);
    this.newDates = [];
    const step: number = moment.duration(end.diff(start)).asDays();
    for (let i = 0; i <= step; i++) {
      this.newDates.push(moment.utc(start, GLOBALS.dateFormat).format());
      start.add(1, "day");
    }
    return step + 1;
  }

  /**
   * Function to generate report title
   * @param date Report date
   * @param reportStatusId Report status id
   * @param navigationTitle boolean to identify is it navigation title item or not
   * @param reportVersion Circulated report version
   */
  private generateReportTitle(
    date: string,
    reportStatusId: number,
    navigationTitle?: boolean,
    reportVersion?: number
  ): string {
    if (reportStatusId === ReportStatuses.Draft) {
      return navigationTitle
        ? this.translateService.instant("DASHBOARD.NAVIGATION_ITEM.DRAFT", [
            date,
          ])
        : this.translateService.instant("VIEW_REPORT.PAGETITLE");
    }
    return this.translateService.instant(
      "DASHBOARD.NAVIGATION_ITEM.CIRCULATED",
      [date, reportVersion]
    );
  }

  /**
   * Function to init navigation
   * @param params Params to init navigation
   */
  private initNavigation(params: any): void {
    const title = this.generateReportTitle(
      params,
      this.reportStatusId,
      true,
      this.reportVersion
    );
    const parentTitle = "Dashboard";
    const taskManagerNode = this.navigationService.model.tree.find(
      (x) => x.title === parentTitle
    );
    this.navigationService.select(taskManagerNode);
    this.navigationService.addNode({
      title,
      path: `/bcr-report/${this.documentId}`,
    });
  }

  /**
   * Function to clear navigation
   */
  private clearNavigation(): void {
    this.navigationService.select(this.navigationService.currentNode);
    const taskManagerNode = this.navigationService.model.tree.find(
      (x) => x.title === "Dashboard"
    );
    taskManagerNode.expanded = false;
  }

  /**
   * Function to observe changes in date range calendar
   */
  private observeChanges(): void {
    const currentDate = extendMoment(moment);
    const elementToObserve = document.querySelector(
      ".report-dates .datepicker"
    );
    this.observer = new MutationObserver(() => {
      document.querySelectorAll(".ngb-dp-day").forEach((v) => {
        const ariaLabel = v.getAttribute("aria-label");
        const dates = moment.utc(ariaLabel).startOf("day").toISOString();
        if (
          moment(dates).isoWeek() !== this.currentWeek ||
          moment.utc(dates).isBefore(moment.utc().subtract(1, "days"))
        ) {
          v.classList.add("disable-picker");
        } else {
          v.classList.remove("disable-picker");
        }
      });
    });
    this.observer.observe(elementToObserve, {
      subtree: true,
      childList: true,
      attributes: true,
    });
  }

  /**
   * Function to adjust workitem positions
   */
  private updateWorkitemPositions(
    sectionDetails: BusinessDayCommonSectionDetail[]
  ): void {
    if (sectionDetails && sectionDetails.length) {
      sectionDetails.forEach((row) => {
        if (row.workItems) {
          row.workItems = row.workItems.map((i, index) => ({
            ...i,
            businessDaySectionWorkItemPositionNo: index,
          }));
        }
      });
    }
  }

  private getSelectedDateString(): string {
    return this.dateRange
      ? `(${this.startDate}-${this.endDate})`
      : `(${this.startDate})`;
  }

  private getSelectedDate(): string[] {
    return this.dateRange
      ? this.getDatesInRange(this.startDate.trim(), this.endDate.trim())
      : [moment.utc(this.startDate.trim(), GLOBALS.dateFormat).format()];
  }

  private titleTranslationSuccess(
    isSuccess?: boolean,
    isWithdraw = false
  ): void {
    if (isSuccess !== true) {
      return;
    }

    // Fetch section details for new translation details
    const openSectionComponents = this.dayInstanceComponents
      .toArray()
      .filter((dayComp) => dayComp && dayComp.sectionComponents)
      .map((dayComp) => dayComp.sectionComponents.toArray())
      .reduce((prev, curr) => prev.concat(curr))
      .filter((sectionComp) => !sectionComp.canToggle);

    if (openSectionComponents.length) {
      openSectionComponents.forEach((comp) =>
        comp.populateSectionWithData(true)
      );
    }

    const title = this.translateService.instant(
      isWithdraw
        ? "TOAST.REQ_TITLE_TRANSLATION_WITHDRAW.TITLE"
        : "TOAST.REQ_TITLE_TRANSLATION.TITLE"
    );
    const description = this.translateService.instant(
      isWithdraw
        ? "TOAST.REQ_TITLE_TRANSLATION_WITHDRAW.BODY"
        : "TOAST.REQ_TITLE_TRANSLATION.BODY"
    );

    this.toastService.addNotification({ title, description });
  }
}
