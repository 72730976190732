import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToggleableAccordionComponent } from 'src/app/shared/components/toggleable-accordion/toggleable-accordion.component';

@Component({
  selector: 'oir-announcement-days',
  templateUrl: './announcement-days.component.html',
  styleUrls: ['./announcement-days.component.less']
})
export class AnnouncementDaysComponent implements OnInit {
  @Input() rapporteurReportDay: any;
  @Input() index: number;

  @Output() toggleAccordion: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('toggleableAccordion')
  toggleableAccordion: ToggleableAccordionComponent;

  public canToggle = true;
  public dayAnnouncementTitle: string;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    const dayOfTheWeek = this.rapporteurReportDay.dayName.split(' ')[0];
    this.dayAnnouncementTitle = this.translateService.instant(
      'VIEW_RAPPORTEURS_REPORT_ANNOUNCEMENT_DAYS.TITLE',
      [dayOfTheWeek]
    );
  }

  /**
   * Event emmiter after toggle
   * @param isOpen boolean
   */
  public afterToggle(isOpen: boolean) {
    this.toggleAccordion.emit(isOpen);
  }

  /**
   * Function to close day's accordion
   */
  public closeAccordion(): void {
    this.canToggle = true;
    // Need to timeout to allow change detection to work
    setTimeout(() => {
      this.toggleableAccordion.click();
    }, 1);
  }
}
