import { Component, ViewChild, Input } from '@angular/core';
import { ModalComponent } from 'proceduralsystem-clientcomponents';
import { ReportStoreService } from '../../../services/report-store.service';

@Component({
  selector: 'oir-add-new-section-delete-modal',
  templateUrl: './add-new-section-delete-modal.component.html',
  styleUrls: ['./add-new-section-delete-modal.component.less']
})
export class AddNewSectionDeleteModalComponent {

  @Input() bsSectionId: string;
  @Input() bsDayDate: string;
  @Input() bsSectionTypeId: string;

  @ViewChild('deleteModal') deleteModal: ModalComponent;

  constructor(private reportStoreService: ReportStoreService) { }

  /**
   * Function to delete custom section
   */
  public deleteCustomSection(): void {
    this.reportStoreService.deleteNewCustomSectionAndHeader(this.bsSectionId, this.bsDayDate, this.bsSectionTypeId);
    this.closeDeleteModal();
  }

  /**
   * Function to open Delete modal
   */
  public toggleDeleteModal(): void {
    this.deleteModal.toggle();
  }

  /**
   * Function to close delete modal
   */
  public closeDeleteModal(): void {
    this.deleteModal.close();
  }
}
