import {
  Component,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import {
  OverlayService,
  TabIndexPipe,
} from "proceduralsystem-clientcomponents";

@Component({
  selector: "oir-custom-modal",
  templateUrl: "./custom-modal.component.html",
  styleUrls: ["./custom-modal.component.less"],
})
export class CustomModalComponent implements AfterViewInit {
  TAB_KEYCODE = "9";

  @Input() showCancel = true;
  @Input() cancelNoAction = false;
  @Input() class = "";

  @Output() readonly modalClose: EventEmitter<any> = new EventEmitter();

  @ViewChild("modalHeight") modalHeight: ElementRef;
  @ViewChild("modalDialog") modalDialog: ElementRef;

  overflowClass = "";
  focusable: any;
  private visibleState = false;
  private keyDownEventListener: Function;

  set visible(visible: boolean) {
    const element = this.modalHeight.nativeElement;
    if (visible) {
      setTimeout(() => {
        this.overlayService.show(true);
        this.registerListener();
        const viewHeight = element.offsetHeight;
        // Check if content of modal exceeds browser window.
        if (viewHeight < window.innerHeight) {
          this.removeOverflow();
          this.renderer.setAttribute(
            element,
            "style",
            `margin-top: -${viewHeight / 2}px !important;`
          );
        } else {
          // Set height of modal to match brower window.
          this.renderer.setAttribute(
            element,
            "style",
            `margin-top: -${viewHeight / 2}px !important; height: ${
              window.innerHeight
            }px;`
          );
          this.addOverflow();
        }
        this.visibleState = visible;
        this.setTabIndex(this.visibleState);
      }, 500);
    } else {
      this.checkOverlay();
      this.visibleState = visible;
      this.setTabIndex(this.visibleState);
      if (this.keyDownEventListener) {
        this.keyDownEventListener();
      }
    }
  }
  get visible(): boolean {
    return this.visibleState;
  }

  constructor(
    private readonly el: ElementRef,
    private readonly overlayService: OverlayService,
    private readonly renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    // Get all tabbable elements in document.
    this.focusable = document.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    this.setTabIndex(this.visibleState);
  }

  close(): void {
    this.visible = false;
    this.modalClose.emit();
  }

  noCloseAction(): void {
    this.modalClose.emit();
  }

  toggle(): void {
    this.visible = !this.visible;
  }

  setTabIndex(visible): void {
    new TabIndexPipe().transform(visible, this.modalDialog);
  }

  registerListener(): void {
    this.keyDownEventListener = this.renderer.listen(
      "document",
      "keydown",
      (event: KeyboardEvent) => {
        // Ignore keydown if its not tab or the current element does not belong to this specific modal.
        if (
          event.code === this.TAB_KEYCODE &&
          this.el.nativeElement.contains(event.target) &&
          !event.shiftKey
        ) {
          // Work forwards through the tabbable elements.
          this.handleTabKeyDown(event);
        } else if (
          event.code === this.TAB_KEYCODE &&
          this.el.nativeElement.contains(event.target) &&
          event.shiftKey
        ) {
          // Search forwards. Prevent shift tab from working if on first tabbable element in this modal.
          this.handleShiftTabKeyDown(event);
        }
      }
    );
  }

  private checkOverlay(): void {
    // Setting time out to get changes and check how mutch modals are opened
    setTimeout(() => {
      // If no modals opened remove overlay
      if (document.querySelectorAll("oir-custom-modal .visible").length === 0) {
        this.overlayService.hide(true);
      }
    }, 100);
  }

  private addOverflow(): void {
    // Don't add duplicate class.
    if (this.overflowClass.indexOf("modalOverflow") === -1) {
      // Add scrollbar.
      this.overflowClass = "modalOverflow";
    }
  }

  private removeOverflow(): void {
    // Remove attached scroll bar if exists and is not needed.
    if (this.overflowClass.indexOf("modalOverflow") > -1) {
      this.overflowClass = "";
    }
  }

  private handleTabKeyDown(event: KeyboardEvent): void {
    for (let i = this.focusable.length - 1; i > 0; i--) {
      if (this.el.nativeElement.contains(this.focusable[i])) {
        // If its the last tabbable element prevent the keydown from executing.
        if (event.target === this.focusable[i]) {
          event.preventDefault();
        }
        // Ignore disabled elements.
        if (this.focusable[i].disabled) {
          continue;
        }
        // Breaks on the first tabbable element belong to this modal.
        break;
      }
    }
  }

  private handleShiftTabKeyDown(event: KeyboardEvent) {
    for (let i = 0; i < this.focusable.length - 1; i++) {
      if (this.el.nativeElement.contains(this.focusable[i])) {
        if (event.target === this.focusable[i]) {
          event.preventDefault();
        }
        break;
      }
    }
  }
}
