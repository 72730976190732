import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { AppConfigService } from './app-config.service';
@Injectable({
  providedIn: 'root'
})
export class BcrMotionHubService {
  public dashboardDetailsUpdate = new Subject<boolean>();

  private connection: signalR.HubConnection;

  constructor(private config: AppConfigService) {}

  /**
   * Creating connection to Hub-outstandingItemsHub
   */
  public createConnection(): void {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${this.config.getValue('ApiEndpoint').url}/outstandingItemsHub`)
      .build();
    this.startConnection();
  }

  /**
   * Methode to close connection
   */
  public stopConnection(): void {
    this.connection
      .stop()
      .catch(err => console.log('error while stopping connection', err));
  }

  /**
   * Methode to start connection and listens to dashboardDetailsUpdate Methode
   */
  public startConnection(): void {
    this.connection
      .start()
      .catch(err => console.log('error while starting connection', err));
    this.connection.on('dashboardDetailsUpdate', (updated: boolean) => {
      this.dashboardDetailsUpdate.next(updated);
    });
  }
}
